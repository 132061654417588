export const q_newMessage = `
  subscription NewMessage($to_user_id: String) {
    newMessage(to_user_id: $to_user_id) {
      id
      body
      from_user_id
      to_user_id
      date_sent
      seen

      from_user {
        id
        name
      }
    }
  }
`;
