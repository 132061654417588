import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// assets
import ArrowLeft from 'src/assets/svg/arrows/ArrowLeft';
import ArrowRight from 'src/assets/svg/arrows/ArrowRight';

// components
import { Link } from 'react-router-dom';

function determineHoverBgColor(color) {
    if (color === 'transparent') {
        return 'transparent';
    } else if (color === 'black') {
        return '#111';
    } else if (color === '#a6bcff') {
        return '#7C96E5';
    } else {
        return '#cacaca';
    }
}

function determineContentColor(color) {
    if (color === 'black') {
        return 'white';
    } else if (color === 'white') {
        return 'black';
    } else if (color === '#a6bcff') {
        return 'black';
    }
    return 'black';
}

export default function Button({
    href = null,
    to = null,
    onClick = null,
    color = 'white',
    arrow = null,
    disabled = false,
    style = null,
    text = null,
    size = 'default'
}) {
    Button.propTypes = {
        href: PropTypes.string,
        to: PropTypes.string,
        onClick: PropTypes.func,
        color: PropTypes.oneOf(['white', 'black', 'a6bcff']), // Limit allowed colors
        arrow: PropTypes.oneOf(['left', 'right', null]), // Allow arrows or null
        disabled: PropTypes.bool,
        style: PropTypes.object,
        text: PropTypes.string.isRequired, // Make text required
        size: PropTypes.oneOf(['small', 'default']) // Only allow specific sizes
    };

    Button.defaultProps = {
        // Set default values
        color: 'white',
        disabled: false,
        size: 'default'
    };

    const handleClick = (e) => {
        if (disabled || href || to) return;

        if (onClick) {
            onClick(e);
        }
    };

    const arrowLeft = arrow === 'left';
    const arrowRight = arrow === 'right';

    const StyledButtonContainer = (
        <StyledButton
            bgColor={color}
            className={disabled ? 'disabled' : ''}
            onClick={handleClick}
            style={style}
            text={text}
            arrowLeft={arrowLeft}
            arrowRight={arrowRight}
            size={size}
        >
            {arrowLeft && <ArrowLeft stroke={determineContentColor(color)} paddingTop="4px" />}
            <ButtonText color={determineContentColor(color)} size={size}>
                {text}
            </ButtonText>
            {arrowRight && <ArrowRight stroke={determineContentColor(color)} paddingTop="4px" />}
        </StyledButton>
    );

    return (
        <div>
            {href ? (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {StyledButtonContainer}
                </a>
            ) : to ? (
                <Link to={to}>{StyledButtonContainer}</Link>
            ) : (
                StyledButtonContainer
            )}
        </div>
    );
}

const StyledButton = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex-shrink: 0;
    gap: 8px;

    /* Default size */
    height: 56px;
    padding: 20px 32px;
    ${(props) => props.arrowLeft && 'padding-left: 24px;'}
    ${(props) => props.arrowRight && 'padding-right: 24px;'} 

  /* Small size */
  ${(props) =>
        props.size === 'small' &&
        `
    height: 36px;
    padding: 10px 20px;
    ${(props) => props.arrowLeft && 'padding-left: 14px;'} 
    ${(props) => props.arrowRight && 'padding-right: 14px;'} 
  `}

  margin-top: ${(props) => props.marginTop || '0px'};

    border: 0;
    border-radius: 56px;

    background: ${(props) => props.bgColor || 'var(--White, #FFF)'};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

    svg {
        margin: 0;
        vertical-align: middle;

        ${(props) => props.arrowLeft && 'margin-right: 5px;'}
        ${(props) => props.arrowRight && 'margin-left: 5px;'}

    /* Small button adjustments */
    ${(props) => props.size === 'small' && 'width: 20px; '}
    ${(props) => props.size === 'small' && 'padding-top: 2px !important;'}
    }

    &:hover {
        background: ${(props) => determineHoverBgColor(props.bgColor)};
    }

    &.disabled {
        cursor: default;
        opacity: 0.6;
        &:hover {
            background: ${(props) => props.bgColor || 'var(--White, #FFF)'};
        }
    }
`;

const ButtonText = styled.div`
    font-family: Inter;
    font-size: clamp(1.3rem, 1.6vw, 1.45rem);
    ${(props) => props.size === 'small' && 'font-size: 14px;'}
    font-style: normal;
    font-weight: 400;

    color: ${(props) => props.color || 'var(--Black, #000)'};
    line-height: 140%;
    text-align: ${(props) => props.textAlign || 'left'};
    // margin-bottom: 2px;

    white-space: nowrap;
`;
