import { Helmet } from 'react-helmet-async';
import {
    Avatar,
    Box,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import Slider from '@mui/material/Slider';
import EditIcon from '@mui/icons-material/CameraAlt';
import Footer from 'src/components/Footer';
import React, { useEffect, useRef, useState } from 'react';
import Spinner from 'src/components/Spinner';
import { useParams } from 'react-router-dom';
import { User } from '../../../../graphql/types/User';
import { avatarUploadGenerateURL, feedGet, userFriendAdd, userFriendRemove, userGet } from '../../../../graphql/GMAPI';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FeedEvent } from '../../../../graphql/types/Feed';
import FeedEventCard from '../../../../components/FeedEventCard';
import { selectUser } from '../../../../redux/features/user/userSlice';
import { useAppSelector } from '../../../../redux/hooks';
import { useAppDispatch } from '../../../..';
import { setMyAvatar } from '../../../../redux/features/user/userSlice';

interface ProfileProps {
    idProp?: string; // Optional 'id' prop
}

function Profile({ idProp }: ProfileProps) {
    const myUser = useAppSelector(selectUser);
    const { id: idParam } = useParams<{ id: string }>();
    const id = idProp || idParam;
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User>();
    const navigate = useNavigate();
    const [feed, setFeed] = useState<FeedEvent[]>([]);
    const [page, setPage] = useState<number>(0);
    const isMine: boolean = myUser?.value.id == user?.id;

    const [hasCamera, setHasCamera] = useState<boolean>(false);
    const [capturing, setCapturing] = useState<boolean>(false);
    const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const avatarEditorRef = useRef<AvatarEditor>();
    const [previewImage, setPreviewImage] = useState<string | null>(null);

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const dialogContentRef = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [avatarUrl, setAvatarUrl] = useState(null);
    const dispatch = useAppDispatch();

    const minZoomFactor = 0.2; // Minimum zoom factor
    const maxZoomFactor = 5; // Maximum zoom factor
    const initialZoomLevel = 2;
    const [zoomLevel, setZoomLevel] = useState(initialZoomLevel); // 1x zoom initially
    const [sliderValue, setSliderValue] = useState(50); // Initial slider position

    const [dimensions, setDimensions] = useState({ width: 350, height: 250 });

    const dialogContentStyles = {
        position: 'relative',
        width: '100%', // Width of the content area
        height: '100%', // Height of the content area to maintain aspect ratio
        overflow: 'hidden', // Prevents the image from dragging outside the bounds
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:before': {
            // This creates the circular mask
            content: '""',
            display: 'block',
            pointerEvents: 'none',
            position: 'absolute',
            width: '100%', // Full width of the content area
            height: '100%', // Full height of the content area
            background: 'radial-gradient(circle at center, transparent 130px, rgba(0, 0, 0, 0.5) 140px)',
            zIndex: 10
        },
        '&:after': {
            // This creates the overlay with a cut-out circle in the center
            content: '""',
            display: 'block',
            position: 'absolute',
            pointerEvents: 'none',
            width: '100%', // Full width of the content area
            height: '100%', // Full height of the content area
            background: 'radial-gradient(circle at center, transparent 130px, rgba(0, 0, 0, 0.5) 141px)',
            zIndex: 9
        }
    };

    const updateDimensions = () => {
        if (dialogContentRef.current) {
            setDimensions({
                width: dialogContentRef.current.clientWidth,
                height: dialogContentRef.current.clientHeight
            });
        }
    };

    useEffect(() => {
        // Initialize dimensions on mount
        updateDimensions();

        const resizeObserver = new ResizeObserver(() => {
            updateDimensions();
        });

        if (dialogContentRef.current) {
            resizeObserver.observe(dialogContentRef.current);
        }

        return () => {
            if (dialogContentRef.current) {
                resizeObserver.unobserve(dialogContentRef.current);
            }
        };
    }, []);

    const handleAvatarClick = () => {
        console.log('IsMine: ' + isMine);
        if (isMine) {
            setOpenDialog(true);
        }
    };

    const customStyles = {
        dialogPaper: {
            width: { xs: '90%', md: '50%' },
            height: { xs: '70%', md: '60%' }
        },
        video: {
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain' as const
        }
    };

    useEffect(() => {
        // Check if the device has a camera
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                const cameras = devices.filter((device) => device.kind === 'videoinput');
                setHasCamera(cameras.length > 0);
            });
        }
    }, []);

    const handleCameraAccess = async () => {
        setPreviewImage(null); // Reset the preview image
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraStream(stream);
            setCapturing(true);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error('Error accessing the camera:', error);
        }
    };

    const capturePhoto = () => {
        if (videoRef.current && cameraStream) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            setCapturing(false);

            canvas.toBlob((blob) => {
                if (blob) {
                    const imageUrl = URL.createObjectURL(blob);
                    setPreviewImage(imageUrl);
                    // Convert blob to file
                    const file = new File([blob], `${user.id}-avatar.jpg`, { type: 'image/jpeg' });
                    setSelectedFile(file); // Set the captured image as selected file
                }
                // Stop the camera stream
                cameraStream.getTracks().forEach((track) => track.stop());
                setCameraStream(null);
            }, 'image/jpeg');
        } else {
            console.log('No video ref or stream: ' + videoRef.current + ', ' + cameraStream);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setPreviewImage(imageUrl);
            setSelectedFile(file); // Set the selected file to state
        }
    };

    //TODO paginate
    useEffect(() => {
        setPage(0);
    }, []);

    const startConversation = () => {
        if (user) {
            navigate(`/dashboards/messenger/conversation/${user.id}`);
        }
    };

    const processFriendRequest = async () => {
        if (user.friend_status == 'NONE') {
            await userFriendAdd(user.id);

            setUser({
                ...user,
                friend_status: 'PENDING'
            });
        } else {
            //Status is friends or pending..
            await userFriendRemove(user.id);

            setUser({
                ...user,
                friend_status: 'NONE'
            });
        }
    };

    useEffect(() => {
        async function loadPage(userId: string) {
            const user = await userGet(userId);
            if (user.data) {
                setUser(user.data);
                setAvatarUrl(user.data.avatar_url);
            }
            const response = await feedGet(page, userId);
            if (response.data) {
                setFeed(response.data);
            }

            setLoading(false);
        }

        loadPage(id);
    }, [id]);

    const handleZoomChange = (event, newValue) => {
        const newScaleFactor = minZoomFactor + (newValue / 100) * (maxZoomFactor - minZoomFactor);

        console.log('Zoom: ' + newScaleFactor);
        setZoomLevel(newScaleFactor);
        setSliderValue(newValue);
    };

    const uploadImage = async () => {
        if (!selectedFile) {
            console.error('No file selected for upload');
            return;
        }

        const img = avatarEditorRef.current.getImageScaledToCanvas();
        const rect = avatarEditorRef.current.getCroppingRect();

        // Calculate the aspect ratio of the cropping rectangle
        const aspectRatio = rect.width / rect.height;

        // Set the canvas dimensions while maintaining the aspect ratio
        const canvasWidth = 500;
        const canvasHeight = canvasWidth / aspectRatio;

        // Create a new canvas
        const canvas = document.createElement('canvas');
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const ctx = canvas.getContext('2d');

        console.log('Rect is: ', rect);

        if (rect.width > 1 || rect.height > 1) {
            // Calculate source dimensions and positions
            const sourceX = rect.x * img.width;
            const sourceY = rect.y * img.height;
            const sourceWidth = rect.width * img.width;
            const sourceHeight = rect.height * img.height;

            // Center the image in the canvas
            const destX = (canvasWidth - sourceWidth) / 2;
            const destY = (canvasHeight - sourceHeight) / 2;

            ctx.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, sourceWidth, sourceHeight);
        } else {
            // For normal cases, draw the image to cover the entire canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }

        const croppedImageBlob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, 'image/jpeg'));

        setUploading(true); // Start uploading

        let finalUrl = null;
        try {
            // Get the signed URL from your GraphQL API
            const response = await avatarUploadGenerateURL();
            if (response.errors) {
                console.error('Error getting signed URL:', response.errors);
                setUploading(false); // Reset uploading on error
                return;
            }
            finalUrl = response.data.finalUrl;
            const signedUrl = response.data.uploadUrl;

            // Perform the upload to the signed URL
            const uploadResult = await fetch(signedUrl, {
                method: 'PUT',
                body: croppedImageBlob,
                headers: {
                    'Content-Type': 'image/jpeg'
                }
            });

            if (uploadResult.ok) {
                console.log('Image successfully uploaded');
                // Here you might want to update the user's avatar URL in your database
            } else {
                console.error('Upload failed:', await uploadResult.text());
            }
        } catch (error) {
            console.error('Error during the upload:', error);
        } finally {
            setUploading(false); // Reset uploading
            setOpenDialog(false); // Close the dialog

            //Update the avatar on this page
            setAvatarUrl(finalUrl + '?random=' + Math.random());

            //Set the user globally
            dispatch(setMyAvatar(finalUrl));
        }
    };

    return (
        <>
            <Helmet>
                <title>Profile</title>
            </Helmet>

            {loading ? (
                <Spinner />
            ) : (
                <Container maxWidth="lg">
                    <Grid container alignItems="flex-start" justifyContent="center">
                        <Grid item xs={12} lg={3} style={{ textAlign: 'center', paddingRight: '20px' }}>
                            {user && (
                                <>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ margin: '20px', position: 'relative' }}
                                    >
                                        <Avatar
                                            onClick={handleAvatarClick}
                                            alt={user.name}
                                            src={avatarUrl}
                                            style={{ width: 60, height: 60 }} // Adjust the size as needed
                                        >
                                            {!user.avatar_url && user.name.charAt(0)}
                                        </Avatar>
                                        {isMine && (
                                            <IconButton
                                                onClick={handleAvatarClick}
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 0,
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    width: 24, // Adjust size as needed
                                                    height: 24 // Adjust size as needed
                                                }}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Box>
                                    <Typography variant="h3">{user.name}</Typography>
                                    {myUser.value.id !== user.id ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={startConversation}
                                            style={{ marginTop: '20px' }}
                                        >
                                            Conversation
                                        </Button>
                                    ) : (
                                        <></>
                                    )}

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={processFriendRequest}
                                        style={{ marginTop: '20px', width: '100%' }}
                                    >
                                        {user.friend_status == 'FRIENDS'
                                            ? 'Remove Friend'
                                            : user.friend_status == 'PENDING'
                                            ? 'Cancel Friend Request'
                                            : 'Add Friend'}
                                    </Button>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={10} lg={9}>
                            {feed.map((event, index) => (
                                <FeedEventCard extended={false} user={myUser} key={index} event={event} />
                            ))}
                        </Grid>
                    </Grid>
                </Container>
            )}

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                PaperProps={{
                    sx: customStyles.dialogPaper
                }}
            >
                <DialogTitle>Update Avatar</DialogTitle>
                <DialogContent ref={dialogContentRef} sx={dialogContentStyles}>
                    {uploading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            {cameraStream && <video ref={videoRef} autoPlay playsInline />}
                            {previewImage && (
                                <AvatarEditor
                                    ref={avatarEditorRef}
                                    image={previewImage}
                                    width={dimensions.width}
                                    height={dimensions.height}
                                    border={5}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={zoomLevel}
                                    rotate={0}
                                />
                            )}
                        </>
                    )}
                </DialogContent>
                <Grid display="flex" flexDirection="column">
                    <Slider
                        value={sliderValue}
                        onChange={handleZoomChange}
                        aria-labelledby="zoom-slider"
                        min={0}
                        max={100}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
                        {!capturing && !selectedFile && (
                            <Button variant="contained" component="label">
                                Upload File
                                <input type="file" hidden accept="image/*" onChange={handleFileUpload} />
                            </Button>
                        )}
                        {hasCamera && !capturing && (
                            <Button variant="contained" onClick={handleCameraAccess}>
                                Take a Photo
                            </Button>
                        )}
                        {cameraStream && (
                            <Button variant="contained" onClick={capturePhoto}>
                                Capture
                            </Button>
                        )}

                        {previewImage && (
                            <Button variant="contained" onClick={uploadImage} color="primary">
                                Save Avatar
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Dialog>

            <Footer />
        </>
    );
}

export default Profile;
