import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    Box,
    CircularProgress,
    Button,
    Tabs,
    Tab,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    ListItemIcon,
    Avatar
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import PlaceIcon from '@mui/icons-material/Place';
import GroupIcon from '@mui/icons-material/Groups';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { groupEventAttendToggle, groupEventGet } from '../../../../../graphql/GMAPI';
import { GroupEvent } from '../../../../../graphql/types/Group';
import { format, intervalToDuration, formatDuration } from 'date-fns';
import { useAppSelector } from '../../../../..';
import { selectUser } from '../../../../../redux/features/user/userSlice';
import EventDiscussion from './EventDiscussion';

const GroupEventDisplay: React.FC = () => {
    const [groupEvent, setGroupEvent] = useState<GroupEvent | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id: string }>();
    const [attendeesDialogOpen, setAttendeesDialogOpen] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();
    const user = useAppSelector(selectUser).value;

    const toggleAttendance = async () => {
        // setIsLoading(true);
        try {
            const result = await groupEventAttendToggle(groupEvent.id);
            if (result.errors) {
                setError('Error toggling attendance');
            } else {
                // Update local groupEvent to reflect the new list of attendees
                setGroupEvent((prevState) => ({
                    ...prevState,
                    attendees:
                        result.data.action === 'CREATED'
                            ? [...(prevState.attendees || []), result.data]
                            : prevState.attendees.filter((attendee) => attendee.attendee_id !== result.data.attendee_id)
                }));
            }
        } catch (e) {
            setError('Error toggling attendance');
        }
        // setIsLoading(false);
    };

    useEffect(() => {
        const fetchEvent = async () => {
            setIsLoading(true);
            try {
                const response = await groupEventGet(id);
                if (response.errors) {
                    setError('Error fetching event');
                } else {
                    setGroupEvent(response.data);
                }
            } catch (e) {
                setError('Error fetching event');
            }
            setIsLoading(false);
        };

        if (id) {
            fetchEvent();
        }
    }, [id]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!groupEvent) {
        return <Typography>No event found.</Typography>;
    }

    const formatDate = (start: Date, end: Date) => {
        const formattedStart = format(start, "EEEE, dd MMMM yyyy 'at' HH:mm");
        const duration = intervalToDuration({ start, end });
        return `${formattedStart} (${formatDuration(duration)})`;
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Container maxWidth="md">
            {groupEvent?.isUserMember && (
                <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop={2}>
                    <Typography variant="h4">{groupEvent.title}</Typography>
                    <Button variant="contained" color="primary" onClick={toggleAttendance}>
                        {groupEvent.attendees?.find((attendee) => attendee.attendee?.id === user.id)
                            ? "I can't go"
                            : "I'm going!"}
                    </Button>
                </Box>
            )}
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="event tabs">
                <Tab label="About" />
                <Tab label="Discussion" />
            </Tabs>
            {tabValue === 0 && (
                <>
                    <Card>
                        <CardContent>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <EventIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${formatDate(new Date(groupEvent.start), new Date(groupEvent.end))}`}
                                    />
                                </ListItem>
                                <ListItem button onClick={() => navigate(`/group/instance/${groupEvent.group.id}`)}>
                                    <ListItemIcon>
                                        <GroupIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={groupEvent.group.name} />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => navigate(`/profile/user/${groupEvent.event_owner?.id}`)}
                                >
                                    <ListItemIcon>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`Organised by ${groupEvent.event_owner?.name}` || 'Organiser'}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <PlaceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Location placeholder" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <Button onClick={() => setAttendeesDialogOpen(true)}>
                                        {groupEvent.attendees?.length}{' '}
                                        {groupEvent.attendees?.length == 1 ? 'person' : 'people'} going
                                    </Button>
                                </ListItem>
                            </List>

                            <Box marginTop={2}>
                                <ReactQuill value={groupEvent.body} readOnly={true} theme={null} />
                            </Box>
                        </CardContent>
                    </Card>

                    {/* Attendees count and dialog */}

                    <Dialog onClose={() => setAttendeesDialogOpen(false)} open={attendeesDialogOpen}>
                        <DialogTitle>Attendees</DialogTitle>
                        <DialogContent dividers>
                            <List>
                                {groupEvent.attendees?.map((attendee) => (
                                    <ListItem
                                        button
                                        key={attendee.id}
                                        onClick={() => navigate(`/profile/user/${attendee.attendee?.id}`)}
                                    >
                                        <ListItemIcon>
                                            <Avatar src={attendee.attendee?.avatar_url} />
                                        </ListItemIcon>
                                        <ListItemText primary={attendee.attendee?.name || 'Unknown'} />
                                    </ListItem>
                                ))}
                            </List>
                        </DialogContent>
                    </Dialog>
                </>
            )}
            {tabValue === 1 && (
                <Paper>
                    <EventDiscussion myUser={user} groupPostId={groupEvent?.group_post_id} />{' '}
                </Paper>
            )}
        </Container>
    );
};

export default GroupEventDisplay;
