import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import greyTaskerCard from 'src/assets/img/card-grey-tasker.png';
import healthCard from 'src/assets/img/card-health.png';
import learningCard from 'src/assets/img/card-learning.png';
import circlesCard from 'src/assets/img/card-circles.png';
import styles from './Onboarding.module.css';
import FlipCard from '../../../../components/FlipCard';
import { useNavigate } from 'react-router-dom';

const OnboardingCardsComponent: React.FC = () => {
    const [activeCard, setActiveCard] = useState(0);
    const theme = useTheme();
    const boxRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const cardWidth = isMobile ? window.innerWidth * 0.7 : 300;
    const cardGap = 50; // Adjust based on the gap between your cards
    const [transform, setTransform] = useState<string>();
    const navigate = useNavigate();

    useEffect(() => {
        if (isMobile) {
            if (boxRef.current) {
                console.log('Box width: ' + boxRef.current.clientWidth);
                const activeOffset = activeCard * (cardWidth + cardGap * 2);
                setTransform(
                    `translateX(${boxRef.current.clientWidth / 2 - cardWidth / 2 - cardGap * 1.2 - activeOffset}px)`
                );
            }
        } else {
            if (boxRef.current) {
                console.log('Box width: ' + boxRef.current.clientWidth);
            } else {
                console.log('Box ref is null');
            }

            const activeOffset = activeCard * (cardWidth + cardGap * 2);
            setTransform(`translateX(${window.innerWidth / 2 + cardWidth / 2 - activeOffset}px)`);
        }
    }, [boxRef, activeCard]);

    // Initialize swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => setActiveCard((prevActiveCard) => Math.min(prevActiveCard + 1, cards.length - 1)),
        onSwipedRight: () => setActiveCard((prevActiveCard) => Math.max(prevActiveCard - 1, 0))
    });

    // Update transform on resize to adjust for new screen size
    useEffect(() => {
        const handleResize = () => {
            setActiveCard(0); // Reset to the first card on resize or adjust as needed
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Create an array of cards for dynamic calculation
    const cards = [
        {
            title: 'Grey Tasker',
            imageUrl: greyTaskerCard,
            cardText:
                'Our Gig Economy platform helps you transition out of full-time employment. We can find gigs tailored to your work style and preferences.',
            bgColor: '#FEF4ED'
        },
        {
            title: 'Health & Wellbeing',
            imageUrl: healthCard,
            cardText:
                'Your health and wellbeing is important. Our platform empowers you to actively take control of your wellbeing so that you can live well and always perform at your best.',
            bgColor: '#F0D8A7'
        },
        {
            title: 'Lifelong Learning',
            imageUrl: learningCard,
            bgColor: '#A6BCFF',
            cardText:
                'Embrace lifelong learning with personalised and curated access to online courses, micro-credentials and certifications. Continue to learn, grow, and challenge yourself.'
        },
        {
            title: 'Social Circles',
            imageUrl: circlesCard,
            bgColor: '#4BFFDF',
            cardText:
                'Create and join social circles to stay connected, inspired and supported. Keep active by joining our local community clubs and attending exciting events.'
        }
    ];

    const nextCard = () => {
        if (activeCard == cards.length - 1) {
            navigate('/onboarding/3');
        } else {
            setActiveCard((prevActiveCard) => prevActiveCard + 1);
        }
    };

    const previousCard = () => {
        setActiveCard((prevActiveCard) => (prevActiveCard - 1 + cards.length) % cards.length);
    };

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                backgroundSize: 'cover',
                backgroundColor: '#1D1F27',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#fff',
                overflow: 'hidden'
            }}
        >
            <Typography className={styles.cardsTitle} sx={{ paddingBottom: 2 }}>
                Explore and personalise key areas of your life with your AI companion, ELLA
            </Typography>

            <div ref={boxRef}>
                <Box
                    {...handlers}
                    display="flex"
                    flexDirection="row"
                    gap={`${cardGap}px`}
                    sx={{
                        transition: 'transform 0.5s',
                        transform: transform
                    }}
                >
                    {cards.map((card, index) => (
                        <FlipCard
                            flip={false}
                            key={index}
                            bgColor={card.bgColor}
                            title={card.title}
                            imageUrl={card.imageUrl}
                            cardText={card.cardText}
                        />
                    ))}
                </Box>
            </div>
            <Box sx={{ paddingTop: 2 }}>
                <Button onClick={previousCard}>Back</Button>
                <Button onClick={nextCard}>Next</Button>
                <Button
                    onClick={() => {
                        navigate('/onboarding/3');
                    }}
                    sx={{ position: 'absolute', right: 0 }}
                >
                    Skip
                </Button>
            </Box>
        </Box>
    );
};

export default OnboardingCardsComponent;
