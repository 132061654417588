import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="105" height="100" viewBox="0 0 105 113" fill="none">
            <mask id="mask0_253_2388" maskUnits="userSpaceOnUse" x="0" y="0" width="105" height="113">
                <path d="M105 0H0V112.632H105V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_253_2388)">
                <path
                    d="M85.1652 15.9795L52.25 55.7822L52.7522 56.1985L85.6674 16.3958L85.1652 15.9795Z"
                    fill="white"
                />
                <path
                    d="M85.8445 27.844C83.5162 27.844 80.9369 27.0577 78.2891 24.8143L78.6999 24.3055C86.6206 31.0588 93.9021 23.9354 94.2217 23.6348L94.6782 24.1204C94.6782 24.1436 90.8662 27.844 85.8445 27.844Z"
                    fill="white"
                />
                <path
                    d="M81.0234 25.2755C80.1789 25.2755 79.3115 25.1599 78.3984 24.8823L78.581 24.2348C84.9952 26.1312 89.6289 19.8868 89.6745 19.8174L90.1995 20.2106C90.1995 20.2568 86.456 25.2755 81.0234 25.2755Z"
                    fill="white"
                />
                <path
                    d="M88.0784 33.024C86.9371 33.024 85.7501 32.8621 84.6317 32.4459C81.6414 31.3588 79.473 28.7454 78.1719 24.6749L78.811 24.4668C80.0436 28.3291 82.0751 30.8038 84.8599 31.7983C90.3838 33.8104 97.1175 29.4624 97.186 29.4393L97.5512 29.9943C97.3001 30.1562 92.8491 33.024 88.0784 33.024Z"
                    fill="white"
                />
                <path
                    d="M48.8922 52.914C47.2031 54.9493 47.477 57.9558 49.4629 59.6442C51.4715 61.3556 54.4389 61.0781 56.1052 59.066C57.7715 57.0307 57.5205 54.0241 55.5346 52.3358C53.5259 50.6475 50.5585 50.9019 48.8922 52.914Z"
                    fill="white"
                />
                <path
                    d="M82.8084 36.2619C82.7399 36.2157 76.0747 32.2608 78.1747 24.4668L78.8138 24.6518C76.8508 31.937 83.0823 35.6606 83.1508 35.6837L82.8084 36.2619Z"
                    fill="white"
                />
                <path
                    d="M52.6549 56.2908L52.3125 55.7126L76.9647 41.2578L77.1245 41.5353C79.7495 46.2534 83.0364 46.6466 83.0592 46.6466L82.9908 47.3173C82.8538 47.2941 79.4755 46.9241 76.7136 42.1598L52.6549 56.2908Z"
                    fill="white"
                />
                <path
                    d="M74.6568 48.2423C74.3372 44.4262 76.5285 41.6509 76.6427 41.5121L76.734 41.3965L83.6731 41.6046V42.2753L77.0535 42.0903C76.6427 42.6685 75.0449 45.12 75.3188 48.2192L74.6568 48.2423Z"
                    fill="white"
                />
                <path
                    d="M85.1652 15.9795L52.25 55.7822L52.7522 56.1985L85.6674 16.3958L85.1652 15.9795Z"
                    fill="white"
                />
                <path
                    d="M78.2941 24.8393C69.917 17.6929 76.08 8.41868 76.1257 8.32617L76.6735 8.69621C76.4224 9.06625 70.7843 17.5772 78.705 24.3305L78.2941 24.8393Z"
                    fill="white"
                />
                <path
                    d="M78.1911 24.7218C75.0411 18.2229 80.6107 12.1866 80.6564 12.1172L81.1357 12.5797C81.1357 12.5797 75.8172 18.3617 78.7846 24.4211L78.1911 24.7218Z"
                    fill="white"
                />
                <path
                    d="M78.4586 24.9064C74.2814 24.3282 71.3825 22.5936 69.8075 19.7952C66.7488 14.337 69.8075 6.79742 69.9445 6.47363L70.5608 6.72803C70.5151 6.79741 67.4793 14.2908 70.401 19.4483C71.8619 22.0617 74.6238 23.6806 78.5727 24.2126L78.4586 24.9064Z"
                    fill="white"
                />
                <path
                    d="M56.1127 59.0675C54.4236 61.1027 51.4562 61.3571 49.4703 59.6457C47.4617 57.9342 47.2106 54.9276 48.8997 52.9155C50.5888 50.8803 53.5562 50.6259 55.5421 52.3373C57.528 54.0488 57.8019 57.0554 56.1127 59.0675Z"
                    fill="white"
                />
                <path
                    d="M74.1665 25.95C69.4415 25.95 66.4056 22.319 66.3828 22.2727L66.885 21.8564C67.0676 22.0877 71.6328 27.4996 78.3437 24.2849L78.6176 24.8862C77.0198 25.6494 75.5361 25.95 74.1665 25.95Z"
                    fill="white"
                />
                <path
                    d="M52.8038 56.1059L52.1875 55.8747L61.706 29.3472C56.5929 27.4045 55.6571 24.0972 55.6114 23.9585L56.2505 23.7734C56.2505 23.7734 57.2092 27.0344 62.231 28.8384L62.5505 28.954L62.4364 29.2778L52.8038 56.1059Z"
                    fill="white"
                />
                <path
                    d="M56.1935 32.515L56.125 31.8443C59.1837 31.5668 61.2837 29.5547 61.763 29.0459L60.4391 22.4776L61.0782 22.3389L62.4706 29.2309L62.3565 29.3466C62.288 29.4853 59.937 32.1681 56.1935 32.515Z"
                    fill="white"
                />
                <path
                    d="M102.875 46.6709L52.4297 55.6444L52.5438 56.292L102.989 47.3185L102.875 46.6709Z"
                    fill="white"
                />
                <path
                    d="M104.479 59.1121C102.242 59.1121 93.6591 58.4646 92.0156 48.936L92.6548 48.8203C94.4352 59.1584 104.547 58.4646 104.958 58.4183L105.004 59.089C105.004 59.089 104.798 59.1121 104.479 59.1121Z"
                    fill="white"
                />
                <path
                    d="M101.132 53.4915C98.4382 53.4915 94.4893 52.7746 92.0469 49.0741L92.5947 48.7041C96.2925 54.3473 103.802 52.5664 103.871 52.5664L104.008 53.214C104.008 53.214 102.775 53.4915 101.086 53.4915H101.132Z"
                    fill="white"
                />
                <path
                    d="M103.474 65.4726C103.131 65.4263 95.1651 64.3393 92.038 58.9275C90.4401 56.1521 90.4173 52.7292 92.0151 48.7744L92.6314 49.0288C91.1249 52.7986 91.1249 56.0134 92.6086 58.6037C95.576 63.738 103.474 64.825 103.565 64.825L103.474 65.4726Z"
                    fill="white"
                />
                <path
                    d="M51.6734 51.2722C49.1169 51.7347 47.3821 54.2094 47.8386 56.7997C48.2951 59.4131 50.7375 61.1477 53.294 60.6851C55.8505 60.2226 57.5853 57.7479 57.1288 55.1576C56.6723 52.5673 54.2299 50.8096 51.6734 51.2722Z"
                    fill="white"
                />
                <path
                    d="M88.2184 60.6638C88.1956 60.5944 85.5934 53.1935 92.1217 48.6143L92.4869 49.1693C86.3695 53.4711 88.7206 60.1781 88.8119 60.4556L88.2184 60.6638Z"
                    fill="white"
                />
                <path
                    d="M81.3732 69.2915C81.2819 69.1989 78.9308 66.7012 79.821 61.243L52.4297 56.34L52.5438 55.6924L80.5742 60.7111L80.5058 61.0349C79.5243 66.3542 81.8069 68.8058 81.8297 68.8289L81.3732 69.2915Z"
                    fill="white"
                />
                <path
                    d="M85.0804 65.772L80.1272 61.3315C79.4424 61.5165 76.6804 62.3491 74.9228 64.8932L74.375 64.5231C76.5435 61.4009 80.013 60.6839 80.15 60.6608L80.3098 60.6377L85.5141 65.3095L85.0804 65.772Z"
                    fill="white"
                />
                <path
                    d="M102.875 46.6709L52.4297 55.6444L52.5438 56.292L102.989 47.3185L102.875 46.6709Z"
                    fill="white"
                />
                <path
                    d="M92.0003 48.9357C90.1057 37.9963 100.697 34.8972 100.811 34.874L100.994 35.5216C100.583 35.6372 90.859 38.4819 92.6623 48.82L92.0003 48.9357Z"
                    fill="white"
                />
                <path
                    d="M92.6235 48.9642L91.9844 48.8023C93.6735 41.7715 101.777 40.777 101.868 40.7539L101.937 41.4246C101.868 41.4246 94.1985 42.3728 92.6235 48.9642Z"
                    fill="white"
                />
                <path
                    d="M92.0751 49.0987C89.2447 45.9302 88.1034 42.7154 88.674 39.5469C89.7925 33.3718 96.9142 29.5789 97.211 29.417L97.5077 29.9952C97.4392 30.0414 90.3631 33.7881 89.3131 39.6394C88.7881 42.5998 89.861 45.6295 92.5545 48.6361L92.0751 49.0987Z"
                    fill="white"
                />
                <path
                    d="M53.326 60.7102C50.7695 61.1728 48.3042 59.4382 47.8706 56.8248C47.414 54.2113 49.126 51.7367 51.7053 51.2972C54.2619 50.8578 56.7271 52.5693 57.1608 55.1827C57.5945 57.773 55.8825 60.2477 53.326 60.7102Z"
                    fill="white"
                />
                <path
                    d="M92.238 49.1907C84.5456 47.1323 84.5 39.292 84.5 39.2227H85.1619C85.1619 39.5233 85.2076 46.6467 92.3978 48.5662L92.238 49.1907Z"
                    fill="white"
                />
                <path
                    d="M52.6549 56.2915L52.3125 55.7133L76.394 41.6054C73.7234 36.7717 75.0929 33.6264 75.1386 33.5107L75.7321 33.7883C75.7321 33.7883 74.4082 36.9105 77.1245 41.5592L77.3071 41.8367L52.6549 56.2915Z"
                    fill="white"
                />
                <path
                    d="M75.7727 42.1126C74.4716 42.1126 72.2346 41.8582 70.2031 40.4011L70.5684 39.846C73.0792 41.6269 75.9553 41.465 76.6629 41.3956L79.8129 35.498L80.3836 35.8218L77.0738 42.0201L76.914 42.0432C76.914 42.0432 76.4118 42.1126 75.7727 42.1126Z"
                    fill="white"
                />
                <path
                    d="M52.6711 55.7119L52.3516 56.2901L96.7027 82.2856L97.0222 81.7074L52.6711 55.7119Z"
                    fill="white"
                />
                <path
                    d="M90.7608 92.6041C90.6695 92.5348 81.7445 85.9434 87.2228 76.3223L87.7934 76.6692C82.6119 85.7815 91.0576 92.0028 91.1489 92.0722L90.7608 92.6041Z"
                    fill="white"
                />
                <path
                    d="M93.7568 87.4691C93.6883 87.4228 86.4068 83.6761 87.1829 76.4834L87.8448 76.5528C87.1144 83.2829 93.9851 86.8446 94.0535 86.8677L93.7568 87.4691Z"
                    fill="white"
                />
                <path
                    d="M85.5579 96.489C85.3297 96.2346 79.8971 90.2214 80.9471 84.0001C81.4721 80.8085 83.6405 78.195 87.3612 76.2061L87.6808 76.7842C84.1427 78.6807 82.1112 81.1554 81.609 84.1157C80.6275 89.9902 85.9916 95.9571 86.0601 96.0265L85.5579 96.489Z"
                    fill="white"
                />
                <path
                    d="M54.8803 51.8511C52.6205 50.5328 49.7444 51.3191 48.4433 53.6088C47.1422 55.8984 47.9183 58.8125 50.1781 60.1539C52.4379 61.4722 55.314 60.6858 56.615 58.3962C57.9161 56.0834 57.14 53.1693 54.8803 51.8511Z"
                    fill="white"
                />
                <path
                    d="M77.5225 83.1003L76.9062 82.869C76.9291 82.7997 79.6454 75.445 87.5432 76.1851L87.4747 76.8558C80.0334 76.1389 77.5454 83.0309 77.5225 83.1003Z"
                    fill="white"
                />
                <path
                    d="M66.2196 85.0169C66.2196 84.855 65.9913 81.4321 70.1228 77.8473L52.25 56.2229L52.7522 55.8066L71.0587 77.9398L70.8076 78.148C66.6761 81.594 66.8815 84.9475 66.8815 84.9706L66.2196 85.0169Z"
                    fill="white"
                />
                <path
                    d="M71.2713 84.7382L70.3126 78.1005C69.6734 77.7767 67.0256 76.6435 64.081 77.4298L63.8984 76.7822C67.5278 75.7878 70.6321 77.4992 70.7691 77.5917L70.906 77.6611L71.9104 84.6225L71.2713 84.7382Z"
                    fill="white"
                />
                <path
                    d="M52.6711 55.7119L52.3516 56.2901L96.7027 82.2856L97.0222 81.7074L52.6711 55.7119Z"
                    fill="white"
                />
                <path
                    d="M87.7816 76.6692L87.2109 76.3223C92.6892 66.7012 102.756 71.2573 102.87 71.3036L102.596 71.9049C102.208 71.7199 92.9631 67.58 87.7816 76.6692Z"
                    fill="white"
                />
                <path
                    d="M99.6001 77.0837C99.5317 77.0374 93.0719 72.7819 87.6849 76.783L87.2969 76.2511C93.049 71.9725 99.8969 76.4824 99.9425 76.5286L99.6001 77.0837Z"
                    fill="white"
                />
                <path
                    d="M87.1942 76.5283C87.0344 72.2497 88.1985 69.0581 90.6409 66.9997C95.4115 62.9986 103.264 64.7332 103.583 64.8257L103.446 65.4733C103.378 65.4502 95.5713 63.7156 91.0746 67.5085C88.792 69.4281 87.7192 72.4579 87.8561 76.5052L87.1942 76.5283Z"
                    fill="white"
                />
                <path
                    d="M50.1625 60.1324C47.9027 58.8141 47.1266 55.8769 48.4277 53.5873C49.7288 51.2976 52.6049 50.5113 54.8646 51.8296C57.1244 53.1478 57.9005 56.0851 56.5994 58.3747C55.2983 60.6875 52.4222 61.4507 50.1625 60.1324Z"
                    fill="white"
                />
                <path
                    d="M87.2338 76.6893C82.6457 70.0979 87.5762 64.0616 87.6218 63.9922L88.124 64.4085C87.9186 64.6398 83.4675 70.121 87.7588 76.2961L87.2338 76.6893Z"
                    fill="white"
                />
                <path
                    d="M80.4914 61.3341L52.4609 56.3154L52.5751 55.6678L79.9664 60.5708C80.9707 55.1358 84.0066 53.6094 84.1435 53.54L84.4175 54.1414C84.4175 54.1414 81.4273 55.6678 80.5599 61.0103L80.4914 61.3341Z"
                    fill="white"
                />
                <path
                    d="M80.191 61.3357L80.054 61.2432C79.9399 61.1738 76.904 59.2774 75.9453 55.6001L76.5844 55.415C77.3834 58.4216 79.6888 60.1562 80.2823 60.5725L86.4225 58.0979L86.6736 58.7223L80.191 61.3357Z"
                    fill="white"
                />
                <path
                    d="M52.7882 55.876L52.1719 56.1073L69.6795 104.953L70.2958 104.722L52.7882 55.876Z"
                    fill="white"
                />
                <path
                    d="M58.578 108.999C58.5552 108.884 55.8845 98.0368 66.1791 94.2207L66.4073 94.8452C56.6834 98.4299 59.103 108.398 59.2171 108.814L58.578 108.999Z"
                    fill="white"
                />
                <path
                    d="M64.1686 107.013C64.1229 106.943 60.9273 99.311 66.086 94.3154L66.5425 94.8011C61.7262 99.4729 64.7164 106.689 64.7621 106.758L64.1686 107.013Z"
                    fill="white"
                />
                <path
                    d="M52.1609 108.584C52.1381 108.237 51.8185 100.096 56.5207 96.0252C58.9402 93.9438 62.2728 93.3424 66.3815 94.2444L66.2446 94.892C62.3413 94.0363 59.2141 94.5913 56.9544 96.534C52.4804 100.373 52.8 108.468 52.8 108.56L52.1609 108.584Z"
                    fill="white"
                />
                <path
                    d="M56.9332 54.3689C56.0429 51.8942 53.3495 50.5991 50.8842 51.5011C48.4418 52.4031 47.1636 55.1553 48.0766 57.6299C48.9668 60.1046 51.6603 61.3997 54.1255 60.4977C56.5679 59.5958 57.8462 56.8436 56.9332 54.3689Z"
                    fill="white"
                />
                <path
                    d="M66.0871 94.7733C60.8599 89.4077 54.5599 93.0387 54.5143 93.0618L54.1719 92.4836C54.2404 92.4374 60.9512 88.5519 66.5436 94.3107L66.0871 94.7733Z"
                    fill="white"
                />
                <path
                    d="M45.15 87.5569L44.625 87.1637C44.7163 87.0481 46.7022 84.2728 52.1576 84.2265V56.0107H52.8196V84.8972H52.5C47.1815 84.8278 45.1956 87.5338 45.15 87.5569Z"
                    fill="white"
                />
                <path
                    d="M49.2109 90.5877L48.663 90.2408L52.1098 84.5283C51.8359 83.8807 50.5348 81.2673 47.75 79.949L48.0239 79.3477C51.425 80.9666 52.7261 84.297 52.7717 84.4357L52.8402 84.5976L49.2109 90.5877Z"
                    fill="white"
                />
                <path
                    d="M52.7882 55.876L52.1719 56.1073L69.6795 104.953L70.2958 104.722L52.7882 55.876Z"
                    fill="white"
                />
                <path
                    d="M80.789 100.881C80.6064 100.488 76.1553 91.2833 66.4314 94.845L66.2031 94.2205C76.4977 90.4276 81.3368 100.488 81.3825 100.581L80.789 100.881Z"
                    fill="white"
                />
                <path
                    d="M75.2453 102.87C75.2225 102.801 72.9627 95.3074 66.3203 94.868L66.366 94.1973C73.4877 94.6598 75.8616 102.593 75.8844 102.662L75.2453 102.87Z"
                    fill="white"
                />
                <path
                    d="M85.5331 96.4642C85.4875 96.3948 80.6027 89.9884 74.7364 89.9422H74.6908C71.7462 89.9422 69.0071 91.5611 66.5875 94.7296L66.0625 94.3133C68.619 90.9598 71.5408 89.2715 74.6908 89.2715H74.7364C80.9223 89.3177 85.8299 95.7704 86.0353 96.0479L85.5331 96.4642Z"
                    fill="white"
                />
                <path
                    d="M48.0831 57.6325C47.1929 55.1578 48.4483 52.4056 50.8907 51.5036C53.3331 50.6017 56.0494 51.8737 56.9396 54.3715C57.8298 56.8461 56.5744 59.5983 54.132 60.5003C51.6896 61.4023 48.9961 60.1071 48.0831 57.6325Z"
                    fill="white"
                />
                <path
                    d="M66.6541 94.5885L65.9922 94.5422C66.6541 86.4938 74.2552 85.1061 74.3237 85.083L74.4378 85.7306C74.1411 85.7768 67.2705 87.0489 66.6541 94.5885Z"
                    fill="white"
                />
                <path
                    d="M70.5409 78.3307L52.2344 56.1976L52.7366 55.7812L70.6094 77.4056C74.8094 73.8902 78.1192 74.7228 78.2561 74.746L78.0963 75.3935C78.0963 75.3935 74.855 74.6072 70.792 78.1457L70.5409 78.3307Z"
                    fill="white"
                />
                <path
                    d="M76.9222 80.3674L70.3483 78.1471L70.3026 77.9852C70.257 77.8465 69.1385 74.4467 70.7135 70.9775L71.307 71.2551C70.0059 74.0767 70.6679 76.9214 70.8733 77.6152L77.1505 79.7429L76.9222 80.3674Z"
                    fill="white"
                />
                <path d="M52.1867 55.876L34.6562 104.699L35.2726 104.93L52.803 56.1073L52.1867 55.876Z" fill="white" />
                <path
                    d="M24.2107 100.858L23.6172 100.581C23.6628 100.488 28.502 90.4276 38.7965 94.2205L38.5683 94.845C28.8444 91.2602 24.3933 100.488 24.2107 100.858Z"
                    fill="white"
                />
                <path
                    d="M29.7329 102.87L29.0938 102.685C29.1166 102.616 31.4905 94.6833 38.6122 94.2207L38.6579 94.8914C31.9927 95.3077 29.7557 102.801 29.7329 102.87Z"
                    fill="white"
                />
                <path
                    d="M19.4703 96.4642L18.9453 96.0479C19.1507 95.7704 24.0584 89.3177 30.2442 89.2715H30.2899C33.4399 89.2715 36.3616 90.9829 38.9181 94.3133L38.3931 94.7296C35.9508 91.538 33.2116 89.9422 30.2899 89.9422C30.2671 89.9422 30.2671 89.9422 30.2442 89.9422C24.4007 89.9884 19.516 96.3948 19.4703 96.4642Z"
                    fill="white"
                />
                <path
                    d="M56.9396 57.6325C57.8298 55.1578 56.5744 52.4056 54.132 51.5036C51.6896 50.6017 48.9733 51.8737 48.0831 54.3715C47.1929 56.8461 48.4483 59.5983 50.8907 60.5003C53.3559 61.4023 56.0494 60.1071 56.9396 57.6325Z"
                    fill="white"
                />
                <path
                    d="M38.3618 94.5677C37.7455 87.0512 30.852 85.756 30.5781 85.6866L30.6923 85.0391C30.7607 85.0622 38.3618 86.473 39.0238 94.4983L38.3618 94.5677Z"
                    fill="white"
                />
                <path
                    d="M34.4496 78.3307L34.1985 78.1226C30.1355 74.6072 26.917 75.3704 26.8942 75.3704L26.7344 74.7228C26.8713 74.6766 30.1583 73.8671 34.3811 77.3825L52.254 55.7812L52.7561 56.1976L34.4496 78.3307Z"
                    fill="white"
                />
                <path
                    d="M28.0804 80.3674L27.875 79.7429L34.1522 77.6152C34.3348 76.9214 34.9967 74.0767 33.7185 71.2551L34.312 70.9775C35.9098 74.4467 34.7913 77.8465 34.7228 77.9852L34.6772 78.124L28.0804 80.3674Z"
                    fill="white"
                />
                <path d="M52.1867 55.876L34.6562 104.699L35.2726 104.93L52.803 56.1073L52.1867 55.876Z" fill="white" />
                <path
                    d="M46.3918 108.999L45.7527 108.837C45.8668 108.421 48.2864 98.4531 38.5625 94.8452L38.7908 94.2207C49.0853 98.0368 46.4375 108.884 46.3918 108.999Z"
                    fill="white"
                />
                <path
                    d="M40.8421 107.013L40.2257 106.758C40.2714 106.689 43.2844 99.4729 38.4453 94.8011L38.9018 94.3154C44.0605 99.311 40.8649 106.943 40.8421 107.013Z"
                    fill="white"
                />
                <path
                    d="M52.83 108.583L52.1681 108.537C52.1681 108.468 52.5105 100.373 48.0366 96.5108C45.7768 94.5681 42.6496 94.013 38.7463 94.8456L38.6094 94.1981C42.7181 93.2961 46.0507 93.9205 48.4702 95.9789C53.1952 100.072 52.8529 108.213 52.83 108.583Z"
                    fill="white"
                />
                <path
                    d="M48.0831 54.3689C48.9733 51.8942 51.6668 50.5991 54.132 51.5011C56.5744 52.4031 57.8298 55.1553 56.9396 57.6299C56.0494 60.1046 53.3559 61.3997 50.8907 60.4977C48.4483 59.5958 47.1929 56.8436 48.0831 54.3689Z"
                    fill="white"
                />
                <path
                    d="M38.8934 94.7778L38.4141 94.3153C44.0065 88.5796 50.7402 92.4651 50.7858 92.4882L50.4434 93.0664C50.2152 92.9276 44.1206 89.4122 38.8934 94.7778Z"
                    fill="white"
                />
                <path
                    d="M59.8414 87.5605C59.8414 87.5605 57.8327 84.8314 52.5143 84.9008H52.1719V55.9912H52.8338V84.207C58.2893 84.2532 60.298 87.0286 60.3664 87.1442L59.8414 87.5605Z"
                    fill="white"
                />
                <path
                    d="M55.7622 90.5877L52.1328 84.5976L52.2013 84.4357C52.2469 84.297 53.548 80.9435 56.9491 79.3477L57.223 79.949C54.4382 81.2673 53.16 83.8807 52.8632 84.5283L56.31 90.2408L55.7622 90.5877Z"
                    fill="white"
                />
                <path
                    d="M52.3355 55.6924L7.98438 81.6879L8.30395 82.2661L52.655 56.2706L52.3355 55.6924Z"
                    fill="white"
                />
                <path
                    d="M17.2209 76.6665C12.0393 67.5773 2.79477 71.7403 2.40673 71.9022L2.13281 71.3009C2.22412 71.2546 12.3133 66.6985 17.7915 76.3427L17.2209 76.6665Z"
                    fill="white"
                />
                <path
                    d="M5.38865 77.0569L5.02344 76.5018C5.09192 76.4555 11.9397 71.9225 17.6691 76.2242L17.281 76.7562C11.9169 72.7551 5.45713 77.0337 5.38865 77.0569Z"
                    fill="white"
                />
                <path
                    d="M17.8032 76.5057L17.1412 76.4826C17.2782 72.4353 16.2054 69.4055 13.9228 67.4859C9.40319 63.6699 1.61949 65.4276 1.55101 65.4276L1.41406 64.78C1.75645 64.7106 9.60863 62.9529 14.3565 66.9771C16.8217 69.0586 17.963 72.2502 17.8032 76.5057Z"
                    fill="white"
                />
                <path
                    d="M54.8803 60.1324C57.14 58.8141 57.9161 55.8769 56.615 53.5873C55.314 51.2976 52.4379 50.5113 50.1781 51.8296C47.9183 53.1478 47.1422 56.0851 48.4433 58.3747C49.7216 60.6875 52.6205 61.4507 54.8803 60.1324Z"
                    fill="white"
                />
                <path
                    d="M17.7496 76.6893L17.2246 76.2961C21.5159 70.121 16.905 64.4779 16.8594 64.4085L17.3615 63.9922C17.3615 64.0153 22.3376 70.121 17.7496 76.6893Z"
                    fill="white"
                />
                <path
                    d="M24.5126 61.3341L24.467 61.0103C23.5996 55.6678 20.6322 54.1645 20.6094 54.1414L20.8833 53.54C21.0202 53.6094 24.0561 55.1127 25.0605 60.5708L52.4518 55.6678L52.5659 56.3154L24.5126 61.3341Z"
                    fill="white"
                />
                <path
                    d="M24.8192 61.3126L18.3594 58.7223L18.6105 58.0979L24.7507 60.5725C25.3213 60.1793 27.6496 58.4216 28.4485 55.415L29.0876 55.6001C28.1061 59.2774 25.0931 61.1738 24.9561 61.2432L24.8192 61.3126Z"
                    fill="white"
                />
                <path
                    d="M52.3355 55.6924L7.98438 81.6879L8.30395 82.2661L52.655 56.2706L52.3355 55.6924Z"
                    fill="white"
                />
                <path
                    d="M14.2474 92.6041L13.8594 92.0722C14.2018 91.8178 22.3735 85.7815 17.2148 76.6692L17.7855 76.3223C23.2409 85.9665 14.3159 92.5348 14.2474 92.6041Z"
                    fill="white"
                />
                <path
                    d="M11.2577 87.4466L10.9609 86.8453C11.0294 86.799 17.9001 83.2605 17.1696 76.5303L17.8316 76.4609C18.6077 83.6768 11.3262 87.4235 11.2577 87.4466Z"
                    fill="white"
                />
                <path
                    d="M19.4516 96.4874L18.9722 96.048C18.9722 96.048 24.4048 90.0116 23.4233 84.1372C22.9211 81.1769 20.8896 78.7022 17.3516 76.8057L17.6711 76.2275C21.3918 78.2165 23.5374 80.8531 24.0853 84.0216C25.0896 90.1967 19.6798 96.2099 19.4516 96.4874Z"
                    fill="white"
                />
                <path
                    d="M50.1625 51.8511C52.4222 50.5328 55.2983 51.3191 56.5994 53.6088C57.9005 55.8984 57.1244 58.8125 54.8646 60.1539C52.6049 61.4722 49.7288 60.6858 48.4277 58.3962C47.1266 56.0834 47.9027 53.1693 50.1625 51.8511Z"
                    fill="white"
                />
                <path
                    d="M27.4738 83.0975C27.3825 82.82 24.9173 76.1361 17.5216 76.853L17.4531 76.1823C25.351 75.4191 28.0673 82.7968 28.0901 82.8662L27.4738 83.0975Z"
                    fill="white"
                />
                <path
                    d="M38.7844 85.0169L38.1225 84.9706C38.1225 84.9706 38.3279 81.594 34.1964 78.148L33.9453 77.9398L52.2518 55.8066L52.754 56.2229L34.8812 77.8242C39.0355 81.4321 38.8073 84.855 38.7844 85.0169Z"
                    fill="white"
                />
                <path
                    d="M33.7401 84.7138L33.0781 84.6213L34.0825 77.6599L34.2194 77.5905C34.3564 77.5211 37.4607 75.8096 41.0901 76.781L40.9075 77.4286C37.9401 76.6191 35.2923 77.7755 34.676 78.0993L33.6944 84.7369H33.7401V84.7138Z"
                    fill="white"
                />
                <path
                    d="M2.12976 46.625L2.01562 47.2726L52.4385 56.2924L52.5526 55.6448L2.12976 46.625Z"
                    fill="white"
                />
                <path
                    d="M13.0019 48.9357L12.3628 48.82C14.166 38.4588 4.12255 35.5447 4.03125 35.5216L4.21386 34.874C4.30516 34.8972 14.8965 37.9963 13.0019 48.9357Z"
                    fill="white"
                />
                <path
                    d="M12.3912 48.9642C10.8162 42.396 3.1466 41.4246 3.07812 41.4246L3.14661 40.7539C3.23791 40.7539 11.3412 41.7484 13.0303 48.8023L12.3912 48.9642Z"
                    fill="white"
                />
                <path
                    d="M12.9482 49.0987L12.4689 48.6361C15.1395 45.6295 16.2352 42.5998 15.7102 39.6394C14.6602 33.765 7.5841 30.0414 7.51562 29.9952L7.81236 29.417C8.13193 29.5789 15.2537 33.3718 16.3493 39.5469C16.8971 42.6923 15.7787 45.9302 12.9482 49.0987Z"
                    fill="white"
                />
                <path
                    d="M51.6734 60.709C54.2299 61.1715 56.6951 59.437 57.1516 56.8235C57.6082 54.2101 55.8962 51.7354 53.3168 51.2729C50.7603 50.8103 48.2951 52.5449 47.8386 55.1583C47.3821 57.7486 49.1169 60.2464 51.6734 60.709Z"
                    fill="white"
                />
                <path
                    d="M12.777 49.1914L12.6172 48.5438C19.8074 46.6242 19.8531 39.2927 19.8531 39.2002H20.515C20.515 39.2927 20.4694 47.133 12.777 49.1914Z"
                    fill="white"
                />
                <path
                    d="M52.3319 56.2915L27.6797 41.8367L27.8623 41.5592C30.5786 36.8874 29.2775 33.8114 29.2547 33.7883L29.8482 33.5107C29.9166 33.6495 31.2862 36.7949 28.5927 41.6054L52.6743 55.7133L52.3319 56.2915Z"
                    fill="white"
                />
                <path
                    d="M29.2437 42.1126C28.5817 42.1126 28.148 42.0432 28.1024 42.0432L27.9426 42.02L24.6328 35.8218L25.2035 35.498L28.3535 41.3956C29.0382 41.465 31.9372 41.6269 34.448 39.846L34.8132 40.4011C32.7589 41.8813 30.5448 42.1357 29.2437 42.1126Z"
                    fill="white"
                />
                <path
                    d="M2.12976 46.625L2.01562 47.2726L52.4385 56.2924L52.5526 55.6448L2.12976 46.625Z"
                    fill="white"
                />
                <path
                    d="M0.547825 59.0887C0.205433 59.0887 0.0228261 59.0656 0 59.0656L0.045655 58.3949C0.479351 58.4411 10.5685 59.1349 12.3717 48.7969L13.0109 48.9125C11.3674 58.4642 2.78478 59.0887 0.547825 59.0887Z"
                    fill="white"
                />
                <path
                    d="M3.8983 53.4684C2.20917 53.4684 0.999389 53.1909 0.976562 53.1909L1.11352 52.5433C1.182 52.5664 8.71461 54.3241 12.3896 48.7041L12.9374 49.0741C10.5179 52.7514 6.56895 53.4684 3.8983 53.4684Z"
                    fill="white"
                />
                <path
                    d="M1.53662 65.4508L1.44531 64.7801C1.51379 64.7801 9.43444 63.6931 12.4018 58.5588C13.8855 55.9685 13.8855 52.7537 12.4018 48.9839L13.0181 48.7295C14.5931 52.6843 14.5931 56.1072 12.9953 58.8825C9.86815 64.3176 1.87901 65.4046 1.53662 65.4508Z"
                    fill="white"
                />
                <path
                    d="M53.3168 51.2722C55.8734 51.7347 57.6082 54.2094 57.1516 56.8228C56.6951 59.4362 54.2527 61.1477 51.6734 60.7083C49.1169 60.2457 47.3821 57.7711 47.8386 55.1576C48.2951 52.5673 50.7375 50.8096 53.3168 51.2722Z"
                    fill="white"
                />
                <path
                    d="M16.7991 60.6635L16.1828 60.4322C16.2741 60.1547 18.6252 53.4476 12.5078 49.1459L12.873 48.5908C19.4013 53.1932 16.8219 60.5941 16.7991 60.6635Z"
                    fill="white"
                />
                <path
                    d="M23.62 69.2671L23.1406 68.8045C23.1406 68.8045 25.4461 66.3298 24.4645 61.0105L24.3961 60.6867L52.4265 55.668L52.5406 56.3155L25.1493 61.2186C26.0624 66.6999 23.7341 69.1745 23.62 69.2671Z"
                    fill="white"
                />
                <path
                    d="M19.9337 65.7734L19.5 65.2646L24.7043 60.5928L24.8641 60.6159C25.0011 60.639 28.4706 61.356 30.6391 64.4782L30.0913 64.8483C28.3337 62.2811 25.5717 61.4716 24.887 61.2866L19.9337 65.7734Z"
                    fill="white"
                />
                <path
                    d="M19.8381 15.9795L19.3359 16.3958L52.274 56.2217L52.7762 55.8054L19.8381 15.9795Z"
                    fill="white"
                />
                <path
                    d="M26.7234 24.8139L26.3125 24.3051C34.2332 17.5519 28.5951 9.04087 28.3668 8.67083L28.9147 8.30078C28.9603 8.39329 35.1234 17.6906 26.7234 24.8139Z"
                    fill="white"
                />
                <path
                    d="M26.8196 24.6964L26.2261 24.3957C29.1935 18.3363 23.9207 12.6006 23.875 12.5544L24.3544 12.0918C24.3315 12.0918 29.9696 18.1975 26.8196 24.6964Z"
                    fill="white"
                />
                <path
                    d="M26.5679 24.9077L26.4766 24.237C30.4255 23.6819 33.1874 22.0861 34.6483 19.4727C37.5472 14.2921 34.5113 6.79871 34.4885 6.75245L35.1048 6.49805C35.2418 6.82183 38.3005 14.3615 35.219 19.8196C33.644 22.5949 30.745 24.3064 26.5679 24.9077Z"
                    fill="white"
                />
                <path
                    d="M48.8922 59.0675C50.5813 61.1027 53.5487 61.3571 55.5346 59.6457C57.5433 57.9342 57.7944 54.9276 56.1052 52.9155C54.4161 50.8803 51.4487 50.6259 49.4629 52.3373C47.477 54.0488 47.2031 57.0554 48.8922 59.0675Z"
                    fill="white"
                />
                <path
                    d="M30.8339 25.95C29.4872 25.95 27.9806 25.6494 26.3828 24.8862L26.6567 24.2849C33.3904 27.4996 37.9328 22.0877 38.1154 21.8564L38.6176 22.2727C38.6176 22.2727 35.5589 25.95 30.8339 25.95Z"
                    fill="white"
                />
                <path
                    d="M52.2077 56.1063L42.4609 28.9543L42.7805 28.8387C47.8251 27.0347 48.7609 23.82 48.7609 23.7969L49.4001 23.9819C49.3544 24.1207 48.4185 27.4279 43.3055 29.3706L52.8012 55.8981L52.1848 56.1294H52.2077V56.1063Z"
                    fill="white"
                />
                <path
                    d="M48.8234 32.5176C45.08 32.1707 42.7289 29.4879 42.6376 29.3954L42.5234 29.2797L43.9158 22.3877L44.555 22.5265L43.2311 29.0947C43.7104 29.6035 45.8332 31.6156 48.8691 31.8932L48.8234 32.5176Z"
                    fill="white"
                />
                <path
                    d="M19.8381 15.9795L19.3359 16.3958L52.274 56.2217L52.7762 55.8054L19.8381 15.9795Z"
                    fill="white"
                />
                <path
                    d="M19.1846 27.844C14.1401 27.844 10.3738 24.1667 10.3281 24.1204L10.7846 23.6348C11.1042 23.9354 18.3857 31.0588 26.3064 24.3055L26.7173 24.8143C24.0923 27.0808 21.5129 27.844 19.1846 27.844Z"
                    fill="white"
                />
                <path
                    d="M23.9886 25.2797C18.5331 25.2797 14.8582 20.2842 14.8125 20.2379L15.3375 19.8447C15.3831 19.9141 20.0168 26.1586 26.431 24.2621L26.6136 24.9097C25.7005 25.141 24.8331 25.2566 23.9886 25.2797Z"
                    fill="white"
                />
                <path
                    d="M16.9344 33.024C12.1637 33.024 7.71264 30.1562 7.48438 29.9943L7.8496 29.4393C7.91808 29.4855 14.6746 33.8335 20.1757 31.7983C22.9605 30.7807 24.992 28.306 26.2246 24.4668L26.8637 24.6749C25.5626 28.7454 23.3942 31.3588 20.4039 32.4459C19.2398 32.839 18.0757 33.0009 16.9344 33.024Z"
                    fill="white"
                />
                <path
                    d="M56.1052 52.9157C57.7944 54.951 57.5205 57.9576 55.5346 59.6459C53.5259 61.3573 50.5585 61.0798 48.8922 59.0677C47.2031 57.0325 47.477 54.0259 49.4628 52.3375C51.4715 50.6261 54.4389 50.9036 56.1052 52.9157Z"
                    fill="white"
                />
                <path
                    d="M22.1711 36.2619L21.8516 35.6837C22.1026 35.545 28.1516 31.9139 26.1885 24.6518L26.8276 24.4668C28.9048 32.2608 22.2396 36.2388 22.1711 36.2619Z"
                    fill="white"
                />
                <path
                    d="M52.3334 56.2901L28.2519 42.1823C25.4671 46.9466 22.1117 47.3166 21.9747 47.3397L21.9062 46.669C21.9062 46.669 25.216 46.2758 27.8639 41.5578L28.0236 41.2803L52.6758 55.7351L52.3334 56.2901Z"
                    fill="white"
                />
                <path
                    d="M30.3685 48.2439L29.7065 48.1745C29.9804 45.0754 28.3826 42.6239 27.9717 42.0457L21.375 42.2307V41.56L28.2913 41.375L28.3826 41.4906C28.4967 41.6294 30.7109 44.4279 30.3685 48.2439Z"
                    fill="white"
                />
                <path d="M52.8338 4.02246H52.1719V55.9904H52.8338V4.02246Z" fill="white" />
                <path
                    d="M52.4922 15.3094V14.6387C62.8324 14.6387 63.9052 4.43943 63.9509 4L64.6128 4.06938C64.6128 4.18502 63.4715 15.3094 52.4922 15.3094Z"
                    fill="white"
                />
                <path
                    d="M52.6339 15.2863L52.3828 14.6619C58.5002 11.9328 58.0893 4.11564 58.0893 4.04626L58.7513 4C58.7285 4.09251 59.1622 12.3722 52.6339 15.2863Z"
                    fill="white"
                />
                <path
                    d="M59.1375 17.5537C56.9918 17.5537 54.7092 16.7905 52.3125 15.2641L52.6549 14.709C56.0332 16.8599 59.1603 17.4381 61.9451 16.3973C67.4462 14.339 69.8658 6.6143 69.8886 6.54492L70.5277 6.75307C70.4364 7.07686 67.994 14.8478 62.1962 17.0449C61.1918 17.3456 60.1875 17.5537 59.1375 17.5537Z"
                    fill="white"
                />
                <path
                    d="M47.7969 55.9915C47.7969 58.6281 49.8969 60.779 52.5219 60.779C55.124 60.779 57.2469 58.6281 57.2469 55.9915C57.2469 53.355 55.1469 51.2041 52.5219 51.2041C49.8969 51.2041 47.7969 53.355 47.7969 55.9915Z"
                    fill="white"
                />
                <path
                    d="M61.7588 21.2075C59.3621 21.2075 54.6143 20.4905 52.1719 15.1018L52.7654 14.8242C55.8697 21.6931 62.9915 20.4674 63.0828 20.4443L63.1969 21.0918C63.1969 21.115 62.6262 21.2075 61.7588 21.2075Z"
                    fill="white"
                />
                <path
                    d="M52.8038 56.1068L52.1875 55.8755L61.9342 28.7236L62.2538 28.8393C67.2527 30.7357 70.0147 28.9087 70.0375 28.8855L70.4027 29.4406C70.2886 29.5331 67.4581 31.4296 62.3223 29.5794L52.8038 56.1068Z"
                    fill="white"
                />
                <path
                    d="M64.5432 35.6155C61.8954 32.9327 61.7812 29.3248 61.7812 29.186V29.0241L67.2139 24.6299L67.6247 25.1618L62.4432 29.3248C62.4889 30.0418 62.8312 32.9558 64.9997 35.1299L64.5432 35.6155Z"
                    fill="white"
                />
                <path d="M52.8338 4.02246H52.1719V55.9904H52.8338V4.02246Z" fill="white" />
                <path
                    d="M52.5035 15.3094C41.5469 15.3094 40.3828 4.18502 40.3828 4.06938L41.0448 4C41.0904 4.43943 42.1632 14.6387 52.5035 14.6387V15.3094Z"
                    fill="white"
                />
                <path
                    d="M52.3662 15.2863C45.8379 12.3722 46.2716 4.09251 46.2716 4L46.9335 4.04626C46.9335 4.11564 46.5227 11.9328 52.6401 14.6619L52.3662 15.2863Z"
                    fill="white"
                />
                <path
                    d="M45.8512 17.5531C44.8012 17.5531 43.774 17.3681 42.7925 16.998C36.9946 14.824 34.5522 7.02999 34.4609 6.7062L35.1001 6.49805C35.1229 6.56743 37.5425 14.2921 43.0435 16.3504C45.8283 17.3912 48.9555 16.8361 52.3338 14.6621L52.6762 15.2172C50.2794 16.7899 47.9968 17.5531 45.8512 17.5531Z"
                    fill="white"
                />
                <path
                    d="M57.2313 55.9915C57.2313 58.6281 55.1313 60.779 52.5063 60.779C49.9041 60.779 47.7812 58.6281 47.7812 55.9915C47.7812 53.355 49.8813 51.2041 52.5063 51.2041C55.1084 51.2041 57.2313 53.355 57.2313 55.9915Z"
                    fill="white"
                />
                <path
                    d="M43.2271 21.2097C42.3597 21.2097 41.7891 21.1172 41.7891 21.1172L41.9032 20.4696C42.1999 20.5159 49.0934 21.6954 52.2206 14.8496L52.8141 15.1271C50.3489 20.4928 45.601 21.2097 43.2271 21.2097Z"
                    fill="white"
                />
                <path
                    d="M52.2005 56.1068L42.682 29.5794C37.5233 31.4296 34.7157 29.51 34.6016 29.4406L34.9668 28.8855C34.9668 28.8855 37.7516 30.7589 42.7505 28.8393L43.07 28.7236L52.8168 55.8755L52.2005 56.1068Z"
                    fill="white"
                />
                <path
                    d="M40.4403 35.6155L39.9609 35.1299C42.1294 32.9327 42.4718 30.0186 42.5175 29.3248L37.3359 25.1618L37.7468 24.6299L43.1794 29.0241V29.186C43.2023 29.3248 43.1109 32.9096 40.4403 35.6155Z"
                    fill="white"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
