import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ReactComponent as Logo } from 'src/assets/svg/logo.svg';
import backgroundImage from 'src/assets/img/onboarding-bg1.jpg';
import styles from './Onboarding.module.css';
import { useNavigate } from 'react-router-dom';

const OnboardingStartComponent: React.FC = () => {
    const navigate = useNavigate();

    const navigateStep2 = () => {
        navigate('/onboarding/2');
    };

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#fff' // Adjust the color based on your image
            }}
        >
            <Logo />
            <Typography className={styles.greymattersLogo} paddingTop={1} gutterBottom sx={{ paddingBottom: 3 }}>
                Grey Matters
            </Typography>
            <Typography
                variant="h1"
                gutterBottom
                className={styles.greymattersHeaderName}
                sx={{ paddingBottom: '30vh' }}
            >
                Welcome
            </Typography>
            <Typography className={styles.greymattersMain} sx={{ mb: 2 }}>
                Are you ready to embark on a journey of rediscovery?
            </Typography>
            <Button variant="contained" href="#" sx={{ mt: 2 }} onClick={navigateStep2}>
                Let&apos;s get started →
            </Button>
        </Box>
    );
};

export default OnboardingStartComponent;
