import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { UserUnreadNotification } from '../../../graphql/types/User';

// Define a type for the slice state
interface MessagesState {
    value: UserUnreadNotification[];
    currentUserConversationId: string;
}

// Define the initial state using that type
const initialState: MessagesState = {
    value: undefined,
    currentUserConversationId: undefined
};

export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setUnreadMessages: (state, action: PayloadAction<UserUnreadNotification[]>) => {
            state.value = action.payload;
        },

        addUnreadMessage: (state, action: PayloadAction<UserUnreadNotification>) => {
            const messageType = action.payload.type;
            // Create notification object
            const newMessage: UserUnreadNotification = {
                type: messageType,
                ...(messageType === 'USER_MESSAGE' && { user_message: action.payload.user_message }),
                ...(messageType === 'GROUP_POST' && { group_post: action.payload.group_post })
            };

            // Add to state if conditions are met
            // [Your existing logic here]
            state.value = [...state.value, newMessage];
        },

        removeUnreadMessage: (state, action: PayloadAction<UserUnreadNotification[]>) => {
            // Extract IDs from message types including the source_id for FRIEND_REQUEST
            const idsToRemove = action.payload
                .flatMap((msg) => [
                    msg.user_message?.id,
                    msg.group_post?.id,
                    msg.type === 'FRIEND_REQUEST' ? msg.source_id : undefined // Handling for FRIEND_REQUEST
                ])
                .filter((id) => id !== undefined);

            // Filter out messages with these IDs including checking source_id for FRIEND_REQUEST
            state.value = state.value.filter((message) => {
                return (
                    !idsToRemove.includes(message.user_message?.id) &&
                    !idsToRemove.includes(message.group_post?.id) &&
                    !(message.type === 'FRIEND_REQUEST' && idsToRemove.includes(message.source_id))
                );
            });
        },

        setCurrentConversation: (state, action: PayloadAction<string>) => {
            console.log('Setting current messages conversation user id to: ' + action.payload);
            state.currentUserConversationId = action.payload;
        }
    }
});

export const { setUnreadMessages, removeUnreadMessage, setCurrentConversation, addUnreadMessage } =
    messageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMessages = (state: RootState) => state.messages;

export default messageSlice.reducer;
