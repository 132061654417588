import React from 'react';

import { Button, Stack, AppBar, Toolbar, IconButton, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import LogoIcon from 'src/assets/svg/logo.svg';

const NavBar = () => {
    const buttonLinks = {
        Dashboard: '/',
        'Learning Matters': '/',
        'Job Matters': '/',
        'Health Matters': '/',
        'Money Matters': '/',
        'Social Matters': '/',
        'Travel Matters': '/'
    };

    const numNotifications = 1; // TODO get user's notification count

    return (
        <>
            <AppBar
                position="static"
                sx={{
                    width: '100%',
                    bgcolor: 'transparent',
                    padding: '36px',
                    flexDirection: 'row'
                }}
                elevation={0}
            >
                <Toolbar
                    sx={{
                        width: '100%',
                        margin: '8px 16px',
                        gap: '24px',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <IconButton size="large" edge="start" sx={{ mr: 2 }}>
                        <img src={LogoIcon} alt="Logo" />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, bgcolor: 'rgb(46,46,53)', borderRadius: '50px' }}>
                        <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                            {Object.entries(buttonLinks).map(([text, link]) => (
                                <Button
                                    key={text}
                                    component={Link}
                                    to={link}
                                    color="inherit"
                                    sx={{
                                        '&:hover': {
                                            bgcolor: 'white',
                                            '.MuiTypography-root': {
                                                color: 'primary.main'
                                            }
                                        },
                                        borderRadius: '50px',
                                        padding: '12px 20px',
                                        width: '100%',
                                        minWidth: 'auto'
                                    }}
                                >
                                    <Typography sx={{ whiteSpace: 'nowrap' }}>{text}</Typography>
                                </Button>
                            ))}
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1}>
                            <IconButton
                                color="inherit"
                                aria-label="search"
                                sx={{ bgcolor: 'rgb(46,46,53)', borderRadius: '50px' }}
                            >
                                <SearchIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="notifications"
                                sx={{ bgcolor: 'rgb(46,46,53)', borderRadius: '50px' }}
                            >
                                <Badge badgeContent={numNotifications} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Stack>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavBar;
