import { HelmetProvider } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline } from '@mui/material';
import ThemeProvider from 'src/theme/ThemeProvider';

import 'nprogress/nprogress.css';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from './serviceWorker';
import { messagesUnreadGet } from './graphql/GMAPI';
import { useAppDispatch } from '.';
import { setUnreadMessages } from './redux/features/user/messageSlice';

export function App() {
    const content = useRoutes(router);
    const dispatch = useAppDispatch();

    //Load unread messages..
    useEffect(() => {
        const retrieveMessages = async () => {
            const unreadMessages = await messagesUnreadGet();

            dispatch(setUnreadMessages(unreadMessages.data));
        };

        retrieveMessages();
    }, []);

    return (
        <HelmetProvider>
            <ThemeProvider>
                <SidebarProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CssBaseline />
                        {content}
                    </LocalizationProvider>
                </SidebarProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
}

serviceWorker.unregister();
