import React from 'react';
import { List, ListItemButton, ListItemText, CircularProgress, Box } from '@mui/material';
import { Prompt } from '../../../../graphql/types/Prompt';

interface MasterComponentProps {
    prompts: Prompt[];
    loading: boolean;
    onSelectPrompt: (prompt: Prompt) => void;
    selectedPromptId: string | null;
}

const MasterComponent: React.FC<MasterComponentProps> = ({ prompts, loading, onSelectPrompt, selectedPromptId }) => {
    return (
        <Box>
            {loading ? (
                <CircularProgress />
            ) : (
                <List>
                    {prompts.map((prompt) => (
                        <ListItemButton
                            key={prompt.id}
                            selected={prompt.id === selectedPromptId}
                            onClick={() => onSelectPrompt(prompt)}
                        >
                            <ListItemText primary={prompt.name} secondary={prompt.description} />
                        </ListItemButton>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default MasterComponent;
