import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Key from 'src/assets/svg/icons/Key';

import { ScoreBox, PercentageBar, CardHeader } from '../components/Components';

export default function Wealth(cardColor: string) {
    return (
        <>
            <Card style={{ backgroundColor: 'rgba(255,255,255,0.1)', padding: '16px' }} elevation={0}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '16px' }}>
                    <CardHeader>
                        <Typography variant="h1" color={cardColor} fontWeight="400">
                            Wealth
                        </Typography>
                        <Key />
                    </CardHeader>
                    <PercentageBar label="Wealth plan completion" value={85} color={cardColor} />
                    <Grid container spacing={2} width="auto">
                        <Grid item xs={6}>
                            <ScoreBox label="Income Score" score={91} />
                        </Grid>
                        <Grid item xs={6}>
                            <ScoreBox label="Financial Score" score={83} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
