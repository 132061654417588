import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import AccountSettings from './content/dashboards/screens/AccountSettings';
import CompanySettings from './content/dashboards/screens/CompanySettings';
import Debug from './content/dashboards/screens/Debug';
import Profile from './content/dashboards/screens/Profile';
import GroupList from './content/dashboards/screens/Groups/GroupList';
import GroupInstanceDisplay from './content/dashboards/screens/Groups/GroupInstanceDisplay';
import GroupCreate from './content/dashboards/screens/Groups/GroupCreate';
import EventCreate from './content/dashboards/screens/Groups/GroupInstanceDisplay/EventCreate';
import GroupEventDisplay from './content/dashboards/screens/Groups/GroupEventDisplay';
import OnboardingStartComponent from './content/dashboards/screens/Onboarding/OnboardingStart';
import OnboardingCardsComponent from './content/dashboards/screens/Onboarding/OnboardingCards';
import OnboardingMainComponent from './content/dashboards/screens/Onboarding/OnboardingMain';
import VADProvider from './components/onboarding/VADProvider';
import OnboardingProfile from './content/dashboards/screens/OnboardingProfile';
import { Learning } from './components/Learning';
import Dashboard from './content/dashboards/screens/Dashboard';
import PromptListPage from './content/dashboards/screens/Prompts';

/* eslint-disable react/display-name */
const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

const Home = Loader(lazy(() => import('src/content/dashboards/home')));
const Messenger = Loader(lazy(() => import('./content/dashboards/screens/Messenger')));
const GreyTasker = Loader(lazy(() => import('./content/dashboards/screens/GreyTasker')));
const JobList = Loader(lazy(() => import('./content/dashboards/screens/GreyTasker/sections/JobList')));
const PostedJobs = Loader(lazy(() => import('./content/dashboards/screens/GreyTasker/sections/PostedJobs')));
const QuizList = Loader(lazy(() => import('./content/dashboards/screens/Quiz')));
const Statistics = Loader(lazy(() => import('./content/dashboards/screens/Statistics')));
const CreateProfile = Loader(lazy(() => import('./content/dashboards/screens/GreyTasker/sections/CreateProfile')));
const QuizInstanceDisplay = Loader(lazy(() => import('./content/dashboards/screens/QuizInstanceDisplay')));
const CreateJobPage = Loader(lazy(() => import('./content/dashboards/screens/GreyTasker/sections/CreateJob')));
const JobDetail = Loader(lazy(() => import('./content/dashboards/screens/GreyTasker/sections/JobDetail')));
const FriendsList = Loader(lazy(() => import('./content/dashboards/screens/Friends')));
const Wealth = Loader(lazy(() => import('./content/dashboards/screens/Wealth')));

const routes: RouteObject[] = [
    {
        path: '',
        element: <BaseLayout />,
        children: [
            {
                path: '/',
                element: <SidebarLayout />,
                children: [
                    {
                        path: '',
                        element: <Navigate to="home" replace />
                    },
                    {
                        path: 'home',
                        element: <Home />
                    }
                ]
            },
            {
                path: 'overview',
                element: <Navigate to="/" replace />
            }
            // {
            //   path: 'status',
            //   children: [
            //     {
            //       path: '',
            //       element: <Navigate to="404" replace />
            //     },
            //     {
            //       path: '404',
            //       element: <Status404 />
            //     },
            //     {
            //       path: '500',
            //       element: <Status500 />
            //     },
            //     {
            //       path: 'maintenance',
            //       element: <StatusMaintenance />
            //     },
            //     {
            //       path: 'coming-soon',
            //       element: <StatusComingSoon />
            //     }
            //   ]
            // },
            // {
            //   path: '*',
            //   element: <Status404 />
            // }
        ]
    },
    {
        path: 'dashboards',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="home" replace />
            },
            {
                path: 'messenger',
                element: <Messenger />,
                children: [
                    {
                        path: 'conversation/:navigateUserId',
                        element: <Messenger />
                    }
                ]
            },
            {
                path: 'friends',
                element: <FriendsList />
            }
        ]
    },
    {
        path: 'dashboard-new',
        element: <BaseLayout />,
        children: [
            {
                path: '',
                element: <Dashboard />
            }
        ]
    },
    {
        path: 'wealth',
        element: <SidebarLayout />,
        children: [
            {
                path: 'explorer',
                element: <Wealth />
            }
        ]
    },
    {
        path: 'greytasker',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                children: [
                    {
                        path: '',
                        element: <Navigate to="home" replace />
                    },
                    {
                        path: 'home',
                        element: <GreyTasker />
                    },
                    {
                        path: 'job/:id',
                        element: <JobDetail displayJobList={null} job={null} nextStep={null} editable={false} />
                    },
                    {
                        path: 'job-edit/:id',
                        element: <JobDetail displayJobList={null} job={null} nextStep={null} editable={true} />
                    },
                    {
                        path: 'job-list',
                        element: (
                            <JobList
                                nextStep={null}
                                showJobDetail={null}
                                showCreateJob={null}
                                applicationsMode={false}
                                mySelectedJobsMode={false}
                            />
                        )
                    },
                    {
                        path: 'applications',
                        element: (
                            <JobList
                                nextStep={null}
                                showJobDetail={null}
                                showCreateJob={null}
                                applicationsMode={true}
                                mySelectedJobsMode={false}
                            />
                        )
                    },
                    {
                        path: 'selected',
                        element: (
                            <JobList
                                nextStep={null}
                                showJobDetail={null}
                                showCreateJob={null}
                                applicationsMode={false}
                                mySelectedJobsMode={true}
                            />
                        )
                    },
                    {
                        path: 'create',
                        element: <CreateJobPage nextStep={null} />
                    },
                    {
                        path: 'edit-profile',
                        element: <CreateProfile nextStep={null} />
                    },
                    {
                        path: 'postings',
                        element: (
                            <PostedJobs
                                nextStep={null}
                                showJobDetail={null}
                                showCreateJob={null}
                                applicationsMode={false}
                                mySelectedJobsMode={false}
                            />
                        )
                    }
                ]
            }
        ]
    },
    {
        path: 'debug',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                children: [
                    {
                        path: '',
                        element: <Navigate to="home" replace />
                    },
                    {
                        path: 'home',
                        element: <Debug />
                    }
                ]
            }
        ]
    },
    {
        path: 'management',
        element: <SidebarLayout />,
        children: [
            {
                path: 'profile',
                children: [
                    {
                        path: '',
                        element: <Navigate to="settings" replace />
                    },
                    {
                        path: 'settings',
                        element: <AccountSettings />
                    }
                ]
            },
            {
                path: 'company',
                children: [
                    {
                        path: '',
                        element: <Navigate to="settings" replace />
                    },
                    {
                        path: 'settings',
                        element: <CompanySettings />
                    }
                ]
            }
        ]
    },
    {
        path: 'quiz',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="home" replace />
            },
            {
                path: 'home',
                element: <QuizList />
            },
            {
                path: 'instance/:id',
                element: <QuizInstanceDisplay />
            }
        ]
    },
    {
        path: 'group',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="home" replace />
            },
            {
                path: 'home',
                element: <GroupList />
            },
            {
                path: 'create',
                element: <GroupCreate />
            },
            {
                path: 'instance/:id/:postId',
                element: <GroupInstanceDisplay />
            },
            {
                path: 'instance/:id',
                element: <GroupInstanceDisplay />
            },
            {
                path: 'instance/:id/event-create',
                element: <EventCreate />
            },
            {
                path: 'event/:id',
                element: <GroupEventDisplay />
            }
        ]
    },

    {
        path: 'statistics',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="home" replace />
            },
            {
                path: 'home',
                element: <Statistics />
            }
        ]
    },
    {
        path: 'profile',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="user" replace />
            },
            {
                path: 'user/:id',
                element: <Profile />
            }
        ]
    },
    {
        path: 'learning',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Learning />
            }
        ]
    },
    {
        path: 'prompts',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <PromptListPage />
            }
        ]
    },
    {
        path: 'onboarding',
        element: <BaseLayout />,
        children: [
            {
                path: '1',
                children: [
                    {
                        path: '',
                        element: <OnboardingStartComponent />
                    }
                ]
            },
            {
                path: '2',
                children: [
                    {
                        path: '',
                        element: <OnboardingCardsComponent />
                    }
                ]
            },
            {
                path: '3',
                children: [
                    {
                        path: '',
                        element: (
                            <VADProvider>
                                <OnboardingMainComponent />
                            </VADProvider>
                        )
                    }
                ]
            },
            {
                path: 'profile',
                children: [
                    {
                        path: '',
                        element: <OnboardingProfile />
                    }
                ]
            }
        ]
    }
];

export default routes;
