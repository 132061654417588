import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36" fill="none">
            <path
                d="M3.30414 4.5H12.7627C14.4351 4.5 16.039 5.13214 17.2215 6.25736C18.4041 7.38258 19.0684 8.9087 19.0684 10.5V31.5C19.0684 30.3065 18.5701 29.1619 17.6832 28.318C16.7963 27.4741 15.5934 27 14.3391 27H3.30414V4.5Z"
                stroke="#4BFFDF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.8327 4.5H25.3741C23.7017 4.5 22.0979 5.13214 20.9153 6.25736C19.7328 7.38258 19.0684 8.9087 19.0684 10.5V31.5C19.0684 30.3065 19.5667 29.1619 20.4536 28.318C21.3405 27.4741 22.5434 27 23.7977 27H34.8327V4.5Z"
                stroke="#4BFFDF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgComponent;
