import React, { useEffect, useState } from 'react';
import { Box, styled, Drawer } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import MasterComponent from '././MasterComponent';
import DetailComponent from './DetailComponent';
import { promptGet, promptListGet } from '../../../../graphql/GMAPI';
import { Prompt } from '../../../../graphql/types/Prompt';

const RootWrapper = styled(Box)(
    ({ theme }) => `
        height: calc(100vh - ${theme.header.height});
        display: flex;
        @media (max-width: 767px) {
            @supports (-webkit-touch-callout: none) {
                height: 80vh;
            }
        }
`
);

const DrawerWrapperMobile = styled(Drawer)(
    () => `
    width: 340px;
    flex-shrink: 0;

    & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
    }
`
);

function PromptListPage() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [detailLoading, setDetailLoading] = useState<boolean>(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        async function fetchPrompts() {
            setLoading(true);
            const response = await promptListGet();
            if (response && response.data) {
                setPrompts(response.data);
            }
            setLoading(false);
        }

        fetchPrompts();
    }, []);

    const handlePromptSelected = async (prompt: Prompt) => {
        setDetailLoading(true);
        const response = await promptGet(prompt.id);
        console.log('Prompt: ', response);
        if (response && response.data) {
            setSelectedPrompt(response.data);
        }
        setDetailLoading(false);
    };

    return (
        <RootWrapper className="Mui-FixedWrapper">
            <DrawerWrapperMobile
                sx={{
                    display: { lg: 'none', xs: 'inline-block' }
                }}
                variant="temporary"
                anchor={'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
            >
                <Scrollbar>
                    <MasterComponent
                        prompts={prompts}
                        loading={loading}
                        onSelectPrompt={handlePromptSelected}
                        selectedPromptId={selectedPrompt?.id}
                    />
                </Scrollbar>
            </DrawerWrapperMobile>
            <Box
                sx={{
                    display: { xs: 'none', lg: 'inline-block' },
                    width: 300,
                    borderRight: '1px solid #ccc'
                }}
            >
                <Scrollbar>
                    <MasterComponent
                        prompts={prompts}
                        loading={loading}
                        onSelectPrompt={handlePromptSelected}
                        selectedPromptId={selectedPrompt?.id}
                    />
                </Scrollbar>
            </Box>
            <Box flex={1}>
                <DetailComponent prompt={selectedPrompt} loading={detailLoading} onDrawerToggle={handleDrawerToggle} />
            </Box>
        </RootWrapper>
    );
}

export default PromptListPage;
