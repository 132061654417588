import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { OnboardingConversationElement } from '../../../graphql/types/Onboarding';

// Define a type for the slice state
interface ConversationState {
    conversations: OnboardingConversationElement[];
}

// Define the initial state using that type
const initialState: ConversationState = {
    conversations: []
};

export const conversationSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addConversationItem: (state, action: PayloadAction<OnboardingConversationElement>) => {
            state.conversations.push(action.payload);
        }
    }
});

export const { addConversationItem } = conversationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectConversations = (state: RootState) => state.conversations.conversations;

export default conversationSlice.reducer;
