import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import React, { useEffect, useState } from 'react';
import { Group } from '../../../../../graphql/types/Group';
import { groupListGet } from '../../../../../graphql/GMAPI';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Button,
    CircularProgress,
    Box,
    Stack,
    Tooltip,
    Avatar,
    Grid
} from '@mui/material';
import ReactQuill from 'react-quill';
import { PostCard } from '../../../../../components/GroupPost/PostCard';
import { useAppSelector } from '../../../../..';
import { selectUser } from '../../../../../redux/features/user/userSlice';

function GroupList() {
    const navigate = useNavigate();
    const [groupList, setGroupList] = useState<Group[]>([]);
    const myUser = useAppSelector(selectUser).value;
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        async function getGroupList() {
            setLoading(true);
            try {
                const response = await groupListGet(page);
                setGroupList(response.data);
            } catch (error) {
                console.error('Failed to fetch groups', error);
            } finally {
                setLoading(false);
            }
        }

        getGroupList();
    }, [page]);

    const handleNavigate = (groupId: string) => {
        navigate(`/group/instance/${groupId}`);
    };

    const handleNext = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevious = () => {
        setPage((prevPage) => (prevPage > 0 ? prevPage - 1 : 0));
    };

    // New function to handle navigation to create group page
    const handleCreateNewGroup = () => {
        navigate('/group/create');
    };

    return (
        <>
            <Helmet>
                <title>Groups</title>
            </Helmet>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: 4 }}>
                <Button variant="contained" onClick={handleCreateNewGroup}>
                    Create new Club
                </Button>
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography variant="h4" component="h1" gutterBottom sx={{ margin: 4 }}>
                        Clubs List
                    </Typography>
                    {groupList.map((group) => (
                        <Card key={group.id} sx={{ margin: 2 }}>
                            <CardActionArea onClick={() => handleNavigate(group.id)}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {group.name}
                                    </Typography>
                                    <Stack direction="row" spacing={-2} paddingLeft={0}>
                                        {group?.members.map((member, index) => (
                                            <Tooltip title={member.member.name} key={index}>
                                                <Link
                                                    to={`/user/profile/${member.member.id}`}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <Avatar
                                                        alt={member.member.name}
                                                        src={member.member.avatar_url}
                                                        sx={{ border: '2px solid white', cursor: 'pointer' }} // Added cursor style for better UX
                                                        style={{
                                                            // marginLeft: '-15px',
                                                            zIndex: group.members.length - index, // Ensures the leftmost avatars are on top
                                                            width: 40, // Adjust the size as needed
                                                            height: 40 // Adjust the size as needed
                                                        }}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        ))}
                                        {group?.memberCount > 10 && <Avatar>...</Avatar>}
                                    </Stack>
                                    <Typography fontSize="0.7rem">
                                        {group.memberCount}{' '}
                                        {group.memberCount == 0 || group.memberCount > 1 ? 'members' : 'member'}
                                    </Typography>
                                    <div className={'quill-editor-container toolbar-hidden'}>
                                        <ReactQuill value={group.description} readOnly={true} theme={'snow'} />
                                    </div>

                                    <Grid container display="flex">
                                        <Grid item xs={12} sm={6}>
                                            {group.lastPost && (
                                                <PostCard
                                                    myUser={myUser}
                                                    inPost={group.lastPost}
                                                    groupId={group.id}
                                                    isUserMember={false}
                                                    navigatePostId={null}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 4 }}>
                        <Button variant="contained" onClick={handlePrevious} disabled={page === 0}>
                            Previous
                        </Button>
                        <Button variant="contained" onClick={handleNext}>
                            Next
                        </Button>
                    </Box>
                </>
            )}
            <Footer />
        </>
    );
}

export default GroupList;
