import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Button, CircularProgress, IconButton, Alert } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Prompt } from '../../../../graphql/types/Prompt';
import { promptUpdate } from '../../../../graphql/GMAPI';

interface DetailComponentProps {
    prompt: Prompt | null;
    loading: boolean;
    onDrawerToggle: () => void;
}

const DetailComponent: React.FC<DetailComponentProps> = ({ prompt, loading, onDrawerToggle }) => {
    const [content, setContent] = useState<string>(prompt?.content || '');
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);
    const [updateSuccess, setUpdateSuccess] = useState<boolean | null>(null);

    // Use useEffect to update the content state when the prompt prop changes
    useEffect(() => {
        if (prompt) {
            setContent(prompt.content);
        }
    }, [prompt]);

    const handleUpdate = async () => {
        if (prompt) {
            setUpdateLoading(true);
            const response = await promptUpdate(prompt.id, content);
            setUpdateLoading(false);

            if (response && response.data) {
                setUpdateSuccess(true);
                setTimeout(() => setUpdateSuccess(null), 3000); // Clear success message after 3 seconds
            } else {
                setUpdateSuccess(false);
            }
        }
    };

    return (
        <Box p={2}>
            <Box
                sx={{
                    display: { xs: 'flex', lg: 'none' },
                    alignItems: 'center',
                    mb: 2
                }}
            >
                <IconButton color="primary" onClick={onDrawerToggle}>
                    <MenuTwoToneIcon />
                </IconButton>
                <Typography variant="h4">{prompt ? prompt.name : 'Select a prompt'}</Typography>
            </Box>

            {loading ? (
                <CircularProgress />
            ) : prompt ? (
                <>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Description:
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        {prompt.description}
                    </Typography>
                    <TextField
                        label="Content"
                        fullWidth
                        multiline
                        rows={20}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={handleUpdate} disabled={updateLoading}>
                        {updateLoading ? <CircularProgress size={24} /> : 'Update'}
                    </Button>
                    {updateSuccess === true && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Prompt updated successfully!
                        </Alert>
                    )}
                    {updateSuccess === false && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            Failed to update the prompt. Please try again.
                        </Alert>
                    )}
                </>
            ) : (
                <Typography variant="body1">Select a prompt from the list to view details.</Typography>
            )}
        </Box>
    );
};

export default DetailComponent;
