import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Compass from 'src/assets/svg/icons/Compass';

import { PercentageBar, CardHeader, List } from '../components/Components';
import Button from 'src/components/Button';

export default function Work(cardColor: string) {
    return (
        <>
            <Card style={{ backgroundColor: 'rgba(255,255,255,0.1)', padding: '16px' }} elevation={0}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <CardHeader>
                        <Typography variant="h1" color={cardColor} fontWeight="400">
                            Explore
                        </Typography>
                        <Compass />
                    </CardHeader>
                    <PercentageBar label="Explore plan completion" value={80} color={cardColor} />
                    <List
                        title="My Bucket List"
                        listItems={['1. Japan Trip', '2. Skydive', '3. Learn to play guitar']}
                    />
                    <Button to="/" color="#a6bcff" arrow="right" size="small" text="View All" />
                </CardContent>
            </Card>
        </>
    );
}
