export const listUsers = `
  query listUsers {
    listUsers {
      id
      username
      name
    }
  }`;

export const q_createJobProfile = `
  query createJobProfile {
    createJobProfile {
      body
    }
  }`;

export const q_jobProfileGet = `
  query jobProfileGet {
    jobProfileGet {
      id
      title
      body
    }
  }
  `;

export const q_serviceStateGet = `
  query serviceStateGet ($serviceName: String) {
    serviceStateGet (serviceName: $serviceName) {
      data {
        id
        user_id
        service
        state
      }

      errors {
        code
        message
      }
    }
  }
`;

export const q_jobDetailGet = `
  query jobDetailGet ($jobId: String, $applications: Boolean) {
    jobDetailGet (jobId: $jobId, applications: $applications) {
      id
      title
      description
      date_posted
      date_to_perform
      location
      body
      
      posted_by {
        id
        name
      }

      amount
      currency {
        symbol
        standard_divisor
      }

      applications {
        id
        date_applied
        selected
        user {
          id
          name
          tasker_profiles {
            id
            body
          }
        }
      }
    }
  }
`;

export const q_taskerJobApplicationsGet = `
  query taskerMyJobApplicationsGet($page: Int) {
    taskerMyJobApplicationsGet(page: $page) {
      id
      date_applied
      selected
      
      job {
        id
        title
        description
        date_posted
        date_to_perform
        location
        body

        posted_by {
          id
          name
        }
      }
    }
  }
`;

export const q_jobListGet = `
  query jobListGet($page: Int, $mine: Boolean, $mySelectedJobs: Boolean) {
    jobListGet(page: $page, mine: $mine, mySelectedJobs: $mySelectedJobs) {
      data {
        id
        title
        description
        body
        date_posted
        date_to_perform
        location
        currency {
          id
          standard_divisor
          acronym
          symbol
        }
        amount
        
        posted_by {
          id
          name
        }

        applications {
          id
        }
      }

      errors {
        code
        message
      }
    }
  }
`;

export const onboardingConversationHistory = `
  query onboardingConversationHistory($type: ConversationType) {
    onboardingConversationHistory(type: $type) {
      data {
        id
        initiator
        contents
        reasoning {
          explanation
          usedMemories
        }
      }

      errors {
        code
        message
      }
    }
  }`;

export const onboardingMemories = `
  query memories {
    onboardingMemories {
      id
      contents
    }
  }`;

export const myUser = `
  query myUser {
    myUser {
      id
      username
      email
      name
      dob
      target_retirement_date
      status
      selected_language
      avatar_url
      type
    }
  }`;

export const q_recentConversationsGet = `
    query recentConversationsGet {
      recentConversationsGet {
        from_user {
            id
            name
            avatar_url
            
        }
        mostRecentMessage {
            id
            body
            date_sent
            seen
            from_user_id
        }
      }
    }
  `;

export const q_recentMessagesGet = `
    query recentMessagesGet($from_user_id: String, $page: Int) {
      recentMessagesGet(from_user_id: $from_user_id, page: $page) {
        id
        from_user_id
        to_user_id
        body
        date_sent
        seen
      }
    }
  `;

export const q_messagesUnreadGet = `
  query messagesUnreadGet {
    messagesUnreadGet {

      data {
        type
        subType

        source_id
        source_name
        source_avatar_url

        user_message {
          id
          from_user_id
          to_user_id
          body
          date_sent
          seen
          from_user {
            id
            name
            avatar_url
          }
        }

        group_post {
          id
          parent_post_id
          group_id
          poster {
            id
            name
            avatar_url
          }
        }
      }

      errors {
        code
        message
      }
    }
  }
`;

export const q_userFindByNameFragment = `
    query userFindByNameFragment($nameFragment: String) {
      userFindByNameFragment(nameFragment: $nameFragment) {
        errors {
          code
          message
        }

        data {
          id
          name
          avatar_url
        }
      }
    }
  `;

export const q_statisticsGet = `
    query statisticsGet($type: String) {
      statisticsGet(type: $type) {
        errors {
          code
          message
        }

        data {
          type
          dayCount {
            dy
            cnt
          }

          dobDecade {
            decade
            cnt
          }
        }
      }
    }
  `;

export const q_quizListGet = `
    query quizListGet {
      quizListGet {
        errors {
          code
          message
        }

        data {
          id
          name
          iconURL
        }
      }
    }
  `;

export const q_quizInstanceListGet = `
  query quizInstanceListGet {
    quizInstanceListGet {
      errors {
        code
        message
      }

      data {
        id
        result
        date_created
        quiz_def {
          id
          name
          iconURL
        }
      }
    }
  }
`;

export const q_quizInstanceGet = `
  query quizInstanceGet($quiz_instance_id: String) {
    quizInstanceGet(quiz_instance_id: $quiz_instance_id) {
      errors {
        code
        message
      }

      data {
        id
        date_created
        answers
        result
        status
        quiz_def {
          id
          name
          iconURL
          questions {
            id
            question_content
            type
          }
        }
      }
    }
  }
`;

export const q_feedGet = `
  query feedGet($page: Int, $userId: String) {
    feedGet(page: $page, userId: $userId) {
      errors {
        code
        message
      }

      data {
        id
        date_created
        content
        user_id
        event_type
        owner {
          id
          name
          avatar_url
        }

        group_post {
          id
          group_id
          title
          body
          date_created
          image_url
          poster {
            id
            name
            avatar_url
          }
          reactionCounts {
            LIKE
            LOVE
            LAUGH
            SURPRISE
            ANGRY
          }

          group {
            name
          }

          child_posts {
            id
            title
            body
            date_created
            reactionCounts {
              LIKE
              LOVE
              LAUGH
              SURPRISE
              ANGRY
            }
            poster {
              id
              name
              avatar_url
            }
          }
        }
      }
    }
  }
`;

export const q_groupGet = `
  query groupGet($id: String!) {
    groupGet(id: $id) {
      errors {
        code
        message
      }

      data {
        id
        type
        name
        memberCount
        posts {
          id
          title
          body
          date_created
          image_url
          poster {
            id
            name
            avatar_url
          }
          reactionCounts {
            LIKE
            LOVE
            LAUGH
            SURPRISE
            ANGRY
          }
          child_posts {
            id
            title
            body
            date_created
            poster {
              id
              name
              avatar_url
            }
            reactionCounts {
              LIKE
              LOVE
              LAUGH
              SURPRISE
              ANGRY
            }
          }
        }

        members {
          id
          member {
            id
            name
            avatar_url
          }
        }

        description
        isUserMember
        isUserAdmin
      }
    }
  }
`;

export const q_groupListGet = `
  query grouListGet($page: Int!) {
    groupListGet(page: $page) {
      errors {
        code
        message
      }

      data {
        id
        type
        name
        memberCount
        description
        members {
          id
          member {
            id
            name
            avatar_url
          }
        }

        lastPost {
          id
          title
          body
          date_created
          image_url
          poster {
            id
            name
            avatar_url
          }
          reactionCounts {
            LIKE
            LOVE
            LAUGH
            SURPRISE
            ANGRY
          }          
        }
      }
    }
  }
`;

export const q_groupEventGet = `
  query groupEventGet($id: String!) {
    groupEventGet(id: $id) {
      errors {
        code
        message
      }

      data {
        id
        title
        body
        start
        end
        group_id
        isUserMember

        group_post_id

        attendees {
          attendee {
            id
            name
            avatar_url
          }
        }
        event_owner {
          id
          name
        }
        group {
          id
          name
        }
      }
    }
  }
`;

export const q_groupPostGet = `
  query groupPostGet($id: String!) {
    groupPostGet(id: $id) {
      data {
        id
        title
        body
        date_created
        parent_post_id
        group_id
        isUserMember

        reactionCounts {
          LIKE
          LOVE
          LAUGH
          SURPRISE
          ANGRY
        }
        child_posts {
          id
          title
          body
          date_created
          parent_post_id
          reactionCounts {
            LIKE
            LOVE
            LAUGH
            SURPRISE
            ANGRY
          }   
          poster {
            id
            name
            avatar_url
          }     
        }
        poster {
          id
          name
          avatar_url
        }
      }

      errors {
        message
        code
      }
    }
  }
`;

export const q_userGet = `
  query userGet($userId: String) {
    userGet(userId: $userId) {
      errors {
        code
        message
      }

      data {
        id
        name
        avatar_url
        friend_status
      }
    }
  }
`;

export const q_userCurrencyBalanceGet = `
  query userCurrencyBalanceGet {
    userCurrencyBalanceGet {
      errors {
        code
        message
      }

      data {
        balance
      }
    }
  }`;

export const q_friendsListGet = `
    query friendsListGet($page: Int!) {
      friendsListGet(page: $page) {
        errors {
          code
          message
        }

        data {
          id
          name
          avatar_url
          mutualFriendsCount
        }
      }
    }
  `;

export const q_currencyListGet = `
    query currencyListGet {
      currencyListGet {
        errors {
          code
          message
        }
        
        data {
          id
          symbol
          long_form
          standard_divisor
          acronym
        }
      }
    }
`;

export const q_companyDetailsGet = `
    query companyDetailsGet {
      companyDetailsGet {
        errors {
          code
          message
        }
        
        data {
          id
          name
          mode_friends
          mode_groups
          mode_messages
          mode_jobs
          friends_unaffiliated
          jobs_unaffiliated
          messages_unaffiliated
          groups_unaffiliated
          company_shares_to {
            id
            name
          }
        }
      }
    }
`;

export const financialStatement_o = `
                id
                income {
                  id
                  amount
                  frequency
                  name
                  type
                  currency {
                    symbol
                    standard_divisor
                  }
                }

                expenses {
                  id
                  amount
                  frequency
                  name
                  type
                  currency {
                    symbol
                    standard_divisor
                  }
                  associated_liability_id
                }

                liabilities {
                  id
                  amount
                  name
                  type
                  currency {
                    symbol
                    standard_divisor
                  }
                  interest_rate
                  associated_asset_id
                }

                assets {
                  id
                  value
                  name
                  type
                  currency {
                    symbol
                    standard_divisor
                  }
                }

                simulation_months {
                  month_date
                  income {
                    amount
                  }
                  liabilities {
                    value
                  }
                  assets {
                    value
                  }
                  liquid_assets {
                    value
                  }
                  expenses {
                    amount
                  }
                }
`;

export const q_financialStatementGet = `
    query financialStatementGet {
      financialStatementGet {
        errors {
          code
          message
        }
        
        data {
          ${financialStatement_o}
        }
      }
    }
`;

export const q_financialStatementSimulationGet = `
    query financialStatementSimulationGet {
      financialStatementGet {
        errors {
          code
          message
        }
        
        data {
          monthDate
          income {
            amount
          }
          liabilities {
            value
          }
          assets {
            value
          }
          expenses {
            amount
          }
        }
      }
    }
`;

export const q_promptListGet = `
    query promptListGet {
      promptListGet {
        errors {
          code
          message
        }
        
        data {
          id
          name
          description
          content
          
        }
      }
    }
`;

export const q_promptGet = `
    query promptGet($id: String!) {
      promptGet(id: $id) {
        errors {
          code
          message
        }
        
        data {
          id
          name
          description
          content
          
        }
      }
    }
`;
