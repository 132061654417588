import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path
                d="M23.25 11.25L28.5 6M31.5 3L28.5 6L31.5 3ZM17.085 17.415C17.8595 18.1792 18.4752 19.0891 18.8966 20.0922C19.3181 21.0953 19.5369 22.1719 19.5405 23.2599C19.5442 24.348 19.3326 25.426 18.9179 26.432C18.5032 27.4379 17.8936 28.3518 17.1242 29.1212C16.3548 29.8906 15.4409 30.5002 14.435 30.9149C13.429 31.3296 12.351 31.5412 11.2629 31.5375C10.1749 31.5339 9.09831 31.3151 8.09519 30.8936C7.09206 30.4722 6.1822 29.8565 5.418 29.082C3.9152 27.526 3.08364 25.4421 3.10244 23.2789C3.12124 21.1158 3.98888 19.0466 5.5185 17.517C7.04811 15.9874 9.11732 15.1197 11.2804 15.1009C13.4436 15.0821 15.5275 15.9137 17.0835 17.4165L17.085 17.415ZM17.085 17.415L23.25 11.25L17.085 17.415ZM23.25 11.25L27.75 15.75L33 10.5L28.5 6L23.25 11.25Z"
                stroke="#C2C2C2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgComponent;
