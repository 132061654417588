import React, { useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill, { Quill, UnprivilegedEditor } from 'react-quill';
import { DeltaStatic } from 'quill';
import 'react-quill/dist/quill.snow.css';

interface QuillWithCustomLinkProps {
    value: string;
    style?: React.CSSProperties;
    onFocus?: () => void;
    onBlur?: () => void;
    onChange?: (content: string, delta: DeltaStatic, source: string, editor: UnprivilegedEditor) => void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modules?: any;
}

const QuillWithCustomLink = forwardRef<ReactQuill, QuillWithCustomLinkProps>(
    ({ value, style, onFocus, onBlur, onChange, modules, ...props }, ref) => {
        const navigate = useNavigate();

        useEffect(() => {
            const CustomLink = Quill.import('formats/link');

            class LocalLink extends CustomLink {
                static create(value) {
                    const node = super.create(value);
                    node.addEventListener('click', (e) => {
                        e.preventDefault();
                        const href: string = node.getAttribute('href');

                        if (href.startsWith('http')) {
                            window.location.href = href;
                        } else {
                            navigate(href); // Use React Router's navigate function
                        }
                    });
                    return node;
                }
            }

            Quill.register(LocalLink, true);
        }, [navigate]);

        return (
            <ReactQuill
                value={value}
                style={style}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                modules={modules}
                ref={ref}
                {...props} // Pass any additional props
            />
        );
    }
);

export default QuillWithCustomLink;
