import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Heart from 'src/assets/svg/icons/Heart';

import { ScoreBox, PercentageBar, CardHeader } from '../components/Components';

export default function Health(cardColor: string) {
    return (
        <>
            <Card style={{ backgroundColor: 'rgba(255,255,255,0.1)', padding: '16px' }} elevation={0}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <CardHeader>
                        <Typography variant="h1" color={cardColor} fontWeight="400">
                            Health
                        </Typography>
                        <Heart />
                    </CardHeader>
                    <PercentageBar label="Health plan completion" value={85} color={cardColor} />
                    <Grid container spacing={2} width="auto">
                        <Grid item xs={6}>
                            <ScoreBox label="DNA Risk Score" score={87} lowerScoreIsBetter />
                        </Grid>
                        <Grid item xs={6}>
                            <ScoreBox label="Lifestyle Score" score={48} lowerScoreIsBetter />
                        </Grid>
                        <Grid item xs={6}>
                            <ScoreBox label="Biological Age" score={72} lowerScoreIsBetter />
                        </Grid>
                        <Grid item xs={6}>
                            <ScoreBox label="Actual Age" score={54} circleColor="#a6bcff" invert />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
