import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { User } from '../../../graphql/types/User';

// Define a type for the slice state
interface UserState {
    value: User | undefined;
}

// Define the initial state using that type
const initialState: UserState = {
    value: undefined
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            console.log('set user payload: ' + JSON.stringify(action));
            state.value = action.payload;
            console.log('User language: ' + state.value.selected_language);
        },

        setMyAvatar: (state, action: PayloadAction<string>) => {
            state.value.avatar_url = action.payload;
        },

        setUserStatus: (state, action: PayloadAction<User['status']>) => {
            if (state.value) {
                if (action.payload == 'ONBOARDED') {
                    state.value = {
                        ...state.value,
                        status: 'ONBOARDED'
                    };
                    console.log('Setting status of user to: ONBOARDED: ' + state.value?.status);
                } else if (action.payload == 'SETUP_PROFILE') {
                    state.value = {
                        ...state.value,
                        status: 'SETUP_PROFILE'
                    };
                } else {
                    state.value.status = 'NEW';
                    console.log('Setting status of user to: NEW: ' + state.value?.status);
                }
            } else {
                console.log('No user currently set at userStatus set');
            }
        }
    }
});

export const { setUser, setUserStatus, setMyAvatar } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
