import React, { useEffect, useRef } from 'react';
import { Typography, Avatar, Grid, Card, CardContent, Link } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { groupMemberSeenUntilUpdate } from '../../graphql/GMAPI';
import { PostCard } from '../GroupPost/PostCard';

const FeedEventCard = ({ extended, user, event }) => {
    let cardContent;

    const cardRef = useRef(null);

    const parseMarkdownLinks = (content) => {
        const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const parts = [];
        let lastIndex = 0;

        content.replace(markdownLinkRegex, (match, text, href, index) => {
            // Add the text before the link
            parts.push(content.slice(lastIndex, index));

            // Add the link component
            parts.push(
                <Link key={index} to={href} component={RouterLink}>
                    {text}
                </Link>
            );

            lastIndex = index + match.length;
        });

        // Add any remaining text after the last link
        parts.push(content.slice(lastIndex));

        return parts;
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Triggered every time the card comes into view
                        console.log('Card is in view: ', event);
                        // You can also trigger any other action or event here

                        //Update server to this point in history..
                        if (event.group_post) {
                            console.log('Updating group post member position: ', event.group_post);
                            groupMemberSeenUntilUpdate(event.group_post.id);
                        }

                        // Optional: Unobserve the element after it comes into view
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null, // relative to document viewport
                rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
                threshold: 0.1 // visible amount of item shown in relation to root
            }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [event.event_type]);

    switch (event.event_type) {
        case 'FEED':
            cardContent = (
                <Grid container display="flex" alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={8}>
                        <Card sx={{ my: 2, p: 2 }}>
                            <CardContent>
                                <Grid container alignItems="center">
                                    <Grid item paddingRight={5}>
                                        <RouterLink
                                            to={`/profile/user/${event.user_id}`}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Avatar
                                                src={event.owner.avatar_url}
                                                variant="rounded"
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                                    }
                                                }}
                                            />
                                        </RouterLink>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {/* <Link to={`/profile/user/${event.user_id}`} style={{ textDecoration: 'none' }}> */}
                                        <Typography variant="body1">{parseMarkdownLinks(event.content)}</Typography>
                                        {/* </Link> */}
                                        <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                                            {formatDistanceToNow(new Date(event.date_created)).toLowerCase()} ago
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            );
            break;
        case 'GROUP_POST':
            cardContent = extended ? (
                <Grid container display="flex" alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={8}>
                        <div ref={cardRef}>
                            <PostCard
                                navigatePostId={null}
                                myUser={user}
                                inPost={event.group_post}
                                groupId={event.group_post.group_id}
                                isUserMember={false}
                            />
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <></>
            );
            break;
        default:
            cardContent = (
                <Card sx={{ my: 2, p: 1 }}>
                    <CardContent>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Unhandled Event: {event.event_type}
                        </Typography>
                    </CardContent>
                </Card>
            );
            break;
    }

    return <>{cardContent}</>;
};

export default FeedEventCard;
