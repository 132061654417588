import React, { useEffect, useState } from 'react';
import { User } from '../../../../../graphql/types/User';
import { GroupPost } from '../../../../../graphql/types/Group';
import { groupPostGet } from '../../../../../graphql/GMAPI';
import { PostCard } from '../../../../../components/GroupPost/PostCard';
import { CircularProgress } from '@mui/material';

type DiscussionSectionProps = {
    groupPostId: string;
    myUser: User; // assuming User type is imported or defined elsewhere
};

const EventDiscussion: React.FC<DiscussionSectionProps> = ({ groupPostId, myUser }) => {
    const [groupPost, setGroupPost] = useState<GroupPost | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchGroupPost = async () => {
            setIsLoading(true);
            try {
                const response = await groupPostGet(groupPostId);
                if (response.errors) {
                    setError('Error fetching post');
                } else {
                    setGroupPost(response.data);
                }
            } catch (e) {
                setError('Error fetching post');
            }
            setIsLoading(false);
        };

        fetchGroupPost();
    }, [groupPostId]);

    if (isLoading) {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!groupPost) {
        return <div>No post found.</div>;
    }

    return (
        <PostCard
            myUser={myUser}
            inPost={groupPost}
            groupId={groupPost?.group_id}
            isUserMember={groupPost?.isUserMember}
            navigatePostId={(id) => {
                console.log('STUB: Navigate to post id in event discussion for: ' + id);
            }}
        />
    );
};

export default EventDiscussion;
