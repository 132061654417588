import React, { useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import config from '../config/auth.json';

export function GMAmplifyInit() {
    const [isAmplifyConfigured, setIsAmplifyConfigured] = useState(false);

    useEffect(() => {
        if (!isAmplifyConfigured) {
            Amplify.configure({
                Auth: {
                    region: config.region,
                    userPoolId: config.userPoolId,
                    userPoolWebClientId: config.userPoolWebClientId,
                    oauth: {
                        domain: 'greymatters-dev.auth.us-east-1.amazoncognito.com',
                        scope: ['email', 'profile', 'openid'],
                        redirectSignIn: config.redirectSignIn,
                        redirectSignOut: config.redirectSignIn,
                        responseType: 'code'
                    }
                },
                aws_appsync_graphqlEndpoint: config.graphQLEndpoint,
                aws_appsync_region: config.region,
                aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS' // Use User Pools instead of 'AWS_IAM'
            });

            setIsAmplifyConfigured(true);
        }
    }, [isAmplifyConfigured]);

    useEffect(() => {
        const refreshInterval = 55 * 60 * 1000; // 55 minutes in milliseconds

        const checkSessionAndRefresh = async () => {
            try {
                const session = await Auth.currentSession();

                const now = Math.floor(new Date().getTime() / 1000);
                const expirationTime = session.getIdToken().getExpiration();

                if (expirationTime - now < 5 * 60) {
                    // 5 minutes
                    await Auth.currentAuthenticatedUser({ bypassCache: true });
                }
            } catch (error) {
                console.error('Error refreshing token', error);
            }
        };

        const interval = setInterval(checkSessionAndRefresh, refreshInterval);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    return <></>;
}
