import { financialStatement_o } from './queries';

export const updateUser = `
  mutation UpdateUser($name: String, $email: String, $dob: AWSDateTime, $target_retirement_date: AWSDateTime, $language: String) {
    updateUser(name: $name, email: $email, dob: $dob, target_retirement_date: $target_retirement_date, language: $language) {
      id
      name
      email
      username
      dob
      target_retirement_date
      status
      selected_language
    }
  }
`;

export const nextConversationElement = `
  mutation OnboardingConversation($inputFromUser: String) {
    onboardingConversation(inputFromUser: $inputFromUser) {
      id
      initiator
      contents
      userStatus
      percent
      complete
    }
  }
`;

export const m_onboardingSpeechTranscribe = `
  mutation onboardingSpeechTranscribe($base64Input: String) {
    onboardingSpeechTranscribe(base64Input: $base64Input) {
      data {
        id
        initiator
        contents
        userInput
        voice
        userStatus
        percent
        complete
      }
    }
  }
`;

export const m_ellaConversationNext = `
  mutation ellaConversationNext($inputFromUser: String) {
    ellaConversationNext(inputFromUser: $inputFromUser) {
      id
      initiator
      contents

      reasoning {
        explanation
        usedMemories
      }
    }
  }
`;

export const m_ellaFinancialDiscussionNext = `
  mutation ellaFinancialDiscussionNext($inputFromUser: String) {
    ellaFinancialDiscussionNext(inputFromUser: $inputFromUser) {
      errors {
        message
        code
      }
      
      data {
        conversationElement {
          id
          initiator
          contents

          reasoning {
            explanation
            usedMemories
          }
        }

        financialStatement {
          ${financialStatement_o}
        }
      
      }
    }
  }
`;

export const m_saveJobProfile = `
  mutation SaveJobProfile($title: String, $body: String) {
    saveJobProfile(title: $title, body: $body) {
      id
      title
      body
    }
  }
`;

export const m_taskerJobCreate = `
  mutation TaskerJobCreate($title: String, $description: String, $date_to_perform: AWSDateTime, $location: String, $amount: Int) {
    taskerJobCreate(title: $title, description: $description, date_to_perform: $date_to_perform, location: $location, amount: $amount) {
      id
      title
      description
      location
      date_to_perform
    }
  }
`;

export const m_taskerJobEdit = `
  mutation taskerProfileEdit($instructions: [InstructionInput!]!) {
    taskerProfileEdit(instructions: $instructions) {
      data {
        textResponse
        profile
      }

      errors {
        message
        code
      }
    }
  }
`;

export const m_taskerJobApply = `
  mutation TaskerJobApply($jobId: String) {
    taskerJobApply(jobId: $jobId) {
      data {
        id
        title
        description
        location
        date_to_perform
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_taskerJobApplicationSelect = `
  mutation TaskerJobApplicationSelect($taskerJobApplicationId: String) {
    taskerJobApplicationSelect(taskerJobApplicationId: $taskerJobApplicationId) {
      data {
        id
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_recordUserServiceOnboarded = `
  mutation RecordUserServiceOnboarded($serviceName: String) {
    recordUserServiceOnboarded(serviceName: $serviceName) {
      id
      service
      state
    }
  }
`;

export const resetUser = `
  mutation ResetUser {
    resetUser {
      id
      status
    }
  }
`;

export const q_messageSend = `
  mutation MessageSend($to_user_id: String, $body: String) {
    messageSend(to_user_id: $to_user_id, body: $body) {
      id
      body
      date_sent
      to_user_id
      from_user_id
      seen
      
      from_user {
        id
        name
      }
    }
  }
`;

export const m_quizInstanceCreate = `
  mutation quizInstanceCreate($quiz_def_id: String) {
    quizInstanceCreate(quiz_def_id: $quiz_def_id) {
      errors {
        message
        code
      }

      data {
        id
        quiz_def_id
        user_id
      }
    }
  }
`;

export const m_quizInstanceResponseUpdate = `
  mutation quizInstanceResponseUpdate($quiz_question_id: String, $quiz_instance_id: String, $answer: String) {
    quizInstanceResponseUpdate(quiz_question_id: $quiz_question_id, quiz_instance_id: $quiz_instance_id, answer: $answer) {
      errors {
        message
        code
      }

      data {
        id
        result
      }
    }
  }
`;

export const m_groupJoin = `
  mutation GroupJoin($groupId: String) {
    groupJoin(group_id: $groupId) {
      data {
        id
        group_id
        member_id
        date_created
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_groupMemberSeenUntilUpdate = `
  mutation GroupMemberSeenUntilUpdate($group_post_id: String) {
    groupMemberSeenUntilUpdate(group_post_id: $group_post_id) {
      data {
        id
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_groupPostReadUpdate = `
  mutation groupPostReadUpdate($group_post_id: String) {
    groupPostReadUpdate(group_post_id: $group_post_id) {
      errors {
        code
        message
      }

      data {
        id
      }
    }
  }
`;

export const m_groupPostImageCreate = `
  mutation groupPostImageCreate($group_post_id: String) {
    groupPostImageCreate(group_post_id: $group_post_id) {
      errors {
        code
        message
      }

      data
    }
  }
`;

//groupEventCreate(groupId: String, title: String, description: String, dateStart: AWSDateTime, dateEnd: AWSDateTime): GroupEventCreateResponse
export const m_groupEventCreate = `
  mutation groupEventCreate($groupId: String, $title: String, $description: String, $dateStart: AWSDateTime, $dateEnd: AWSDateTime) {
    groupEventCreate(groupId: $groupId, title: $title, description: $description, dateStart: $dateStart, dateEnd: $dateEnd) {
      errors {
        code
        message
      }

      data {
        id
        title
      }
    }
  }
`;

//groupPostReact(group_post_id: String, reaction: String): GroupPostReactResponse
export const m_groupPostReact = `
  mutation groupPostReact($group_post_id: String, $reaction: String) {
    groupPostReact(group_post_id: $group_post_id, reaction: $reaction) {
      errors {
        code
        message
      }

      data {
        id
        actionPerformed
        type
      }
    }
  }
`;

export const m_groupLeave = `
  mutation GroupLeave($groupId: String) {
    groupLeave(group_id: $groupId) {
      data {
        hasUserLeft
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_groupDetailsUpdate = `
  mutation GroupDetailsUpdate($groupId: String, $name: String, $description: String) {
    groupDetailsUpdate(groupId: $groupId, name: $name, description: $description) {
      data {
        id
        type
        name
        description
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_groupCreate = `
  mutation GroupCreate($name: String, $description: String) {
    groupCreate(name: $name, description: $description) {
      data {
        id
        name
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_groupEventAttendToggle = `
  mutation groupEventAttendToggle($groupEventId: String) {
    groupEventAttendToggle(groupEventId: $groupEventId) {
      data {
        id
        event_id
        attendee_id
        attendee {
          id
          name
        }
        action
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_avatarUploadGenerateURL = `
  mutation avatarUploadGenerateURL {
    avatarUploadGenerateURL {
      errors {
        message
        code
      }

      data {
        uploadUrl
        finalUrl
      }
    }
  }
`;

export const m_groupPost = `
  mutation GroupPost($group_id: String, $title: String, $body: String, $parent_id: String) {
    groupPost(group_id: $group_id, title: $title, body: $body, parent_id: $parent_id) {
      data {
        id
        title
        body
        date_created
        parent_post_id
        reactionCounts {
          LIKE
          LOVE
          LAUGH
          SURPRISE
          ANGRY
        }
        child_posts {
           id
        }
        poster {
          id
          name
        }
      }
      errors {
        message
        code
      }
    }
  }
`;

export const m_userFriendAdd = `
  mutation userFriendAdd($friend_id: String) {
    userFriendAdd(friend_id: $friend_id) {
      errors {
        code
        message
      }

      data {
        id
        source_user_id
        destination_user_id
        date_created
        isPending
      }
    }
  }
`;

export const m_userFriendRemove = `
  mutation userFriendRemove($friend_id: String) {
    userFriendRemove(friend_id: $friend_id) {
      errors {
        code
        message
      }

      data {
        id
        source_user_id
        destination_user_id
        date_created
        isPending
      }
    }
  }
`;

export const m_userFriendRequestAccept = `
  mutation userFriendRequestAccept($user_friend_id: String) {
    userFriendRequestAccept(user_friend_id: $user_friend_id) {
      errors {
        code
        message
      }

      data {
        id
        source_user_id
        destination_user_id
        date_created
        isPending
      }
    }
  }
`;

export const m_userFriendRequestReject = `
  mutation userFriendRequestReject($user_friend_id: String) {
    userFriendRequestReject(user_friend_id: $user_friend_id) {
      errors {
        code
        message
      }

      data {
        id
        source_user_id
        destination_user_id
        date_created
        isPending
      }
    }
  }
`;

export const m_companyDetailsUpdate = `
    mutation companyDetailsUpdate($mode_friends: CompanyShareType!,$mode_groups: CompanyShareType!,$mode_messages: CompanyShareType!,$mode_jobs: CompanyShareType!,$friends_unaffiliated: Boolean!, $groups_unaffiliated: Boolean!, $messages_unaffiliated: Boolean!, $jobs_unaffiliated: Boolean!) {
      companyDetailsUpdate(mode_friends: $mode_friends, mode_groups: $mode_groups,mode_messages: $mode_messages,mode_jobs: $mode_jobs,friends_unaffiliated: $friends_unaffiliated, groups_unaffiliated: $groups_unaffiliated, messages_unaffiliated: $messages_unaffiliated, jobs_unaffiliated: $jobs_unaffiliated) {
        errors {
          code
          message
        }
        
        data {
          id
          name
          mode_friends
          mode_groups
          mode_messages
          mode_jobs
        
          friends_unaffiliated
          groups_unaffiliated
          messages_unaffiliated
          jobs_unaffiliated

          company_shares_to {
            id
            name
          }
        }
      }
    }
`;

export const m_promptUpdate = `
    mutation promptUpdate($id: String!, $content: String!) {
      promptUpdate(id: $id, content: $content) {
        errors {
          code
          message
        }
        
        data {
          id
          name
          description
          content
          
        }
      }
    }
`;

export const m_userUpdateAndReset = `
    mutation userUpdateAndReset($archetype: String!) {
      userUpdateAndReset(archetype: $archetype) {
        errors {
          code
          message
        }
        
        data {
          id
          archetype
        }
      }
    }
`;
