import React, { useEffect, useState } from 'react';
import { UserBalance } from '../../../../../graphql/types/User';
import { userCurrencyBalanceGet } from '../../../../../graphql/GMAPI';
import { Box } from '@mui/material';
import splIcon from 'src/assets/img/spl.png';

export function CurrencyBox() {
    const [balance, setBalance] = useState<UserBalance>();

    useEffect(() => {
        const getBalance = async () => {
            const balance = await userCurrencyBalanceGet();

            setBalance(balance.data);
        };

        getBalance();
    }, []);

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center" margin={1} justifyContent="space-between">
                <div style={{ marginRight: '10px' }}>
                    <img src={splIcon} width={30} />
                </div>
                {balance ? balance.balance : 0}
            </Box>
        </>
    );
}
