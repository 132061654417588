import React, { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { useMicVAD } from 'src/components/onboarding/CustomVAD';
import { MicVAD } from '@ricky0123/vad-web';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
    setVoiceMode,
    setSpeechState,
    VoiceMode,
    selectMicOn,
    selectSpeechState
} from '../../redux/features/vad/vadReducer';

import './OnboardingComponent.css';

type VADContextType = {
    vad: VADType;
    dataArray: Uint8Array | null;
    registerCallback: (key: string, callback: Callback) => void;
    triggerToggleVAD: () => void;
} | null;

export const VADContext = createContext<VADContextType>(null);

interface VADProviderProps {
    children: ReactNode;
}

export type VADType = {
    listening: boolean;
    errored:
        | false
        | {
              message: string;
          };
    loading: boolean;
    userSpeaking: boolean;
    pause: () => void;
    start: () => void;
    toggle: () => void;
    vad: MicVAD;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (data: any) => void;
type Callbacks = { [key: string]: Callback };

const VADProvider = ({ children }: VADProviderProps) => {
    const dispatch = useAppDispatch();
    const speechState = useAppSelector(selectSpeechState);
    const micOn = useAppSelector(selectMicOn);
    const vadRef = useRef(null);
    const [dataArray, setDataArray] = useState<Uint8Array | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref for interval ID
    const [callbacks, setCallbacks] = useState<Callbacks>({});

    const registerCallback = (key, callback) => {
        setCallbacks((prev) => ({ ...prev, [key]: callback }));
    };

    // Function to trigger all registered callbacks
    const triggerCallbacks = (data: Float32Array) => {
        Object.values(callbacks).forEach((callback) => callback(data));
    };

    useEffect(() => {
        if (vadRef.current) {
            if (micOn) {
                vadRef.current.start();
                console.log('Microphone turned ON');
            } else {
                vadRef.current.pause();
                console.log('Microphone turned OFF');
            }
        }
    }, [micOn]);

    const setupAnalyser = () => {
        if (vad && vad.vad && vad.vad.audioContext) {
            const source = vad.vad.audioContext.createMediaStreamSource(vad.vad.stream);

            const analyser = vad.vad.audioContext.createAnalyser();
            analyserRef.current = analyser;

            source.connect(analyser);
            analyser.fftSize = 2048;

            // Set up dataArray
            const bufferLength = analyser.frequencyBinCount;
            setDataArray(new Uint8Array(bufferLength));

            console.log('Analyser constructed..');
        } else {
            console.error('Could not setup analyser, VAD is null');
        }
    };

    const vad = useMicVAD({
        startOnLoad: false,
        onSpeechStart: async () => {
            if (speechState == false) {
                console.log('Speech started');
                dispatch(setSpeechState(true));
                dispatch(setVoiceMode(VoiceMode.USER_SPEAKING));

                setupAnalyser();
            }
        },
        onSpeechEnd: async (audio) => {
            if (speechState == true) {
                dispatch(setSpeechState(false));
                vad.toggle();

                if (intervalRef.current) {
                    console.log('Cancelling analyser..');
                    clearInterval(intervalRef.current); // Clear interval on speech end
                    intervalRef.current = null;
                }

                triggerCallbacks(audio);
            }
        }
    });

    useEffect(() => {
        if (speechState) {
            intervalRef.current = setInterval(() => {
                if (analyserRef.current && dataArray) {
                    analyserRef.current.getByteTimeDomainData(dataArray);
                    // console.log('Analyser data: ', dataArray);
                }
            }, 2); // Interval set to 2 ms
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [speechState, dataArray]);

    const triggerToggleVAD = () => {
        if (vadRef && vadRef.current) {
            console.log('Toggling vad..');
            vadRef.current.toggle();
        } else {
            console.log('Tried to toggle an empty vad..');
        }
    };

    useEffect(() => {
        vadRef.current = vad;
    }, [vad]);

    const vadRet: VADContextType = { vad, dataArray, registerCallback, triggerToggleVAD };

    return (
        <VADContext.Provider value={vadRet}>
            <div>{children}</div>
        </VADContext.Provider>
    );
};

export default VADProvider;
