import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

export function PercentageBar({ label, value, color }: { label: string; value: number; color: string }) {
    const [displayValue, setDisplayValue] = useState(`${value}%`);

    useEffect(() => {
        setDisplayValue(`${value}%`);
    }, [value]);

    return (
        <Box style={{ backgroundColor: 'rgba(255,255,255,0.2)', borderRadius: '8px', padding: '7px 11px' }}>
            <Typography variant="h6" color="#d9d9d9">
                {label}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
                <LinearProgress
                    variant="determinate"
                    value={value}
                    sx={{
                        width: '80%',
                        margin: '10px 0 5px 0',
                        height: '3px',
                        backgroundColor: '#919296',
                        '& .MuiLinearProgress-barColorPrimary': {
                            backgroundColor: color
                        }
                    }}
                />
                <Typography variant="body2" color="#fff" sx={{ marginLeft: '10px' }}>
                    {displayValue}
                </Typography>
            </Stack>
        </Box>
    );
}

export function ScoreBox({
    label,
    score,
    lowerScoreIsBetter,
    circleColor,
    invert
}: {
    label: string;
    score: number;
    lowerScoreIsBetter?: boolean;
    circleColor?: string;
    invert?: boolean;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                borderRadius: 2,
                bgcolor: invert ? '#fff' : 'rgba(255,255,255,0.2)',
                aspectRatio: '1 / 1',
                padding: '15px'
            }}
        >
            <Typography
                variant="h3"
                textAlign="center"
                color={invert ? 'black' : 'white'}
                lineHeight="100%"
                fontSize="20px"
                fontWeight="400"
            >
                {label}
            </Typography>
            <Box
                sx={{
                    borderRadius: '50%',
                    backgroundColor:
                        circleColor ||
                        (lowerScoreIsBetter ? determineColorLowerIsBetter(score) : determineColorHigherIsBetter(score)),
                    width: '60%',
                    height: '60%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h1" color="#000" fontFamily="Valizas">
                    {score}
                </Typography>
            </Box>
        </Box>
    );
}

export function List({ title, listItems }: { title: string; listItems: string[] }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 1,
                bgcolor: 'transparent'
            }}
        >
            <Typography variant="h3" color="#999" fontWeight="400" fontSize="20px">
                {title}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {listItems.map((item, index) => (
                    <Typography variant="h3" color="white" key={index} fontWeight="400" fontSize="20px">
                        {item}
                    </Typography>
                ))}
            </div>
        </Box>
    );
}

// helper functions
export function determineColorLowerIsBetter(score: number) {
    if (score >= 70) {
        return '#ff738a';
    } else if (score >= 50) {
        return '#f0d8a7';
    } else {
        return '#4bffdf';
    }
}

export function determineColorHigherIsBetter(score: number) {
    if (score <= 30) {
        return '#ff738a';
    } else if (score <= 50) {
        return '#f0d8a7';
    } else {
        return '#4bffdf';
    }
}

// styled sections
export const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;
