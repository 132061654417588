import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
import { Box, Button, InputBase, styled, useTheme } from '@mui/material';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { OnboardingConversationElement } from '../../graphql/types/Onboarding';
import InfoIcon from '@mui/icons-material/Info';
import { EllaVideoContainer } from '../EllaButton';
import logoVideo from 'src/assets/vid/ELLA.mp4';

interface EllaChatContainerProps {
    conversations: OnboardingConversationElement[];
    message: string;
    isTyping: boolean;

    handleSend: () => Promise<void>; // Assuming handleSend is an async function
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}

function TypingIndicator() {
    return (
        <div className={styles.ellaTypingIndicator}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

const MessageInputWrapper = styled(InputBase)(
    ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

function parseLinks(input: string) {
    if (input) {
        const parts = input.split(/(\[[^\]]*\]\([^)]*\))/g);
        return parts.map((part, index) => {
            const match = part.match(/\[([^\]]*)\]\(([^)]*)\)/);
            if (match) {
                return (
                    <a key={index} href={match[2]}>
                        {match[1]}
                    </a>
                );
            } else {
                return part;
            }
        });
    } else {
        return '';
    }
}

export const EllaChatContainer: React.FC<EllaChatContainerProps> = ({
    conversations,
    isTyping,
    message,
    handleSend,
    setMessage
}) => {
    const theme = useTheme();
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [activeInfoIndex, setActiveInfoIndex] = useState<number | null>(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [conversations]);

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSend();
            setMessage('');
        }
    };

    const toggleInfo = (index: number) => {
        setActiveInfoIndex(activeInfoIndex === index ? null : index);
    };

    return (
        <>
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        flex: 1,
                        backgroundColor: '#222222',
                        overflowY: 'auto'
                    }}
                >
                    <div className={styles.ellaChatContainer}>
                        <div className={styles.ellaMessagesContainer}>
                            {conversations.map((message, i) => (
                                <div key={i + 'outer'}>
                                    <div
                                        key={i}
                                        className={
                                            styles.message +
                                            ` ${message.initiator == 'USER' ? styles.myMessage : styles.theirMessage}`
                                        }
                                    >
                                        {message.initiator == 'ASSISTANT' && message.reasoning && (
                                            <InfoIcon onClick={() => toggleInfo(i)} />
                                        )}
                                        <div
                                            className={`${
                                                message.initiator == 'USER'
                                                    ? styles.myMessageInt
                                                    : styles.theirMessageInt
                                            }`}
                                        >
                                            {parseLinks(message.contents)}
                                        </div>
                                    </div>
                                    {activeInfoIndex === i && (
                                        <div key={i + 'explan'} className={styles.message + ' ' + styles.theirMessage}>
                                            <div className={styles.explanMessageInt}>
                                                <div>
                                                    <EllaVideoContainer autoPlay muted playsInline loop>
                                                        <source src={logoVideo} type="video/mp4" />
                                                    </EllaVideoContainer>
                                                    {/* <div style={{ paddingBottom: '5px' }}> */} Here&apos;s the
                                                    thought process for how I responded:
                                                    {/* </div> */}
                                                </div>
                                                <div style={{ paddingTop: '5px' }}>{message.reasoning.explanation}</div>
                                                <div style={{ paddingTop: '5px' }}>
                                                    In order to decide on this response, I used the following memories
                                                    of our conversations:
                                                </div>
                                                <ul>
                                                    {message.reasoning.usedMemories.map((memory, index) => (
                                                        <li key={index}>{memory}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            {isTyping && <TypingIndicator />}

                            <div ref={messagesEndRef} />
                        </div>
                    </div>
                </div>
                <Box
                    sx={{
                        background: theme.colors.alpha.white[50],
                        display: 'flex',
                        alignItems: 'center',
                        p: 0
                    }}
                >
                    <Box flexGrow={1} display="flex" alignItems="center">
                        <MessageInputWrapper
                            autoFocus
                            placeholder="Write your message here..."
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                    </Box>
                    <Box>
                        <Button
                            onClick={() => {
                                handleSend();
                                setMessage('');
                            }}
                            disabled={message.length == 0}
                            startIcon={<SendTwoToneIcon />}
                            variant="contained"
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </div>
        </>
    );
};
