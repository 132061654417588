import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Sun from 'src/assets/svg/icons/Sun';

import { ScoreBox, PercentageBar, CardHeader } from '../components/Components';

export default function Social(cardColor: string) {
    return (
        <>
            <Card style={{ backgroundColor: 'rgba(255,255,255,0.1)', padding: '16px' }} elevation={0}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <CardHeader>
                        <Typography variant="h1" color={cardColor} fontWeight="400">
                            Social
                        </Typography>
                        <Sun />
                    </CardHeader>
                    <PercentageBar label="Social plan completion" value={85} color={cardColor} />
                    <Grid container spacing={2} width="auto">
                        <Grid item xs={6}>
                            <ScoreBox label="Social Score" score={67} />
                        </Grid>
                        <Grid item xs={6}>
                            <ScoreBox label="My Inner Circle" score={48} />
                        </Grid>
                        <Grid item xs={6}>
                            <ScoreBox label="Happiness Score" score={77} />
                        </Grid>
                        <Grid item xs={6}>
                            <ScoreBox label="Giving Score" score={81} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
