import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path
                d="M17.951 33C26.1768 33 32.8451 26.2843 32.8451 18C32.8451 9.71573 26.1768 3 17.951 3C9.72524 3 3.05695 9.71573 3.05695 18C3.05695 26.2843 9.72524 33 17.951 33Z"
                stroke="#FEF4ED"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.266 11.6396L21.1085 21.1796L11.6359 24.3596L14.7934 14.8196L24.266 11.6396Z"
                stroke="#FEF4ED"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgComponent;
