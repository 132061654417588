import React, { useRef, useState } from 'react';
import { TextField, Container, Box, Typography, Button, Grid, CircularProgress } from '@mui/material';
import ReactQuill from 'react-quill';
import { DeltaStatic } from 'quill';
import 'react-quill/dist/quill.snow.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate, useParams } from 'react-router-dom';
import { groupEventCreate } from '../../../../../graphql/GMAPI';

// Localizer for the calendar
const localizer = momentLocalizer(moment);

const EventCreate: React.FC = () => {
    const [title, setTitle] = useState<string>('');
    const [bodyDelta, setBodyDelta] = useState<DeltaStatic>(); // Store delta instead of HTML
    const [selectedRange, setSelectedRange] = useState<{ start: Date; end: Date } | null>(null);
    const quillRef = useRef<ReactQuill>(null); // Ref to access Quill instance
    const [loading, setLoading] = useState<boolean>(false);

    const { id } = useParams();
    const navigate = useNavigate();

    // Handler for date range selection in the calendar
    const handleSelect = (event: { start: Date; end: Date }) => {
        setSelectedRange(event);
    };

    const handleCreateEvent = async () => {
        if (selectedRange && title && bodyDelta) {
            setLoading(true);
            const description = JSON.stringify(bodyDelta); // Stringify the delta

            try {
                const response = await groupEventCreate(id, title, description, selectedRange.start, selectedRange.end);
                console.log('Event creation response:', response);
                setLoading(false);

                if (response.data) {
                    navigate(`/group/event/${response.data.id}`);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error in event creation:', error);
            }
        }
    };

    // Check if all conditions for enabling the 'Create' button are met
    const isCreateEnabled = title.length >= 3 && selectedRange !== null;

    const defaultScrollTime = new Date();
    defaultScrollTime.setHours(9, 0, 0);

    const handleBodyChange = (content, delta, source, editor) => {
        setBodyDelta(editor.getContents()); // Update with the full content
    };

    return (
        <>
            {loading ? (
                <CircularProgress />
            ) : (
                <Container maxWidth="md">
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        Create New Event
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Event Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                onClick={handleCreateEvent}
                                variant="contained"
                                color="primary"
                                disabled={!isCreateEnabled}
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                    <Box sx={{ mb: 4, mt: 2 }}>
                        <ReactQuill ref={quillRef} onChange={handleBodyChange} />
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Select Event Date and Time
                        </Typography>
                        <Calendar
                            localizer={localizer}
                            selectable
                            onSelectSlot={handleSelect}
                            defaultView="week"
                            views={{ week: true }}
                            events={selectedRange ? [selectedRange] : []}
                            scrollToTime={defaultScrollTime}
                            style={{ height: 500 }}
                        />
                    </Box>
                </Container>
            )}
        </>
    );
};

export default EventCreate;
