import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import EllaIcon from 'src/assets/svg/EllaIcon';
import Button from 'src/components/Button';
import styled from 'styled-components';

export default function Ella() {
    return (
        <>
            <Card sx={{ backgroundColor: '#000', padding: '12px 30px' }} elevation={0}>
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
                    <LeftSide>
                        <Typography variant="h3" color="white" fontWeight="400" fontSize="20px">
                            Chat with ELLA
                        </Typography>
                        <Button size="small" text="Chat Now" arrow="right" to="/" color="#a6bcff" />
                    </LeftSide>
                    <EllaIcon />
                </CardContent>
            </Card>
        </>
    );
}

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;
