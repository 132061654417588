import React, { CSSProperties } from 'react';
import spinner from 'src/assets/img/pulse-multiple.svg';

export default function Spinner() {
    const spinnerStyle: CSSProperties = {
        position: 'fixed', // Fixed position
        zIndex: 9999, // Make it appear on top
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Adjust for true centering
        width: '50px',
        height: '50px',
        borderRadius: '50%', // Rounded corners
        backgroundColor: 'rgba(0,0,0,0.5)', // Black background with 50% opacity
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    return (
        <div style={spinnerStyle}>
            <img src={spinner} alt="Loading..." />
        </div>
    );
}
