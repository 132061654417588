import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_253_2240)">
                <path
                    d="M22.65 14.3902L12 22.1302L1.34999 14.3902C1.20722 14.2852 1.10131 14.1377 1.04743 13.9689C0.993542 13.8001 0.99444 13.6185 1.04999 13.4502L2.26999 9.67021L4.70999 2.16021C4.73366 2.09901 4.77133 2.04421 4.81999 2.00021C4.89923 1.92783 5.00267 1.8877 5.10999 1.8877C5.21731 1.8877 5.32075 1.92783 5.39999 2.00021C5.45137 2.04987 5.48924 2.11182 5.50999 2.18021L7.94999 9.67021H16.05L18.49 2.16021C18.5137 2.09901 18.5513 2.04421 18.6 2.00021C18.6792 1.92783 18.7827 1.8877 18.89 1.8877C18.9973 1.8877 19.1008 1.92783 19.18 2.00021C19.2314 2.04987 19.2692 2.11182 19.29 2.18021L21.73 9.6902L23 13.4502C23.0505 13.6237 23.0437 13.8088 22.9807 13.9782C22.9176 14.1475 22.8017 14.292 22.65 14.3902V14.3902Z"
                    stroke="#4BFFDF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_253_2240">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgComponent;
