import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36" fill="none">
            <g clipPath="url(#clip0_253_2311)">
                <path
                    d="M19.348 25.5C23.6134 25.5 27.0712 22.1421 27.0712 18C27.0712 13.8579 23.6134 10.5 19.348 10.5C15.0826 10.5 11.6248 13.8579 11.6248 18C11.6248 22.1421 15.0826 25.5 19.348 25.5Z"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.348 1.5V4.5"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.348 31.5V34.5"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.33067 6.33008L9.52405 8.46008"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.1719 27.54L31.3653 29.67"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.35693 18H5.44621"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33.2497 18H36.339"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.33067 29.67L9.52405 27.54"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.1719 8.46008L31.3653 6.33008"
                    stroke="#FF738A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_253_2311">
                    <rect width="37.0714" height="36" fill="white" transform="translate(0.812286)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgComponent;
