import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import messagesReducer from './features/user/messageSlice';
import conversationsReducer from './features/onboarding/onboardingConversationSlice';
import vadReducer from './features/vad/vadReducer';

export const store = configureStore({
    reducer: {
        user: userReducer,
        messages: messagesReducer,
        conversations: conversationsReducer,
        vad: vadReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
