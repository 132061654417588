import { Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

function PageHeader() {
    return (
        <>
            <Typography variant="h3">{t('Company Settings')}</Typography>
        </>
    );
}

export default PageHeader;
