import { Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import avatar from 'src/assets/img/pfp.png';
import styled from 'styled-components';
import Star from 'src/assets/svg/icons/Star';
import GitLab from 'src/assets/svg/icons/GitLab';
import Shield from 'src/assets/svg/icons/Shield';
import Button from 'src/components/Button';

export default function Profile() {
    return (
        <Card style={{ backgroundColor: 'rgba(255,255,255,0.1)', position: 'relative' }} elevation={0}>
            <div
                style={{
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    padding: '20% 0 0 0'
                }}
            />
            <SPLContainer>
                <Typography variant="body1" color="#999">
                    SPL:
                </Typography>
                <Typography variant="h4" color="#4bffdf" fontFamily="Valizas">
                    2,164
                </Typography>
            </SPLContainer>
            <AvatarContainer>
                <img src={avatar} alt="profile photo" style={{ width: '100%', borderRadius: '50%' }} />
            </AvatarContainer>
            <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '2px 9% 9%' }}>
                <div>
                    <Typography variant="h3" color="white" fontWeight="400">
                        Claire Rowlands
                    </Typography>
                    <Typography variant="h4" color="grey" fontWeight="400">
                        Community Carer
                    </Typography>
                </div>
                <Divider sx={{ border: '1px solid rgba(159, 159, 159, 0.40)' }} />

                <Typography variant="body2" color="#d9d9d9">
                    Badges Earned
                </Typography>
                <BadgesWrapper>
                    <Badges>
                        <GitLab />
                        <Star />
                        <Shield />
                    </Badges>
                    <Button to="/" color="#a6bcff" arrow="right" size="small" text="Full Profile" />
                </BadgesWrapper>
            </CardContent>
        </Card>
    );
}

const BadgesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Badges = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const SPLContainer = styled.div`
    position: absolute;
    top: 18px;
    left: 36px;
`;

const AvatarContainer = styled.div`
    width: 25%;
    margin: -12% auto 0;
`;
