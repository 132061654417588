import {
    alpha,
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
} from '@mui/material';
import React, { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../../../redux/hooks';
import { removeUnreadMessage, selectMessages } from '../../../../../redux/features/user/messageSlice';
import { Link, useNavigate } from 'react-router-dom';
import { groupPostReadUpdate, userFriendRequestAccept, userFriendRequestReject } from '../../../../../graphql/GMAPI';
import { useAppDispatch } from '../../../../..';
import { UserUnreadNotification } from '../../../../../graphql/types/User';

const NotificationsBadge = styled(Badge)(
    ({ theme }) => `
        .MuiBadge-badge {
            background-color: ${alpha(theme.palette.error.main, 0.1)};
            color: ${theme.palette.error.main};
            min-width: 16px; 
            height: 16px;
            padding: 0;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
                content: "";
            }
        }
    `
);

function HeaderNotifications() {
    const ref = useRef<HTMLButtonElement>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const messages = useAppSelector(selectMessages)?.value;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    function truncateString(str, num) {
        if (str.length <= num) return str;
        return str.slice(0, num) + '...';
    }

    async function acceptFriend(user_friend_id: string) {
        await userFriendRequestAccept(user_friend_id);
        dispatch(removeUnreadMessage([{ type: 'FRIEND_REQUEST', source_id: user_friend_id }]));
    }

    async function rejectFriend(user_friend_id: string) {
        await userFriendRequestReject(user_friend_id);
        dispatch(removeUnreadMessage([{ type: 'FRIEND_REQUEST', source_id: user_friend_id }]));
    }

    const handleGroupPostClick = async (groupId, groupPostId) => {
        await groupPostReadUpdate(groupPostId);
        handleClose();

        //Remove from notifications..
        const message: UserUnreadNotification[] = [
            {
                type: 'GROUP_POST',
                group_post: {
                    id: groupPostId,
                    group_id: groupId
                }
            }
        ];
        dispatch(removeUnreadMessage(message));

        navigate(`/group/instance/${groupId}/${groupPostId}`);
    };

    return (
        <>
            <Tooltip arrow title="Notifications">
                <IconButton color="primary" ref={ref} onClick={handleOpen}>
                    <NotificationsBadge
                        badgeContent={messages?.length || 0}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <NotificationsActiveTwoToneIcon />
                    </NotificationsBadge>
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box sx={{ p: 2 }} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">Notifications</Typography>
                </Box>
                <Divider />
                <List sx={{ p: 0 }}>
                    {messages?.map((message, index) => (
                        <React.Fragment key={index}>
                            {message.type === 'USER_MESSAGE' && (
                                <div onClick={handleClose}>
                                    <Link
                                        to={`/dashboards/messenger/conversation/${message.user_message.from_user_id}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <ListItem
                                            sx={{
                                                p: 2,
                                                maxWidth: 350,
                                                display: { xs: 'block', sm: 'flex' }
                                            }}
                                        >
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography
                                                    noWrap
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    {truncateString(message.user_message?.body, 40)}
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="caption"
                                                    color="text.primary"
                                                    sx={{
                                                        display: 'block',
                                                        mt: 0.2
                                                    }}
                                                >
                                                    {message.user_message?.from_user.name}
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                    </Link>
                                </div>
                            )}
                            {message.type === 'GROUP_POST' && (
                                <div
                                    onClick={() =>
                                        handleGroupPostClick(message.group_post.group_id, message.group_post.id)
                                    }
                                    style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                                >
                                    <ListItem
                                        sx={{
                                            p: 2,
                                            maxWidth: 350,
                                            display: { xs: 'block', sm: 'flex' }
                                        }}
                                    >
                                        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
                                            <Avatar
                                                src={message.group_post.poster.avatar_url}
                                                sx={{ marginRight: 2 }} // Add horizontal spacing
                                            />
                                            {message.subType == null ||
                                                (message.subType == 'GROUP_POST_COMMENT' && (
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="text.secondary"
                                                        noWrap
                                                    >
                                                        {`${message.group_post.poster.name} mentioned you in a comment`}
                                                    </Typography>
                                                ))}
                                            {message.subType == 'GROUP_POST_REPLY' && (
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                    noWrap
                                                >
                                                    {`${message.group_post.poster.name} replied to your post`}
                                                </Typography>
                                            )}
                                            {message.subType == 'GROUP_POST_REACTION' && (
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                    noWrap
                                                >
                                                    {`${message.group_post.poster.name} reacted to your post`}
                                                </Typography>
                                            )}
                                        </Box>
                                    </ListItem>
                                </div>
                            )}

                            {message.type === 'FRIEND_REQUEST' && (
                                <ListItem
                                    sx={{
                                        p: 2,
                                        maxWidth: 350,
                                        display: { xs: 'block', sm: 'flex' }
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography noWrap component="span" variant="body2" color="text.secondary">
                                            Friend request
                                        </Typography>
                                        <Button
                                            onClick={() => {
                                                acceptFriend(message.source_id);
                                            }}
                                        >
                                            Accept
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                rejectFriend(message.source_id);
                                            }}
                                        >
                                            Reject
                                        </Button>
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            color="text.primary"
                                            sx={{
                                                display: 'block',
                                                mt: 0.2
                                            }}
                                        >
                                            {message.source_name}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            )}
                            {index !== messages.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Popover>
        </>
    );
}

export default HeaderNotifications;
