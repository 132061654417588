import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
import { groupCreate } from '../../../../../graphql/GMAPI';
import { DeltaStatic } from 'quill';

const GroupCreate: React.FC = () => {
    const navigate = useNavigate();
    const [clubName, setClubName] = useState('');
    const [clubDescription, setClubDescription] = useState<DeltaStatic>();
    const [canCreate, setCanCreate] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setClubName(name);
        setCanCreate(name.length >= 5);
    };

    const handleDescriptionChange = (
        content: string,
        delta: DeltaStatic,
        source: string,
        editor: UnprivilegedEditor
    ) => {
        const deltaValue = editor.getContents(); // This gets the delta
        setClubDescription(deltaValue); // Assuming you want to store the delta instead of HTML
        setCanCreate(clubName.length >= 5 && editor.getLength() > 1); // editor.getLength() gives you the content length
    };

    const handleCreate = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await groupCreate(clubName, JSON.stringify(clubDescription));
            if (response.data) {
                navigate('/group/home');
            } else {
                setIsLoading(false); // Stop loading if request failed
            }
        } catch (error) {
            console.error('Error creating group:', error);
            setIsLoading(false); // Stop loading if there was an error
        }
    };

    const handleCancel = () => {
        navigate('/group/home');
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Create a Club</title>
            </Helmet>
            <Box sx={{ margin: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Create a New Club
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    Here you can create a new club inside the Grey Matters Platform. Clubs are a social gathering,
                    allowing you to interact with and learn from like-minded people.
                </Typography>
                <Box>
                    <TextField
                        label="Club Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={clubName}
                        onChange={handleNameChange}
                        inputProps={{ minLength: 5 }}
                    />
                    <ReactQuill
                        theme="snow"
                        value={clubDescription}
                        onChange={handleDescriptionChange}
                        style={{ height: '10em', marginBottom: '20px' }}
                    />
                    <Box paddingTop={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" color="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" disabled={!canCreate} onClick={handleCreate}>
                            Create
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default GroupCreate;
