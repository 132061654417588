import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

export enum VoiceMode {
    NOT_SPEAKING,
    USER_SPEAKING,
    ELLA_THINKING,
    ELLA_SPEAKING
}

// Define a type for the slice state
interface MessageState {
    speechState: boolean;
    micOn: boolean;
    voiceMode: VoiceMode;
}

// Define the initial state using that type
const initialState = {
    speechState: false,
    micOn: false,
    voiceMode: VoiceMode.NOT_SPEAKING
} as MessageState;

export const vadSlice = createSlice({
    name: 'vadSlice',
    initialState,
    reducers: {
        setSpeechState(state, action: PayloadAction<boolean>) {
            state.speechState = action.payload;
        },
        setVoiceMode(state, action: PayloadAction<VoiceMode>) {
            state.voiceMode = action.payload;
        },
        setMicOn(state, action: PayloadAction<boolean>) {
            state.micOn = action.payload;
        }
    }
});

export const { setSpeechState, setVoiceMode, setMicOn } = vadSlice.actions;

export const selectSpeechState = (state: RootState) => state.vad.speechState;
export const selectVoiceMode = (state: RootState) => state.vad.voiceMode;
export const selectMicOn = (state: RootState) => state.vad.micOn;

export default vadSlice.reducer;
