import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="36" viewBox="0 0 38 36" fill="none">
            <path
                d="M32.6628 6.91481C31.8812 6.14832 30.9532 5.54028 29.9318 5.12543C28.9104 4.71059 27.8157 4.49707 26.71 4.49707C25.6044 4.49707 24.5097 4.71059 23.4883 5.12543C22.4669 5.54028 21.5389 6.14832 20.7573 6.91481L19.1352 8.50481L17.5131 6.91481C15.9343 5.36727 13.7931 4.49787 11.5604 4.49787C9.32763 4.49787 7.18636 5.36727 5.60759 6.91481C4.02882 8.46235 3.14188 10.5613 3.14188 12.7498C3.14188 14.9384 4.02882 17.0373 5.60759 18.5848L7.22968 20.1748L19.1352 31.8448L31.0407 20.1748L32.6628 18.5848C33.4448 17.8187 34.0651 16.909 34.4883 15.9078C34.9115 14.9067 35.1293 13.8335 35.1293 12.7498C35.1293 11.6661 34.9115 10.593 34.4883 9.59178C34.0651 8.59059 33.4448 7.68094 32.6628 6.91481V6.91481Z"
                stroke="#A6BCFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgComponent;
