import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import {
    myUser,
    onboardingConversationHistory,
    onboardingMemories,
    q_companyDetailsGet,
    q_createJobProfile,
    q_currencyListGet,
    q_feedGet,
    q_financialStatementGet,
    q_financialStatementSimulationGet,
    q_friendsListGet,
    q_groupEventGet,
    q_groupGet,
    q_groupListGet,
    q_groupPostGet,
    q_jobDetailGet,
    q_jobListGet,
    q_jobProfileGet,
    q_messagesUnreadGet,
    q_promptGet,
    q_promptListGet,
    q_quizInstanceGet,
    q_quizInstanceListGet,
    q_quizListGet,
    q_recentConversationsGet,
    q_recentMessagesGet,
    q_serviceStateGet,
    q_statisticsGet,
    q_taskerJobApplicationsGet,
    q_userCurrencyBalanceGet,
    q_userFindByNameFragment,
    q_userGet
} from './queries';
import {
    nextConversationElement,
    updateUser,
    resetUser,
    m_saveJobProfile,
    m_recordUserServiceOnboarded,
    m_taskerJobCreate,
    m_taskerJobApply,
    q_messageSend,
    m_taskerJobApplicationSelect,
    m_ellaConversationNext,
    m_quizInstanceCreate,
    m_quizInstanceResponseUpdate,
    m_onboardingSpeechTranscribe,
    m_groupJoin,
    m_groupLeave,
    m_groupDetailsUpdate,
    m_groupPost,
    m_groupCreate,
    m_groupMemberSeenUntilUpdate,
    m_groupPostReadUpdate,
    m_groupEventCreate,
    m_groupPostReact,
    m_groupEventAttendToggle,
    m_avatarUploadGenerateURL,
    m_taskerJobEdit,
    m_groupPostImageCreate,
    m_userFriendRemove,
    m_userFriendAdd,
    m_userFriendRequestAccept,
    m_userFriendRequestReject,
    m_companyDetailsUpdate,
    m_ellaFinancialDiscussionNext,
    m_promptUpdate,
    m_userUpdateAndReset
} from './mutations';
import { Currency, Response, TaskerJob, TaskerJobApplication, TaskerJobEdit } from './types/Job';
import { ServiceState } from './types/ServiceState';
import { StatisticsResponse } from './types/Statistics';
import { QuizDef, QuizInstance } from './types/Quiz';
import { SignedURL, User, UserBalance, UserFriend, UserUnreadNotification } from './types/User';
import { FeedEvent } from './types/Feed';
import {
    Group,
    GroupEvent,
    GroupEventAttendee,
    GroupLeave,
    GroupMember,
    GroupPost,
    GroupPostReaction
} from './types/Group';
import { OnboardingConversationElement } from './types/Onboarding';
import { Company, CompanyShareType } from './types/Company';
import {
    FinancialStatement,
    FinancialStatementMonth,
    OnboardingConversationElementWithFinancialStatement
} from './types/Financial';
import { Prompt } from './types/Prompt';

async function currentSession() {
    try {
        const data = await Auth.currentAuthenticatedUser();
        return data;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function isAuthenticated() {
    const user = await currentSession();

    //console.log('User at isAuthenticated: ' + JSON.stringify(user));

    if (user) return true;
    return false;
}

export async function getCurrentUser() {
    await currentSession();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ((await API.graphql(graphqlOperation(myUser))) as any).data.myUser;
}

export async function updateMyUser(
    name: string,
    email: string,
    dob: Date,
    target_retirement_date: Date,
    language: string
): Promise<User> {
    await currentSession();

    try {
        const input = {
            name,
            email,
            dob,
            target_retirement_date,
            language
        };

        const result = await API.graphql(graphqlOperation(updateUser, input));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.updateUser;
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

export async function getNextConversationElement(input: string) {
    try {
        const inputParams = {
            inputFromUser: input
        };

        const result = await API.graphql(graphqlOperation(nextConversationElement, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.onboardingConversation;
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

export async function onboardingSpeechTranscribe(
    base64Input: string
): Promise<Response<OnboardingConversationElement>> {
    try {
        const inputParams = {
            base64Input
        };
        const result: {
            data: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onboardingSpeechTranscribe: Response<any>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_onboardingSpeechTranscribe, inputParams))) as any;

        const ret = result.data.onboardingSpeechTranscribe;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function ellaConversationNext(input: string) {
    try {
        const inputParams = {
            inputFromUser: input
        };

        const result = await API.graphql(graphqlOperation(m_ellaConversationNext, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.ellaConversationNext;
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

export async function getConversationHistory(
    type: 'GENERAL' | 'FINANCIAL' | 'JOB_PROFILE' = 'GENERAL'
): Promise<Response<OnboardingConversationElement[]>> {
    try {
        const inputParams = {
            type
        };
        const result: {
            data: {
                onboardingConversationHistory: Response<OnboardingConversationElement[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(onboardingConversationHistory, inputParams))) as any;

        const ret = result.data.onboardingConversationHistory;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting conversation history for user:', error);
    }
}

export async function createJobProfile() {
    try {
        const result = await API.graphql(graphqlOperation(q_createJobProfile));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.createJobProfile;
    } catch (error) {
        console.error('Error writing job profile for user:', error);
    }
}

export async function jobProfileGet() {
    try {
        const result = await API.graphql(graphqlOperation(q_jobProfileGet));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.jobProfileGet;
    } catch (error) {
        console.error('Error getting job profile for user:', error);
    }
}

export async function jobListGet(page: number, mine: boolean, mySelectedJobs: boolean): Promise<Response<TaskerJob[]>> {
    try {
        const inputParams = {
            page,
            mine,
            mySelectedJobs
        };

        const result: {
            data: {
                jobListGet: Response<TaskerJob[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_jobListGet, inputParams))) as any;

        const ret = result.data.jobListGet;

        //Parse the location to objects..
        const updatedData = ret.data.map((item) => {
            return {
                ...item,
                location: JSON.parse(item.location as unknown as string)
            };
        });

        ret.data = updatedData;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting job list for user:', error);
    }
}

export async function taskerJobApplicationsGet(page: number) {
    try {
        const inputParams = {
            page
        };
        const result = await API.graphql(graphqlOperation(q_taskerJobApplicationsGet, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ret = (result as any).data.taskerMyJobApplicationsGet;

        //Parse the location to objects..
        const updatedData = ret.map((item) => {
            return {
                ...item.job,
                location: JSON.parse(item.job.location)
            };
        });

        return updatedData;
    } catch (error) {
        console.error('Error getting job list for user:', error);
    }
}

export async function jobDetailGet(jobId: string, applications: boolean) {
    try {
        const inputParams = {
            jobId,
            applications
        };

        const result = await API.graphql(graphqlOperation(q_jobDetailGet, inputParams));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ret = (result as any).data.jobDetailGet;

        const jobFormatted = {
            ...ret,
            location: JSON.parse(ret.location),
            body: JSON.parse(ret.body)
        };

        return jobFormatted;
    } catch (error) {
        console.error('Error retrieving service state for user:', error);
    }
}

export async function taskerJobCreate(
    title: string,
    description: string,
    date_to_perform: Date,
    location: string,
    currency_id: string,
    amount: number
) {
    try {
        const inputParams = {
            title,
            description,
            date_to_perform,
            location,
            currency_id,
            amount
        };

        const result = await API.graphql(graphqlOperation(m_taskerJobCreate, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ret = (result as any).data.taskerJobCreate;

        return ret;
    } catch (error) {
        console.error('Could not create task: ', error);
    }
}

export async function taskerJobApply(jobId: string): Promise<Response<TaskerJob>> {
    try {
        const inputParams = {
            jobId
        };

        const result: { data: { taskerJobApply: Response<TaskerJob> } } = (await API.graphql(
            graphqlOperation(m_taskerJobApply, inputParams)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        )) as any;
        const ret = result.data.taskerJobApply;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Could not apply for job:', error);
        return { errors: [{ message: error.message, code: -1 }] };
    }
}

export async function taskerProfileEdit(
    instructions: OnboardingConversationElement[]
): Promise<Response<TaskerJobEdit>> {
    try {
        type TaskerProfileEditInput = {
            instruction: string;
            initiator: 'USER' | 'ASSISTANT';
        };

        const inputs: TaskerProfileEditInput[] = [];
        for (const input of instructions) {
            inputs.push({ instruction: input.contents, initiator: input.initiator });
        }

        const inputParams = {
            instructions: inputs
        };

        const result: { data: { taskerProfileEdit: Response<TaskerJobEdit> } } = (await API.graphql(
            graphqlOperation(m_taskerJobEdit, inputParams)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        )) as any;
        const ret = result.data.taskerProfileEdit;

        if (ret && ret.data && typeof ret.data.profile === 'string') {
            ret.data.profile = JSON.parse(ret.data.profile);
        }

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Could not edit job profile:', error);
        return { errors: [{ message: error.message, code: -1 }] };
    }
}

export async function taskerJobApplicationSelect(
    taskerJobApplicationId: string
): Promise<Response<TaskerJobApplication>> {
    try {
        const inputParams = {
            taskerJobApplicationId
        };

        const result: {
            data: {
                taskerJobApplicationSelect: Response<TaskerJobApplication>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_taskerJobApplicationSelect, inputParams))) as any;

        console.log('Applicant response: ' + JSON.stringify(result));

        const ret = result.data.taskerJobApplicationSelect;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Could not select applicant for job:', error);
        return { errors: [{ message: error.message, code: -1 }] };
    }
}

export async function serviceStateGet(serviceName: string): Promise<Response<ServiceState>> {
    try {
        const inputParams = {
            serviceName
        };

        const result: { data: { serviceStateGet: Response<ServiceState> } } = (await API.graphql(
            graphqlOperation(q_serviceStateGet, inputParams)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        )) as any;

        const ret = result.data.serviceStateGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving service state for user:', error);
    }
}

export async function saveJobProfile(title: string, body: string) {
    try {
        const inputParams = {
            title,
            body
        };

        const result = await API.graphql(graphqlOperation(m_saveJobProfile, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.saveJobProfile;
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

export async function recordUserServiceOnboarded(serviceName: string) {
    try {
        const inputParams = {
            serviceName
        };

        const result = await API.graphql(graphqlOperation(m_recordUserServiceOnboarded, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.recordUserServiceOnboarded;
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

export async function resetUserProfile() {
    await currentSession();

    try {
        const result = await API.graphql(graphqlOperation(resetUser));
        console.log('Reset user result: ' + JSON.stringify(result));
    } catch (error) {
        console.error('Error resetting user:', error);
    }
}

export async function getOnboardingMemories() {
    try {
        const result = await API.graphql(graphqlOperation(onboardingMemories));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data.onboardingMemories;
    } catch (error) {
        console.error('Error updating user:', error);
    }
}

export async function getRecentConversations() {
    try {
        const result = await API.graphql(graphqlOperation(q_recentConversationsGet));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data;
    } catch (error) {
        console.error('Error getting conversations', error);
    }
}

export async function getRecentMessages(from_user_id: string, page: number) {
    console.log('From user id is: ' + from_user_id);
    const inputParams = {
        from_user_id,
        page
    };

    try {
        const result = await API.graphql(graphqlOperation(q_recentMessagesGet, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data;
    } catch (error) {
        console.error('Error getting messages:', error);
    }
}

export async function messagesUnreadGet(): Promise<Response<UserUnreadNotification[]>> {
    try {
        const result: { data: { messagesUnreadGet: Response<UserUnreadNotification[]> } } = (await API.graphql(
            graphqlOperation(q_messagesUnreadGet)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        )) as any;

        const ret = result.data.messagesUnreadGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving service state for user:', error);
    }
}

export async function userFindByNameFragment(nameFragment: string): Promise<Response<User[]>> {
    console.log('Sending name frag: ' + nameFragment);
    const inputParams = {
        nameFragment
    };

    try {
        const result: {
            data: {
                userFindByNameFragment: Response<User[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_userFindByNameFragment, inputParams))) as any;

        const ret = result.data.userFindByNameFragment;
        console.log('Object is: ' + JSON.stringify(result));

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving statistics:', error);
    }
}

export async function messageSend(to_user_id: string, body: string) {
    const inputParams = {
        to_user_id,
        body
    };

    try {
        const result = await API.graphql(graphqlOperation(q_messageSend, inputParams));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (result as any).data;
    } catch (error) {
        console.error('Error getting names:', error);
    }
}

export async function statisticsGet(type: string): Promise<Response<StatisticsResponse>> {
    try {
        const inputParams = {
            type
        };

        const result: {
            data: {
                statisticsGet: Response<StatisticsResponse>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_statisticsGet, inputParams))) as any;

        const ret = result.data.statisticsGet;
        console.log('Object is: ' + JSON.stringify(result));

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving statistics:', error);
    }
}

export async function quizListGet(): Promise<Response<QuizDef[]>> {
    try {
        const result: {
            data: {
                quizListGet: Response<QuizDef[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_quizListGet))) as any;

        const ret = result.data.quizListGet;
        console.log('Object is: ' + JSON.stringify(result));

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving statistics:', error);
    }
}

export async function quizInstanceCreate(quiz_def_id: string): Promise<Response<QuizInstance>> {
    try {
        const inputParams = {
            quiz_def_id
        };

        const result: {
            data: {
                quizInstanceCreate: Response<QuizInstance>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_quizInstanceCreate, inputParams))) as any;

        const ret = result.data.quizInstanceCreate;
        console.log('Object is: ' + JSON.stringify(result));

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving statistics:', error);
    }
}

export async function quizInstanceResponseUpdate(
    quiz_question_id: string,
    quiz_instance_id: string,
    answer: string
): Promise<Response<QuizInstance>> {
    try {
        const inputParams = {
            quiz_question_id,
            quiz_instance_id,
            answer
        };

        const result: {
            data: {
                quizInstanceResponseUpdate: Response<QuizInstance>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_quizInstanceResponseUpdate, inputParams))) as any;

        const ret = result.data.quizInstanceResponseUpdate;

        if (ret.data && typeof ret.data.result === 'string') {
            ret.data.result = JSON.parse(ret.data.result);
        }
        console.log('Object is: ' + JSON.stringify(result));

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving statistics:', error);
    }
}

export async function quizInstanceListGet(): Promise<Response<QuizInstance[]>> {
    try {
        const result: {
            data: {
                quizInstanceListGet: Response<QuizInstance[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_quizInstanceListGet))) as any;

        const ret = result.data.quizInstanceListGet;
        console.log('Object is: ' + JSON.stringify(result));

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving statistics:', error);
    }
}

export async function quizInstanceGet(quiz_instance_id: string): Promise<Response<QuizInstance>> {
    try {
        const inputParams = {
            quiz_instance_id
        };

        const result: {
            data: {
                quizInstanceGet: Response<QuizInstance>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_quizInstanceGet, inputParams))) as any;

        const ret = result.data.quizInstanceGet;
        if (ret.data && typeof ret.data.answers === 'string') {
            console.log('Parsing answers: ' + ret.data.answers);
            ret.data.answers = JSON.parse(ret.data.answers);
        }
        if (ret.data && typeof ret.data.result === 'string') {
            console.log('Parsing quiz instance at get: ' + ret.data.result);
            ret.data.result = JSON.parse(ret.data.result);
        } else {
            ret.data.result = { outcome: 'Unknown' };
        }
        console.log('Object is: ' + JSON.stringify(result));

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function feedGet(page: number, userId: string = null): Promise<Response<FeedEvent[]>> {
    try {
        const inputParams = {
            page,
            userId
        };
        const result: {
            data: {
                feedGet: Response<FeedEvent[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_feedGet, inputParams))) as any;

        const ret = result.data.feedGet;

        //Parse different types...
        for (const feedEvent of ret.data) {
            if (feedEvent.group_post && typeof feedEvent.group_post.body == 'string') {
                feedEvent.group_post.body = JSON.parse(feedEvent.group_post.body);

                for (const childPost of feedEvent.group_post.child_posts) {
                    if (childPost && typeof childPost.body == 'string') {
                        childPost.body = JSON.parse(childPost.body);
                    }
                }
            }
        }

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function groupCreate(name: string, description: string): Promise<Response<Group>> {
    try {
        const inputParams = {
            name,
            description
        };
        const result: {
            data: {
                groupCreate: Response<Group>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupCreate, inputParams))) as any;

        const ret = result.data.groupCreate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error creating group instance:', error);
    }
}

export async function groupGet(id: string): Promise<Response<Group>> {
    try {
        const inputParams = {
            id
        };
        const result: {
            data: {
                groupGet: Response<Group>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_groupGet, inputParams))) as any;

        const ret = result.data.groupGet;
        if (ret.data && typeof ret.data.description === 'string') {
            console.log('Parsing description for group: ' + ret.data.description);
            ret.data.description = JSON.parse(ret.data.description);
        }

        for (const post of ret.data.posts) {
            if (post.body && typeof post.body == 'string') {
                post.body = JSON.parse(post.body);
            }

            for (const child_post of post.child_posts) {
                if (child_post.body && typeof child_post.body == 'string') {
                    child_post.body = JSON.parse(child_post.body);
                }
            }
        }

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function groupDetailsUpdate(groupId: string, name: string, description: string): Promise<Response<Group>> {
    try {
        const inputParams = {
            groupId,
            name,
            description
        };
        const result: {
            data: {
                groupDetailsUpdate: Response<Group>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupDetailsUpdate, inputParams))) as any;

        const ret = result.data.groupDetailsUpdate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function groupPostReadUpdate(group_post_id: string): Promise<Response<User>> {
    try {
        const inputParams = {
            group_post_id
        };
        const result: {
            data: {
                groupPostReadUpdate: Response<User>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupPostReadUpdate, inputParams))) as any;

        const ret = result.data.groupPostReadUpdate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function groupPostImageCreate(group_post_id: string): Promise<Response<string>> {
    try {
        const inputParams = {
            group_post_id
        };
        const result: {
            data: {
                groupPostImageCreate: Response<string>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupPostImageCreate, inputParams))) as any;

        const ret = result.data.groupPostImageCreate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error creating image:', error);
    }
}

export async function groupEventCreate(
    groupId: string,
    title: string,
    description: string,
    dateStart: Date,
    dateEnd: Date
): Promise<Response<GroupEvent>> {
    try {
        const inputParams = {
            groupId,
            title,
            description,
            dateStart,
            dateEnd
        };
        const result: {
            data: {
                groupEventCreate: Response<GroupEvent>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupEventCreate, inputParams))) as any;

        const ret = result.data.groupEventCreate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function groupEventGet(id: string): Promise<Response<GroupEvent>> {
    try {
        const inputParams = {
            id
        };
        const result: {
            data: {
                groupEventGet: Response<GroupEvent>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_groupEventGet, inputParams))) as any;

        const ret = result.data.groupEventGet;

        if (ret.data && typeof ret.data.body === 'string') {
            ret.data.body = JSON.parse(ret.data.body);
        }

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving group event:', error);
    }
}

export async function groupPostGet(id: string): Promise<Response<GroupPost>> {
    try {
        const inputParams = {
            id
        };
        const result: {
            data: {
                groupPostGet: Response<GroupPost>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_groupPostGet, inputParams))) as any;

        const ret = result.data.groupPostGet;

        //Parse Quill delta body and child post bodies
        if (ret.data && typeof ret.data.body === 'string') {
            ret.data.body = JSON.parse(ret.data.body);
        }

        for (const child_post of ret.data.child_posts) {
            if (child_post.body && typeof child_post.body == 'string') {
                child_post.body = JSON.parse(child_post.body);
            }
        }

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving group post:', error);
    }
}

export async function groupPost(
    groupId: string,
    title: string,
    body: string,
    parentId: string | null
): Promise<Response<GroupPost>> {
    try {
        const inputParams = {
            group_id: groupId,
            title: title,
            body: body,
            parent_id: parentId || ''
        };

        const result: {
            data: {
                groupPost: Response<GroupPost>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupPost, inputParams))) as any;

        const ret = result.data.groupPost;
        if (ret.data && typeof ret.data.body === 'string') {
            ret.data.body = JSON.parse(ret.data.body);
        }

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error creating group post:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
}

export async function groupPostReact(
    group_post_id: string,
    reaction: 'LIKE' | 'LOVE' | 'LAUGH' | 'SURPRISE' | 'ANGRY'
): Promise<Response<GroupPostReaction>> {
    try {
        const inputParams = {
            group_post_id,
            reaction
        };

        const result: {
            data: {
                groupPostReact: Response<GroupPostReaction>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupPostReact, inputParams))) as any;

        const ret = result.data.groupPostReact;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error creating group post reaction:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
}

export async function groupEventAttendToggle(groupEventId: string): Promise<Response<GroupEventAttendee>> {
    try {
        const inputParams = {
            groupEventId
        };

        const result: {
            data: {
                groupEventAttendToggle: Response<GroupEventAttendee>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupEventAttendToggle, inputParams))) as any;

        const ret = result.data.groupEventAttendToggle;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error toggling event attendance:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
}

export async function groupListGet(page: number): Promise<Response<Group[]>> {
    try {
        const inputParams = {
            page
        };
        const result: {
            data: {
                groupListGet: Response<Group[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_groupListGet, inputParams))) as any;

        const ret = result.data.groupListGet;
        for (const group of ret.data) {
            if (typeof group.description === 'string') {
                console.log('Parsing description for group: ' + group.description);
                group.description = JSON.parse(group.description);
            }

            if (group.lastPost && typeof group.lastPost.body === 'string') {
                console.log('Parsing body for group last post: ');
                group.lastPost.body = JSON.parse(group.lastPost.body);
            }

            //We don't have comments, so just create an empty list
            if (group.lastPost) {
                group.lastPost.child_posts = [];
            }
        }

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving group list:', error);
    }
}

export async function groupJoin(groupId: string): Promise<Response<GroupMember>> {
    try {
        const inputParams = {
            groupId
        };
        const result: {
            data: {
                groupJoin: Response<GroupMember>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupJoin, inputParams))) as any;

        const ret = result.data.groupJoin;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error joining group:', error);
    }
}

export async function avatarUploadGenerateURL(): Promise<Response<SignedURL>> {
    try {
        const result: {
            data: {
                avatarUploadGenerateURL: Response<SignedURL>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_avatarUploadGenerateURL))) as any;

        const ret = result.data.avatarUploadGenerateURL;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error generating URL:', error);
    }
}

export async function groupMemberSeenUntilUpdate(group_post_id: string): Promise<Response<GroupMember>> {
    try {
        const inputParams = {
            group_post_id
        };
        const result: {
            data: {
                groupMemberSeenUntilUpdate: Response<GroupMember>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupMemberSeenUntilUpdate, inputParams))) as any;

        const ret = result.data.groupMemberSeenUntilUpdate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error joining group:', error);
    }
}

export async function groupLeave(groupId: string): Promise<Response<GroupLeave>> {
    try {
        const inputParams = {
            groupId
        };
        const result: {
            data: {
                groupLeave: Response<GroupLeave>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_groupLeave, inputParams))) as any;

        const ret = result.data.groupLeave;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error leaving group:', error);
    }
}

export async function userGet(userId: string): Promise<Response<User>> {
    try {
        const inputParams = {
            userId
        };
        const result: {
            data: {
                userGet: Response<User>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_userGet, inputParams))) as any;

        const ret = result.data.userGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving quiz instance:', error);
    }
}

export async function userCurrencyBalanceGet(): Promise<Response<UserBalance>> {
    try {
        const result: {
            data: {
                userCurrencyBalanceGet: Response<UserBalance>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_userCurrencyBalanceGet))) as any;

        const ret = result.data.userCurrencyBalanceGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error retrieving user balance:', error);
    }
}

export async function userFriendAdd(friend_id: string): Promise<Response<UserFriend>> {
    try {
        const inputParams = {
            friend_id
        };

        const result: {
            data: {
                userFriendAdd: Response<UserFriend>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_userFriendAdd, inputParams))) as any;

        const ret = result.data.userFriendAdd;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error adding friend:', error);
    }
}

export async function userFriendRemove(friend_id: string): Promise<Response<UserFriend>> {
    try {
        const inputParams = {
            friend_id
        };

        const result: {
            data: {
                userFriendRemove: Response<UserFriend>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_userFriendRemove, inputParams))) as any;

        const ret = result.data.userFriendRemove;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error removing friend:', error);
    }
}

export async function userFriendRequestAccept(user_friend_id: string): Promise<Response<UserFriend>> {
    try {
        const inputParams = {
            user_friend_id
        };

        const result: {
            data: {
                userFriendRequestAccept: Response<UserFriend>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_userFriendRequestAccept, inputParams))) as any;

        const ret = result.data.userFriendRequestAccept;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error accepting friend:', error);
    }
}

export async function userFriendRequestReject(user_friend_id: string): Promise<Response<UserFriend>> {
    try {
        const inputParams = {
            user_friend_id
        };

        const result: {
            data: {
                userFriendRequestReject: Response<UserFriend>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_userFriendRequestReject, inputParams))) as any;

        const ret = result.data.userFriendRequestReject;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error accepting friend:', error);
    }
}

export async function friendsListGet(page: number): Promise<Response<User[]>> {
    try {
        const inputParams = {
            page
        };

        const result: {
            data: {
                friendsListGet: Response<User[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_friendsListGet, inputParams))) as any;

        const ret = result.data.friendsListGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting friends list:', error);
    }
}

export async function currencyListGet(): Promise<Response<Currency[]>> {
    try {
        const result: {
            data: {
                currencyListGet: Response<Currency[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_currencyListGet))) as any;

        const ret = result.data.currencyListGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting currency list:', error);
    }
}

export async function companyDetailsGet(): Promise<Response<Company>> {
    try {
        const result: {
            data: {
                companyDetailsGet: Response<Company>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_companyDetailsGet))) as any;

        const ret = result.data.companyDetailsGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting company details:', error);
    }
}

export async function companyDetailsUpdate(
    friends_unaffiliated: boolean,
    groups_unaffiliated: boolean,
    messages_unaffiliated: boolean,
    jobs_unaffiliated: boolean,
    mode_friends: CompanyShareType,
    mode_jobs: CompanyShareType,
    mode_messages: CompanyShareType,
    mode_groups: CompanyShareType
): Promise<Response<Company>> {
    try {
        const inputParams = {
            friends_unaffiliated,
            groups_unaffiliated,
            messages_unaffiliated,
            jobs_unaffiliated,
            mode_friends,
            mode_jobs,
            mode_messages,
            mode_groups
        };

        const result: {
            data: {
                companyDetailsUpdate: Response<Company>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_companyDetailsUpdate, inputParams))) as any;

        const ret = result.data.companyDetailsUpdate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting company details:', error);
    }
}

export async function financialStatementGet(): Promise<Response<FinancialStatement>> {
    try {
        const result: {
            data: {
                financialStatementGet: Response<FinancialStatement>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_financialStatementGet))) as any;

        const ret = result.data.financialStatementGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting financial statement:', error);
    }
}

export async function ellaFinancialDiscussionNext(
    input: string
): Promise<Response<OnboardingConversationElementWithFinancialStatement>> {
    try {
        const inputParams = {
            inputFromUser: input
        };

        const result: {
            data: {
                ellaFinancialDiscussionNext: Response<OnboardingConversationElementWithFinancialStatement>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_ellaFinancialDiscussionNext, inputParams))) as any;

        const ret = result.data.ellaFinancialDiscussionNext;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error talking to ella:', error);
    }
}

export async function financialStatementSimulationGet(): Promise<Response<FinancialStatementMonth[]>> {
    try {
        const result: {
            data: {
                financialStatementSimulationGet: Response<FinancialStatementMonth[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_financialStatementSimulationGet))) as any;

        const ret = result.data.financialStatementSimulationGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting simulation:', error);
    }
}

export async function promptListGet(): Promise<Response<Prompt[]>> {
    try {
        const result: {
            data: {
                promptListGet: Response<Prompt[]>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_promptListGet))) as any;

        const ret = result.data.promptListGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting prompts:', error);
    }
}

export async function promptGet(id: string): Promise<Response<Prompt>> {
    try {
        const result: {
            data: {
                promptGet: Response<Prompt>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(q_promptGet, { id }))) as any;

        const ret = result.data.promptGet;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting prompts:', error);
    }
}

export async function promptUpdate(id: string, content: string): Promise<Response<Prompt>> {
    try {
        const result: {
            data: {
                promptUpdate: Response<Prompt>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_promptUpdate, { id, content }))) as any;

        const ret = result.data.promptUpdate;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error getting prompts:', error);
    }
}

export async function userUpdateAndReset(archetype: string): Promise<Response<User>> {
    try {
        const result: {
            data: {
                userUpdateAndReset: Response<User>;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = (await API.graphql(graphqlOperation(m_userUpdateAndReset, { archetype }))) as any;

        const ret = result.data.userUpdateAndReset;

        if (ret.errors && ret.errors.length > 0) {
            console.error('GraphQL errors:', ret.errors);
            return { errors: ret.errors };
        } else {
            return { data: ret.data };
        }
    } catch (error) {
        console.error('Error updating user:', error);
    }
}
