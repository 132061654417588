import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { User } from 'src/graphql/types/User';
import { selectUser, setUser } from 'src/redux/features/user/userSlice';
import { updateMyUser } from 'src/graphql/GMAPI';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserProfileProps {}

const OnboardingProfile: React.FC<UserProfileProps> = () => {
    const user = useAppSelector(selectUser);
    const [state, setState] = useState<User>(user.value);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const dispatch = useAppDispatch(); // Use the hook here

    const changeLanguage = (lang: 'en_GB' | 'fr') => {
        setState((prevState) => ({ ...prevState, selectedLang: lang }));
    };

    const getUnicodeFlagIcon = (countryCode: string) => {
        switch (countryCode) {
            case 'GB':
                return '🇬🇧';
            case 'FR':
                return '🇫🇷';
            default:
                return '';
        }
    };

    const completeProfile = async () => {
        setLoading(true);
        const user = await updateMyUser(
            state.name,
            state.email,
            state.dob,
            state.target_retirement_date,
            state.selected_language
        );
        dispatch(setUser(user));

        navigate('/'); //Go to main page

        setLoading(false);
    };

    return (
        <Box
            sx={{
                width: '100vw', // Stretch the outer container to 100% of viewport width
                height: { xs: '100vh', sm: '100vh' },
                backgroundColor: 'grey', // Set a grey background color
                display: 'flex',
                justifyContent: 'center', // Center the form horizontally
                alignItems: 'center' // Center the form vertically, remove if not needed
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', sm: '50%' },
                    height: { xs: '100%', sm: '80vh' },
                    margin: '0 auto',
                    backgroundColor: 'white',
                    borderRadius: '8px'
                }}
            >
                <Grid container spacing={3} padding={3}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            align="center"
                            sx={{
                                marginBottom: 3, // Increased spacing below the text
                                marginTop: 2, // Some space above, to ensure it doesn't touch the top
                                fontWeight: 'medium', // Medium font weight for a balanced emphasis
                                color: '#777', // Darker text for better contrast against the grey background
                                lineHeight: 1.6, // Adjusting the line height for better readability
                                letterSpacing: '0.5px' // Slight letter spacing
                            }}
                        >
                            Thank you so much for telling me about yourself!
                            <br />
                            Please confirm a few details below, and then we can start exploring new and exciting
                            possibilities.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Full Name"
                            variant="outlined"
                            value={state.name}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    name: e.target.value
                                }))
                            }
                        />
                    </Grid>
                    DOB
                    <Grid item xs={12}>
                        <DatePicker
                            defaultValue={state && state.dob ? dayjs(state.dob) : null}
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    dob: e.toDate()
                                }))
                            }
                        />
                    </Grid>
                    Retirement Date
                    <Grid item xs={12}>
                        <DatePicker
                            defaultValue={
                                state && state.target_retirement_date ? dayjs(state.target_retirement_date) : null
                            }
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    target_retirement_date: e.toDate()
                                }))
                            }
                        />
                    </Grid>
                    <Grid container item xs={12} paddingTop={2} spacing={2}>
                        <Grid item>
                            <Button
                                variant={state.selected_language === 'en_GB' ? 'contained' : 'outlined'}
                                onClick={() => changeLanguage('en_GB')}
                            >
                                {getUnicodeFlagIcon('GB')} English
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={state.selected_language === 'fr' ? 'contained' : 'outlined'}
                                onClick={() => changeLanguage('fr')}
                            >
                                {getUnicodeFlagIcon('FR')} French
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end" padding={3}>
                    <Button variant="contained" color="primary" disabled={loading} onClick={completeProfile}>
                        Let&apos;s go!
                    </Button>
                </Grid>
            </Box>
        </Box>
    );
};

export default OnboardingProfile;
