import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en_GB: {
        translation: {
            'Welcome to Grey Matters': 'Welcome to Grey Matters',
            welcomeMessage: 'Welcome, {{name}}',
            goodDay: 'Today is a good day!',

            //Sidebar
            Messenger: 'Messenger',
            Home: 'Home',
            'Account Settings': 'Account Settings',
            Tools: 'Tools',
            Dashboards: 'Dashboards',
            Accounts: 'Accounts',
            'Grey Tasker': 'Grey Tasker',

            //Account Settings Page
            accountPageExplanation: 'This page allows you to change your account details.',
            languageSelection: 'Select your language',

            //Company Settings Page
            companySettingsPageExplanation:
                'This page allows Grey Matters Company Administrators to change settings that impact all users within an company on this platform.',

            //GreyTasker
            greyTaskerWelcome1:
                "Have you ever wondered if you can use the skills you've built from a lifetime of experience as you transition away from full-time work? Well, now you can!",
            greyTaskerWelcome2:
                "On the next page, we'll get you started by helping you build a skills profile based on what Ella has learned about you.  You can edit and add to your skills if she misses anything.",
            greyTaskerWriteProfile:
                "Let's get started writing your profile! I've written a first draft, which we can now work on by discussing it on the right",
            buttonLaunchGreyTasker: 'Launch Grey Tasker!',

            //Wealth Explorer
            greyTaskerCreateFinancial: "Let's make a start on understanding your financial position!",
            //Button titles
            updateButton: 'Update',
            getStartedButton: 'Get Started!'
        }
    },
    fr: {
        translation: {
            'Welcome to Greymatters': 'Bienvenue à Greymatters',
            welcomeMessage: 'Bienvenue, {{name}}',
            goodDay: "Aujourd'hui est un bon jour !",

            //Sidebar
            Messenger: 'Application de messagerie',
            Home: "Écran d'accueil",
            'Account Settings': 'Paramètres du compte',
            Tools: 'Outils',
            Dashboards: 'Tableaux de bord',
            Accounts: 'Comptes',
            'Grey Tasker': 'Mission Grisée',

            //Account Settings Page
            accountPageExplanation: 'Cette page vous permet de modifier les détails de votre compte.',
            languageSelection: 'Sélectionnez votre langue',

            //Buttons
            updateButton: 'Mettre à jour'
        }
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en_GB', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
