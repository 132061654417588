import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
    Container,
    Grid,
    Typography,
    Slider,
    FormGroup,
    FormControl,
    FormLabel,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Button
} from '@mui/material';
import Footer from 'src/components/Footer';
import { useTranslation } from 'react-i18next';
import { companyDetailsGet, companyDetailsUpdate } from '../../../../graphql/GMAPI';
import { Company } from '../../../../graphql/types/Company';

function CompanySettings() {
    const { t } = useTranslation();
    const [company, setCompany] = useState<Company | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getCompanyDetails() {
            setIsLoading(true);
            try {
                const companyDetails = await companyDetailsGet();
                setCompany(companyDetails.data);
            } catch (error) {
                console.error('Failed to fetch company details:', error);
            } finally {
                setIsLoading(false);
            }
        }

        getCompanyDetails();
    }, []);

    const saveSettings = async () => {
        if (company) {
            setIsLoading(true);
            const response = await companyDetailsUpdate(
                company.friends_unaffiliated,
                company.groups_unaffiliated,
                company.messages_unaffiliated,
                company.jobs_unaffiliated,
                company.mode_friends,
                company.mode_jobs,
                company.mode_messages,
                company.mode_groups
            );
            setIsLoading(false);
            if (response.errors) {
                console.error('Failed to update company details:', response.errors);
            } else {
                console.log('Company details updated successfully');
            }
        }
    };

    const handleChange = (
        event: Event,
        newValue: number | number[],
        field: 'mode_friends' | 'mode_groups' | 'model_messages'
    ) => {
        if (company) {
            setCompany({
                ...company,
                [field]: newValue === 0 ? 'LOCAL' : newValue === 100 ? 'GLOBAL' : 'SPECIFIED'
            });
        }
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        type: 'friends' | 'groups' | 'messages'
    ) => {
        const { checked } = event.target;
        if (company) {
            setCompany({
                ...company,
                [`${type}_unaffiliated`]: checked
            });
        }
    };

    const marks = [
        { value: 0, label: 'Local' },
        { value: 50, label: 'Specified' },
        { value: 100, label: 'Global' }
    ];

    function getModeDescription(
        mode: 'LOCAL' | 'GLOBAL' | 'SPECIFIED',
        type: 'friends' | 'groups' | 'messages',
        unaffiliated: boolean
    ) {
        let description = '';

        if (type === 'friends') {
            switch (mode) {
                case 'LOCAL':
                    description = 'Your employees will be able to find and add friends only within your company.';
                    break;
                case 'SPECIFIED':
                    description =
                        'Your employees will be able to add friends with other people in your company, and those specified below.';
                    break;
                case 'GLOBAL':
                    description =
                        'Your employees will be able to add friends with other people in your company, and those in other companies who have also specified the global option.';
                    break;
            }
        } else if (type === 'groups') {
            switch (mode) {
                case 'LOCAL':
                    description = 'Your employees will be able to join groups only within your company.';
                    break;
                case 'SPECIFIED':
                    description =
                        'Your employees will be able to join groups with other people in your company, and those specified below.';
                    break;
                case 'GLOBAL':
                    description =
                        'Your employees will be able to join groups with other people in your company, and those in other companies who have also specified the global option.';
                    break;
            }
        } else if (type === 'messages') {
            switch (mode) {
                case 'LOCAL':
                    description = 'Your employees will be able to send messages only within your company.';
                    break;
                case 'SPECIFIED':
                    description =
                        'Your employees will be able to send messages to other people in your company, and those specified below.';
                    break;
                case 'GLOBAL':
                    description =
                        'Your employees will be able to send messages to other people in your company, and those in other companies who have also specified the global option.';
                    break;
            }
        } else if (type === 'jobs') {
            switch (mode) {
                case 'LOCAL':
                    description = 'Your employees will be able to post and apply for jobs only within your company.';
                    break;
                case 'SPECIFIED':
                    description =
                        'Your employees will be able to post and apply for jobs in your company, and other companies specified below.';
                    break;
                case 'GLOBAL':
                    description =
                        'Your employees will be able to post and apply for jobs within your company, and to companies who have also specified the global option.';
                    break;
            }
        }

        if (unaffiliated) {
            description +=
                " They'll also be able to do this with people who have retired from their company, and are active on the platform as an individual.";
        }

        return description;
    }

    return (
        <>
            <Helmet>
                <title>Greymatters Company Settings</title>
            </Helmet>
            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>

            {isLoading ? (
                <CircularProgress />
            ) : (
                <Container maxWidth="lg">
                    <Typography variant="h5" gutterBottom>
                        {company?.name}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h6" gutterBottom>
                                {t('companySettingsPageExplanation')}
                            </Typography>
                        </Grid>
                        {['friends', 'groups', 'messages', 'jobs'].map((type) => (
                            <Grid item xs={12} key={type}>
                                <FormControl component="fieldset" fullWidth>
                                    <FormLabel component="legend" sx={{ padding: 5 }}>
                                        {type.charAt(0).toUpperCase() + type.slice(1)} Settings
                                    </FormLabel>
                                    <FormGroup>
                                        <Typography gutterBottom>
                                            {type.charAt(0).toUpperCase() + type.slice(1)}
                                        </Typography>
                                        <Slider
                                            defaultValue={
                                                company
                                                    ? company[`mode_${type}`] === 'GLOBAL'
                                                        ? 100
                                                        : company[`mode_${type}`] === 'SPECIFIED'
                                                        ? 50
                                                        : 0
                                                    : 0
                                            }
                                            step={null}
                                            marks={marks}
                                            onChange={(e, newValue) =>
                                                handleChange(
                                                    e,
                                                    newValue,
                                                    `mode_${type}` as 'mode_friends' | 'mode_groups' | 'model_messages'
                                                )
                                            }
                                            sx={{ width: '50%', paddingBottom: 2 }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={company ? company[`${type}_unaffiliated`] : false}
                                                    onChange={(e) =>
                                                        handleCheckboxChange(
                                                            e,
                                                            type as 'friends' | 'groups' | 'messages'
                                                        )
                                                    }
                                                    name={`${type}_unaffiliated`}
                                                />
                                            }
                                            label="Also enable between your employees and people on Greymatters that are no longer with a company"
                                        />
                                        <Typography variant="body2" color="textSecondary">
                                            {getModeDescription(
                                                company ? company[`mode_${type}`] : 'LOCAL',
                                                type as 'friends' | 'groups' | 'messages',
                                                company ? company[`${type}_unaffiliated`] : false
                                            )}
                                        </Typography>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        ))}

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={saveSettings}>
                                Save Settings
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            )}
            <Footer />
        </>
    );
}

export default CompanySettings;
