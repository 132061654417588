import React, { useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import styles from './index.module.css';

interface FlipCardProps {
    title: string;
    imageUrl: string;
    cardText: string;
    bgColor: string;
    flip: boolean;
}

const FlipCard: React.FC<FlipCardProps> = ({ title, imageUrl, cardText, bgColor, flip }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    return flip ? (
        <div
            className={`${styles.cardContainer} ${isFlipped ? styles.flipped : ''}`}
            onClick={() => setIsFlipped(!isFlipped)}
        >
            <Card className={`${styles.cardFace}`} sx={{ backgroundColor: bgColor }}>
                <CardContent>
                    <Typography sx={{ paddingBottom: 1 }}>{title}</Typography>
                    <img src={imageUrl} alt="Card Front" style={{ width: '100%', height: '100%' }} />
                </CardContent>
            </Card>
            <Card className={`${styles.cardFace} ${styles.cardBack}`}>
                <CardContent>
                    <Typography variant="h6">{cardText}</Typography>
                </CardContent>
            </Card>
        </div>
    ) : (
        <div className={`${styles.cardContainer}`}>
            <Card className={`${styles.cardFace}`} sx={{ backgroundColor: bgColor }}>
                <CardContent>
                    <Typography sx={{ paddingBottom: 1 }}>{title}</Typography>
                    <img src={imageUrl} alt="Card Front" style={{ width: '100%', height: '100%' }} />
                    <Typography fontSize={'0.9em'}>{cardText}</Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default FlipCard;
