import React from 'react';
import { Box } from '@mui/material';
import Masonry from '@mui/lab/Masonry';

import NavBar from './newSections/NavBar';

// new sections
import Health from './newSections/Health';
import Wealth from './newSections/Wealth';
import Profile from './newSections/Profile';
import Ella from './newSections/Ella';
import Work from './newSections/Work';
import Social from './newSections/Social';
import Explore from './newSections/Explore';
import Learn from './newSections/Learn';

const widgets = [
    Health('#a6bcff'),
    Wealth('#c2c2c2'),
    Profile(),
    Work('#F0D8A7'),
    Ella(),
    Social('#FF738A'),
    Explore('#fef4ed'),
    Learn('#4bffdf')
];

function Dashboard() {
    return (
        <>
            <Box bgcolor="rgb(27,28,35)" px={4}>
                <NavBar />
                <Masonry
                    columns={3}
                    spacing={2}
                    style={{ backgroundColor: 'rgb(27,28,35)', width: 'auto', padding: '0 36px' }}
                >
                    {widgets.map((widget, index) => (
                        <div key={index}>{widget}</div>
                    ))}
                </Masonry>
            </Box>
        </>
    );
}

export default Dashboard;
