import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Suitcase from 'src/assets/svg/icons/Suitcase';

import { PercentageBar, CardHeader, List } from '../components/Components';

export default function Work(cardColor: string) {
    return (
        <>
            <Card style={{ backgroundColor: 'rgba(255,255,255,0.1)', padding: '16px' }} elevation={0}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                    <CardHeader>
                        <Typography variant="h1" color={cardColor} fontWeight="400">
                            Work
                        </Typography>
                        <Suitcase />
                    </CardHeader>
                    <PercentageBar label="Work plan completion" value={85} color={cardColor} />
                    <List
                        title="My Work Ambitions"
                        listItems={[
                            '1. Transition from 5 days to 3 days by Nov 2025',
                            '2. Get a Non-Executive Board position',
                            '3. Volunteer at a local school'
                        ]}
                    />
                </CardContent>
            </Card>
        </>
    );
}
