import React, { useState } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import EllaChat from '../EllaChat';
import logoVideo from 'src/assets/vid/ELLA.mp4';

const HoverButton = styled(Button)(() => ({
    '&.MuiButton-contained': {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: 'grey'
        }
    },
    width: '30px',
    height: '40px',
    '@media (min-width:768px)': {
        width: '50px',
        height: '50px'
    },
    '&:hover': {
        backgroundColor: 'grey'
    }
}));

export const EllaVideoContainer = styled('video')({
    width: '20px',
    height: '30px',
    '@media (min-width:768px)': {
        width: '30px',
        height: '30px'
    }
});

const EllaButton = () => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const onClose = () => {
        setOpen(false);
    };

    return !isOpen ? (
        <HoverButton variant="contained" style={{ margin: '20px' }} onClick={() => setOpen(!isOpen)}>
            <EllaVideoContainer autoPlay muted playsInline loop>
                <source src={logoVideo} type="video/mp4" />
            </EllaVideoContainer>
        </HoverButton>
    ) : (
        <EllaChat open={isOpen} onClose={onClose} />
    );
};

export default EllaButton;
