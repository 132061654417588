import React, { useContext, useEffect, useState } from 'react';

import { ListSubheader, alpha, Box, List, styled, Button, ListItem, Badge } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';

import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import PeopleIcon from '@mui/icons-material/People';

import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { selectMessages } from '../../../../redux/features/user/messageSlice';
import { selectUser } from '../../../../redux/features/user/userSlice';

const MenuWrapper = styled(Box)(
    ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
    ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
    const { closeSidebar } = useContext(SidebarContext);

    const openLearning = () => {
        //TODO - store url somewhre, make
        window.location.href = 'https://lms-dev.greymatters.com.au/moodle/auth/mo_saml/index.php';
    };

    const { t } = useTranslation();

    const messages = useAppSelector(selectMessages);
    const myUser = useAppSelector(selectUser).value;
    const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false);

    useEffect(() => {
        console.log('Contents of messages: ' + JSON.stringify(messages));

        let hasUnread = false;
        if (messages && messages.value) {
            for (const message of messages.value) {
                if (message.user_message?.seen === false) {
                    hasUnread = true;
                }
            }
        }
        setHasUnreadMessages(hasUnread);
    }, [messages]);

    return (
        <>
            <MenuWrapper>
                <List component="div">
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/overview"
                                    startIcon={<DesignServicesTwoToneIcon />}
                                >
                                    {t('Home')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            {t('Dashboards')}
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/dashboard-new"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Home')}
                                </Button>
                            </ListItem>
                            <Badge
                                color="primary"
                                variant="dot"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                invisible={!hasUnreadMessages}
                            >
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to="/dashboards/messenger"
                                        startIcon={<MmsTwoToneIcon />}
                                    >
                                        {t('Messenger')}
                                    </Button>
                                </ListItem>
                            </Badge>
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/dashboards/friends"
                                    startIcon={<PeopleIcon />}
                                >
                                    {t('Friends')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            Health
                        </ListSubheader>
                    }
                ></List>
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            Wealth
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/wealth/explorer"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Wealth Explorer')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            Clubs
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/group/home"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('List')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>

                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            Learning
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={openLearning}
                                    to="/learning"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Start Learning..')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>

                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            {t('Grey Tasker')}
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/greytasker"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Home')}
                                </Button>
                            </ListItem>

                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/greytasker/applications"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('My Task Applications')}
                                </Button>
                            </ListItem>

                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/greytasker/selected"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('My Tasks')}
                                </Button>
                            </ListItem>

                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/greytasker/postings"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('My Posted Tasks')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            {t('Re-Purpose Profiling©')}
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/quiz"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Quizzes')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            {t('Statistics')}
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/statistics"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Home')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            {t('Accounts')}
                        </ListSubheader>
                    }
                >
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/management/profile/settings"
                                    startIcon={<AccountCircleTwoToneIcon />}
                                >
                                    {t('Account Settings')}
                                </Button>
                            </ListItem>

                            {myUser.type == 'COMPANY_ADMIN' && (
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to="/management/company/settings"
                                        startIcon={<AccountCircleTwoToneIcon />}
                                    >
                                        {t('Company Settings')}
                                    </Button>
                                </ListItem>
                            )}

                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/debug"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Debugging')}
                                </Button>
                            </ListItem>
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/prompts"
                                    startIcon={<TableChartTwoToneIcon />}
                                >
                                    {t('Prompts')}
                                </Button>
                            </ListItem>
                        </List>
                    </SubMenuWrapper>
                </List>
            </MenuWrapper>
        </>
    );
}

export default SidebarMenu;
