import * as React from 'react';
import PropTypes from 'prop-types';

function SvgComponent(props) {
    const strokeColor = props.stroke || 'white';
    const paddingTop = props.paddingTop || '0px';
    const width = props.width || '32';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={width}
            viewBox="0 0 32 32"
            fill="none"
            style={{ paddingTop }}
        >
            <path d="M5 16H27" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M18 7L27 16L18 25"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

SvgComponent.propTypes = {
    stroke: PropTypes.string, // Expect a string for stroke color
    paddingTop: PropTypes.string, // Expect a string for padding (include units if needed)
    width: PropTypes.oneOfType([
        // Expect either a string or a number for width
        PropTypes.string,
        PropTypes.number
    ])
};

SvgComponent.defaultProps = {
    // Optionally set default values
    stroke: 'white',
    paddingTop: '0px',
    width: '32'
};

export default SvgComponent;
