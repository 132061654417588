import React, { ReactNode, createContext, useContext, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import type { GraphQLSubscription } from '@aws-amplify/api';
import { OnNewMessage } from 'src/graphql/types/UserMessage';
import { q_newMessage } from 'src/graphql/subscriptions';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { addUnreadMessage } from 'src/redux/features/user/messageSlice';
import { selectUser } from 'src/redux/features/user/userSlice';

const MessageSubscriptionContext = createContext({});

export const useMessageSubscription = () => useContext(MessageSubscriptionContext);

interface MessageSubscriptionProviderProps {
    children: ReactNode;
}

export const MessageSubscriptionProvider: React.FC<MessageSubscriptionProviderProps> = ({ children }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    useEffect(() => {
        if (user.value) {
            const subscription = API.graphql<GraphQLSubscription<OnNewMessage>>(
                graphqlOperation(q_newMessage, { to_user_id: user.value?.id })
            ).subscribe({
                next: ({ value }) => {
                    dispatch(
                        addUnreadMessage({
                            type: 'USER_MESSAGE',
                            user_message: value.data.newMessage
                        })
                    );
                }
            });

            return () => {
                subscription.unsubscribe();
            };
        }
    }, [user, dispatch]);

    return <MessageSubscriptionContext.Provider value={{}}>{children}</MessageSubscriptionContext.Provider>;
};
