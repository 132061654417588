import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography } from '@mui/material';
import Footer from 'src/components/Footer';
import React, { useEffect, useState } from 'react';
import { getOnboardingMemories } from 'src/graphql/GMAPI';
import Spinner from 'src/components/Spinner';

function AccountSettings() {
    const [memories, setMemories] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetching onboarding memories when the component mounts
        async function fetchMemories() {
            setIsLoading(true);
            const fetchedMemories = await getOnboardingMemories();
            setMemories(fetchedMemories);
            setIsLoading(false);
        }

        fetchMemories();
    }, []);

    return (
        <>
            <Helmet>
                <title>Greymatters Admin Debugging</title>
            </Helmet>

            {isLoading && <Spinner />}

            <Container maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}>
                        <Typography variant="h3" component="h2" gutterBottom>
                            Debugging Utilities
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingTop={10}>
                        <Typography variant="h4" component="h2" gutterBottom>
                            Onboarding Memories
                        </Typography>
                    </Grid>
                    {/* Render each memory in a new grid item */}
                    {memories.map((memory, index) => (
                        <Grid item xs={12} key={index}>
                            <Typography variant="body1">{memory.contents}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>
    );
}

export default AccountSettings;
