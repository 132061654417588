import React, { useState, createContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from '@mui/material';
import { themeCreator } from './base';

// Create a context for the theme
export const ThemeContext = createContext(
    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    (themeName: string): void => {}
);

interface ThemeProviderWrapperProps {
    children: React.ReactNode;
}

const ThemeProviderWrapper = ({ children }: ThemeProviderWrapperProps) => {
    const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
    const [themeName, _setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName); // Make sure themeCreator returns a styled-components compatible theme object

    console.log('Theme after createTheme: ', theme);

    const setThemeName = (themeName: string): void => {
        localStorage.setItem('appTheme', themeName);
        _setThemeName(themeName);
    };

    return (
        <ThemeContext.Provider value={setThemeName}>
            <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeProviderWrapper;
