import { Box, Grid } from '@mui/material';
import HeaderNotifications from './Notifications';
import React from 'react';
import EllaButton from '../../../../components/EllaButton';
import { CurrencyBox } from './CurrencyBox';

function HeaderButtons() {
    return (
        <>
            <CurrencyBox />

            <Box sx={{ mr: 1 }}>
                {/* <HeaderSearch /> */}

                <Grid display="flex" flexDirection="row">
                    <HeaderNotifications />
                    <EllaButton />
                </Grid>
            </Box>
        </>
    );
}

export default HeaderButtons;
