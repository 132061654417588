import * as React from 'react';

function SvgComponent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path
                d="M30 10.5H6C4.34315 10.5 3 11.8431 3 13.5V28.5C3 30.1569 4.34315 31.5 6 31.5H30C31.6569 31.5 33 30.1569 33 28.5V13.5C33 11.8431 31.6569 10.5 30 10.5Z"
                stroke="#F0D8A7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24 31.5V7.5C24 6.70435 23.6839 5.94129 23.1213 5.37868C22.5587 4.81607 21.7956 4.5 21 4.5H15C14.2044 4.5 13.4413 4.81607 12.8787 5.37868C12.3161 5.94129 12 6.70435 12 7.5V31.5"
                stroke="#F0D8A7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgComponent;
