import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box
} from '@mui/material';
import Footer from 'src/components/Footer';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/features/user/userSlice';
import { useTranslation } from 'react-i18next';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import React, { useState } from 'react';
import { updateMyUser, userUpdateAndReset } from 'src/graphql/GMAPI';
import Spinner from 'src/components/Spinner';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function AccountSettings() {
    const user = useSelector(selectUser).value;

    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(i18n.language);
    const [selectedArchetype, setSelectedArchetype] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isArchetypeLoading, setIsArchetypeLoading] = useState(false);

    const archetypeMap: Record<string, string> = {
        'Creative Visionary': 'cv',
        'Lifelong Learner': 'll',
        'Passionate Explorer': 'pe',
        'Community Carer': 'cc'
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setSelectedLang(lng);
    };

    const handleUpdateClick = async () => {
        setIsDisabled(true);
        setIsLoading(true);

        try {
            await updateMyUser(user.name, user.email, user.dob, user.target_retirement_date, selectedLang);
            window.location.reload();
        } catch (error) {
            console.error('Error updating user:', error);
        } finally {
            setIsLoading(false);
            setIsDisabled(false);
        }
    };

    const handleArchetypeUpdateClick = async () => {
        setIsArchetypeLoading(true);
        setIsDisabled(true);

        try {
            if (selectedArchetype) {
                const archetypeCode = archetypeMap[selectedArchetype];
                await userUpdateAndReset(archetypeCode);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error updating archetype:', error);
        } finally {
            setIsArchetypeLoading(false);
            setIsDisabled(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Greymatters Account Settings</title>
            </Helmet>
            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>

            {(isLoading || isArchetypeLoading) && <Spinner />}

            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    {/* Left Column */}
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography variant="h3" component="h2" gutterBottom>
                                {t('accountPageExplanation')}
                            </Typography>
                        </Box>
                        <Box paddingTop={4}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                Your Details
                            </Typography>
                        </Box>
                        <Box paddingTop={2}>
                            <TextField id="name-field" label="Your Name" variant="filled" defaultValue={user.name} />
                        </Box>
                        <Box paddingTop={2}>
                            <TextField
                                id="email-field"
                                label="Your Email Address"
                                variant="filled"
                                defaultValue={user.email}
                            />
                        </Box>
                        <Box paddingTop={2}>
                            <DatePicker label="Date of Birth" defaultValue={dayjs(user.dob)} />
                        </Box>
                        <Box paddingTop={4}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                {t('languageSelection')}
                            </Typography>
                        </Box>
                        <Grid container spacing={2} paddingTop={2}>
                            <Grid item>
                                <Button
                                    variant={selectedLang === 'en_GB' ? 'contained' : 'outlined'}
                                    onClick={() => changeLanguage('en_GB')}
                                >
                                    {getUnicodeFlagIcon('GB')} English
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={selectedLang === 'fr' ? 'contained' : 'outlined'}
                                    onClick={() => changeLanguage('fr')}
                                >
                                    {getUnicodeFlagIcon('FR')} French
                                </Button>
                            </Grid>
                        </Grid>
                        <Box paddingTop={10}>
                            <Button variant="contained" disabled={isDisabled} onClick={handleUpdateClick}>
                                {t('updateButton')}
                            </Button>
                        </Box>
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={12} md={6}>
                        <Box paddingTop={10}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                {t('archetypeSelection')}
                            </Typography>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="archetype-label">Select Your Archetype</InputLabel>
                                <Select
                                    labelId="archetype-label"
                                    id="archetype-select"
                                    value={selectedArchetype}
                                    onChange={(e) => setSelectedArchetype(e.target.value as string)}
                                    disabled={isDisabled}
                                >
                                    <MenuItem value="Creative Visionary">Creative Visionary</MenuItem>
                                    <MenuItem value="Lifelong Learner">Lifelong Learner</MenuItem>
                                    <MenuItem value="Passionate Explorer">Passionate Explorer</MenuItem>
                                    <MenuItem value="Community Carer">Community Carer</MenuItem>
                                </Select>
                            </FormControl>
                            <Box paddingTop={10}>
                                <Button
                                    variant="contained"
                                    disabled={isDisabled || !selectedArchetype}
                                    onClick={handleArchetypeUpdateClick}
                                    fullWidth
                                >
                                    {t('updateArchetypeButton')}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
}

export default AccountSettings;
