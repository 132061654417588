import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { groupDetailsUpdate, groupGet, groupJoin, groupLeave, groupPost } from '../../../../../graphql/GMAPI';
import {
    CircularProgress,
    Container,
    Grid,
    Typography,
    Button,
    TextField,
    Box,
    Dialog,
    DialogContent,
    CardContent,
    Card,
    Avatar,
    Stack,
    Tooltip
} from '@mui/material';
import { DeltaStatic } from 'quill';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Group } from 'src/graphql/types/Group';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import { selectUser } from 'src/redux/features/user/userSlice';
import { useSelector } from 'react-redux';
import { PostCard } from 'src/components/GroupPost/PostCard';

function GroupInstanceDisplay() {
    const [isLoading, setIsLoading] = useState(true);
    const [group, setGroup] = useState<Group | null>(null);
    const [editName, setEditName] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [editedDescription, setEditedDescription] = useState<string | DeltaStatic>();
    const quillRef = useRef(null);
    const newPostQuillRef = useRef(null);
    const [newPostContent, setNewPostContent] = useState('');
    const [toolbarClass, setToolbarClass] = useState('toolbar-hidden');
    const [newPostTitle, setNewPostTitle] = useState(''); // State for new post title
    const [newPostTitleError, setNewPostTitleError] = useState('');
    const [newPostContentError, setNewPostContentError] = useState('');
    const [createPostOpenDialog, setCreatePostOpenDialog] = useState<boolean>(false);
    const navigate = useNavigate();
    const myUser = useSelector(selectUser).value;

    const { id, postId } = useParams<string>();

    useEffect(() => {
        setToolbarClass(editDescription ? 'toolbar-visible' : 'toolbar-hidden');
    }, [editDescription]);

    useEffect(() => {
        async function loadGroup(groupId: string) {
            const groupGetResponse = await groupGet(groupId);

            if (groupGetResponse.data) {
                setGroup(groupGetResponse.data);
                setEditedName(groupGetResponse.data.name);
                setEditedDescription(groupGetResponse.data.description);
                setEditDescription(false);
            }
            setIsLoading(false);

            if (postId) {
                const element =
                    document.getElementById(`post-${postId}`) || document.getElementById(`comment-${postId}`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        }

        if (id) {
            loadGroup(id);
        }
    }, [id]);

    const handleJoinLeaveClick = async () => {
        if (group?.isUserMember) {
            const leaveResponse = await groupLeave(id);
            if (leaveResponse.data?.hasUserLeft) {
                setGroup((prevGroup) => (prevGroup ? { ...prevGroup, isUserMember: false } : null));
            }
        } else {
            const joinResponse = await groupJoin(id);
            if (joinResponse.data?.id) {
                setGroup((prevGroup) => (prevGroup ? { ...prevGroup, isUserMember: true } : null));
            }
        }
    };

    const handleEditName = () => {
        setEditName(true);
    };

    const handleCancelEditName = () => {
        setEditName(false);
        setEditedName(group?.name || '');
    };

    const handleSaveName = async () => {
        if (!id || editedName.trim() === '') return;

        const updateResponse = await groupDetailsUpdate(id, editedName, JSON.stringify(group?.description));
        if (updateResponse.data) {
            setGroup((prevGroup) => {
                return {
                    ...prevGroup,
                    name: editedName
                };
            });
            setEditName(false);
        }
    };

    const handleEditDescription = () => {
        setEditDescription(true);
    };

    const handleCancelEditDescription = () => {
        setEditDescription(false);
        setEditedDescription(group?.description || '');
    };

    const handleSaveDescription = async () => {
        if (!id) return;

        // Convert editedDescription to a string if it's not already.
        const descriptionAsString = JSON.stringify(editedDescription);

        const updateResponse = await groupDetailsUpdate(id, group?.name || '', descriptionAsString);
        if (updateResponse.data) {
            setEditDescription(false);
        }
    };

    const handleDescriptionChange = (content, delta, source, editor) => {
        // Update the editedDescription state with the latest Delta
        setEditedDescription(editor.getContents());
    };

    const handleNewPostChange = (content, delta, source, editor) => {
        setNewPostContent(editor.getContents());
    };

    const handleCreateNewEvent = async () => {
        navigate(`/group/instance/${group.id}/event-create`);
    };

    const handlePostNewPost = async () => {
        setNewPostTitleError('');
        setNewPostContentError('');

        let hasError = false;
        if (newPostTitle.trim() === '') {
            setNewPostTitleError('Title cannot be empty.');
            hasError = true;
        }

        if (hasError) {
            return;
        }

        console.log('New post contents: ' + JSON.stringify(newPostContent));

        const postResponse = await groupPost(id, newPostTitle, JSON.stringify(newPostContent), null);
        if (postResponse.data) {
            setGroup((prevGroup) => {
                if (prevGroup) {
                    return {
                        ...prevGroup,
                        posts: [postResponse.data, ...prevGroup.posts]
                    };
                }
                return prevGroup;
            });
            setNewPostTitle('');
            setNewPostContent('');
            setCreatePostOpenDialog(false);

            // Update UI to show the new post or handle success
            console.log('New post created:', postResponse.data);
        } else if (postResponse.errors) {
            // Handle errors
            console.log('Error creating post:', postResponse.errors);
        }
        // Clear the new post title and content after posting
        setNewPostTitle('');
        setNewPostContent('');
    };

    const postDisabledCheck = newPostTitle.length < 3 || newPostQuillRef.current.getEditor().getLength() < 2;

    return (
        <>
            <Helmet>
                <title>{editName ? 'Edit ' : ''}Group</title>
            </Helmet>

            {isLoading ? (
                <CircularProgress />
            ) : (
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            {group?.isUserAdmin && (
                                <Grid
                                    item
                                    paddingTop={1}
                                    xs={3}
                                    lg={3}
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                >
                                    <Button variant="contained" color="primary" onClick={handleCreateNewEvent}>
                                        Create New Event
                                    </Button>
                                </Grid>
                            )}
                            <Grid container spacing={2} alignItems="flex-start" justifyContent="left" paddingTop={0}>
                                <Grid item xs={12} lg={12}>
                                    {editName ? (
                                        <div>
                                            <TextField
                                                value={editedName}
                                                onChange={(e) => setEditedName(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <Button onClick={handleSaveName}>
                                                <SaveIcon />
                                            </Button>
                                            <Button onClick={handleCancelEditName}>
                                                <CancelIcon />
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            <Box display="flex" flexDirection="row">
                                                <Typography variant="h2">{group?.name}</Typography>

                                                {group?.isUserAdmin && (
                                                    <Button onClick={handleEditName}>
                                                        <EditIcon fontSize="small" />
                                                    </Button>
                                                )}
                                            </Box>
                                            {group && !group?.isUserAdmin && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleJoinLeaveClick}
                                                >
                                                    {group.isUserMember ? 'Leave Group' : 'Join Group'}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                    <Typography fontSize="0.7rem">
                                        {group.memberCount}{' '}
                                        {group.memberCount == 0 || group.memberCount > 1 ? 'members' : 'member'}
                                    </Typography>
                                </Grid>
                                <Stack direction="row" spacing={-2} paddingLeft={2}>
                                    {group?.members.map((member, index) => (
                                        <Tooltip title={member.member.name} key={index}>
                                            <Link
                                                to={`/user/profile/${member.member.id}`}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Avatar
                                                    alt={member.member.name}
                                                    src={member.member.avatar_url}
                                                    sx={{ border: '2px solid white', cursor: 'pointer' }} // Added cursor style for better UX
                                                    style={{
                                                        // marginLeft: '-15px',
                                                        zIndex: group.members.length - index, // Ensures the leftmost avatars are on top
                                                        width: 40, // Adjust the size as needed
                                                        height: 40 // Adjust the size as needed
                                                    }}
                                                />
                                            </Link>
                                        </Tooltip>
                                    ))}
                                    {group?.memberCount > 10 && <Avatar>...</Avatar>}
                                </Stack>
                                <Grid item xs={12} lg={12}>
                                    <Box display="flex" flexDirection="row">
                                        <div className={`quill-editor-container ${toolbarClass}`}>
                                            <ReactQuill
                                                ref={quillRef}
                                                value={editedDescription || ''}
                                                onChange={handleDescriptionChange}
                                                readOnly={!editDescription}
                                                theme={'snow'}
                                            />
                                        </div>

                                        {group?.isUserAdmin && !editDescription && (
                                            <Button onClick={handleEditDescription}>
                                                <EditIcon fontSize="small" />
                                            </Button>
                                        )}
                                        {editDescription && (
                                            <>
                                                <Button onClick={handleSaveDescription}>
                                                    <SaveIcon />
                                                </Button>
                                                <Button onClick={handleCancelEditDescription}>
                                                    <CancelIcon />
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Grid container spacing={2} alignItems="center" justifyContent="center" paddingTop={2}>
                        {/* New Post Box now comes here, at the top */}

                        {group?.isUserMember ? (
                            <>
                                <Grid display={'flex'} justifyContent="center" item xs={12} lg={12}>
                                    <Grid item display="flex" flexDirection="column" xs={12} lg={6}>
                                        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
                                            <CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => setCreatePostOpenDialog(true)}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            borderRadius: '25px',
                                                            backgroundColor: '#f0f2f5',
                                                            // padding: '5px 5px',
                                                            maxWidth: 500, // Match the maxWidth of your TextField
                                                            textTransform: 'none', // Prevents the button from capitalizing content
                                                            '&:hover': {
                                                                backgroundColor: '#e0e3e7' // Slightly darker on hover
                                                            }
                                                        }}
                                                    >
                                                        <Avatar
                                                            sx={{ width: 40, height: 40, marginRight: 2 }}
                                                            src={myUser.avatar_url}
                                                        />
                                                        <Typography variant="body1" color="gray">
                                                            Write to the group...
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Dialog
                                    open={createPostOpenDialog}
                                    onClose={() => {
                                        setCreatePostOpenDialog(false);
                                    }}
                                >
                                    <DialogContent>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Box>
                                                    <Typography variant="h6">Create a new post</Typography>
                                                    <TextField
                                                        error={!!newPostTitleError}
                                                        helperText={newPostTitleError}
                                                        label="Title"
                                                        value={newPostTitle}
                                                        onChange={(e) => setNewPostTitle(e.target.value)}
                                                        fullWidth
                                                    />
                                                    <ReactQuill
                                                        ref={newPostQuillRef}
                                                        value={newPostContent}
                                                        onChange={handleNewPostChange}
                                                        modules={{ toolbar: true }}
                                                        style={{ height: '5em' }}
                                                        theme="snow"
                                                    />
                                                    {newPostContentError && (
                                                        <Typography color="error">{newPostContentError}</Typography>
                                                    )}
                                                    <Box pt={5}>
                                                        <Button
                                                            disabled={postDisabledCheck}
                                                            onClick={handlePostNewPost}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Post
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* Posts Section */}
                        <Grid item xs={12} lg={6}>
                            {group?.posts.map((post) => (
                                <PostCard
                                    navigatePostId={postId}
                                    key={post.id}
                                    myUser={myUser}
                                    inPost={post}
                                    groupId={group.id}
                                    isUserMember={group.isUserMember}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Container>
            )}

            <Footer />
        </>
    );
}

export default GroupInstanceDisplay;
