import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    useTheme,
    useMediaQuery,
    TextField,
    InputAdornment,
    ButtonGroup,
    Slider
} from '@mui/material';
import sparkleVideo from 'src/assets/vid/sparkle.mp4';
import testAudio from 'src/assets/audio/test-audio.mp3';
import ellaSpeaking from 'src/assets/img/ella-speaking.jpg';
import styles from './Onboarding.module.css';
import Logo from '../../../../components/LogoSign';
import MicIcon from '@mui/icons-material/Mic';
import Create from '@mui/icons-material/Create';
import { VADContext } from 'src/components/onboarding/VADProvider';
import { VoiceMode, selectSpeechState, setMicOn, setVoiceMode } from 'src/redux/features/vad/vadReducer';
import { useAppDispatch } from '../../../../redux/hooks';
import { useAppSelector } from '../../../..';
import { utils } from '../../../../components/onboarding/CustomVAD';
import { getNextConversationElement, onboardingSpeechTranscribe } from '../../../../graphql/GMAPI';
import { addConversationItem } from '../../../../redux/features/onboarding/onboardingConversationSlice';
import { setUserStatus } from '../../../../redux/features/user/userSlice';
import { useNavigate } from 'react-router-dom';

type CurrentInteractionType = 'IDLE' | 'THINKING' | 'PLAYING' | 'USER_SPEAKING' | 'COMPLETE';
type SelectedInteractionMode = 'TYPE' | 'SPEAK';

// Define a type for the component's props
interface EllaResponseComponentProps {
    responseValue: string;
}

const EllaResponseComponent: React.FC<EllaResponseComponentProps> = ({ responseValue }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Assuming a line-height of 1.5 for the Typography component
    const lineHeight = 1.5; // Adjust as per your theme's settings
    const maxLines = 4;
    const padding = isMobile ? theme.spacing(1) : theme.spacing(3);
    const maxHeight = `${lineHeight * maxLines}em`; // Adjust 'em' based on your font settings

    return (
        <Box display="flex" justifyContent="center" alignItems="center" padding={isMobile ? 1 : 3}>
            <Typography
                bgcolor={'#1D1F27'}
                width={isMobile ? '100%' : '80%'}
                padding={padding}
                borderRadius={'16px'}
                style={{
                    maxHeight: maxHeight,
                    overflowY: 'auto',
                    lineHeight: `${lineHeight}em`
                }}
            >
                {responseValue}
            </Typography>
        </Box>
    );
};

const OnboardingMainComponent: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentInteraction, setCurrentInteraction] = useState<CurrentInteractionType>('IDLE');
    const [interactionMode, setInteractionMode] = useState<SelectedInteractionMode>('TYPE');
    const analyserRef = useRef<AnalyserNode | null>(null);
    const vadContext = useContext(VADContext);
    const speechState = useAppSelector(selectSpeechState);
    const [registered, setRegistered] = useState<boolean>(false);
    const interactionModeRef = useRef<SelectedInteractionMode>(interactionMode);
    const [inputValue, setInputValue] = useState<string>();
    const [percentComplete, setPercentComplete] = useState<number>(0);
    const percentCompleteRef = useRef<number>(0);
    const navigate = useNavigate();
    const [responseValue, setResponseValue] = useState<string>(
        "Hi there, my name is Ella. I'm here as your personal guide to Grey Matters as you begin your journey. You can talk to me by typing text in the box at the bottom, or by clicking the microphone icon to the right and using your voice. I'd love to get to know you better - can you tell me a bit about yourself?"
    );

    const [dataArray, setDataArray] = useState<Uint8Array>(null); //useState<Uint8Array | null>(null);
    const [opacity, setOpacity] = useState(1); // Start with an opacity of 0

    const dispatch = useAppDispatch();

    useEffect(() => {
        playSound();
    }, []);

    useEffect(() => {
        if (vadContext && !registered) {
            vadContext.registerCallback('childComponent', (data) => {
                console.log('Received data in child component:', data);
                onSpeechEnd(data);
            });
            setRegistered(true);
        } else {
            console.error('No vadContext on component init');
        }
    }, [registered]);

    useEffect(() => {
        interactionModeRef.current = interactionMode;
    }, [interactionMode]);

    useEffect(() => {
        percentCompleteRef.current = percentComplete;
    }, [percentComplete]);

    useEffect(() => {
        if (vadContext) {
            // console.log('Data array is: ' + vadContext.dataArray + ' in onboarding');
            if (currentInteraction == 'USER_SPEAKING') {
                console.log('Updating data array because user is speaking..');
                setDataArray(vadContext.dataArray);
            }
        }
    }, [vadContext, currentInteraction]);

    useEffect(() => {
        console.log('Speech state changed to : ' + speechState + ' in onboarding');
        if (speechState) {
            setCurrentInteraction('USER_SPEAKING');
        } else if (currentInteraction == 'USER_SPEAKING') {
            setResponseValue('');
            setCurrentInteraction('THINKING');
        }
    }, [speechState]);

    //Draw a waveform based on the output of the analyser (from Ella playback)
    useEffect(() => {
        const drawWaveform = () => {
            if (!dataArray) {
                console.log('Data array is: ', dataArray);
                console.log('analyserRef is: ', analyserRef.current);
                return;
            }

            if (analyserRef && analyserRef.current) {
                analyserRef.current.getByteTimeDomainData(dataArray);
            }

            const interpolateColor = (color1, color2, factor) => {
                const result = color1
                    .slice(1)
                    .match(/.{2}/g)
                    .map((hex, index) => {
                        const color1Component = parseInt(hex, 16);
                        const color2Component = parseInt(color2.slice(1).match(/.{2}/g)[index], 16);
                        const blendedComponent = Math.round(
                            color1Component + (color2Component - color1Component) * factor
                        )
                            .toString(16)
                            .padStart(2, '0');
                        return blendedComponent;
                    })
                    .join('');
                return `#${result}`;
            };

            const svgNS = 'http://www.w3.org/2000/svg';
            const waveform = document.getElementById('waveform');
            if (waveform) {
                waveform.innerHTML = '';

                for (let i = 0; i < dataArray.length; i++) {
                    const normData = dataArray[i] / 128.0 - 1.0;
                    const color = interpolateColor('#FFC0CB', '#800080', i / dataArray.length);

                    const line = document.createElementNS(svgNS, 'line');
                    line.setAttribute('x1', `${(i / dataArray.length) * 500}`);
                    line.setAttribute('y1', '50');
                    line.setAttribute('x2', `${(i / dataArray.length) * 500}`);
                    line.setAttribute('y2', `${50 + normData * 200}`);
                    line.setAttribute('stroke', color);
                    waveform.appendChild(line);
                }

                requestAnimationFrame(drawWaveform);
            } else {
                console.log('No waveform svg..');
            }
        };

        if (dataArray) {
            console.log('Drawing waveform..');
            drawWaveform();
        }
    }, [dataArray]);

    //Fade-in reveal of ELLA animation after thinking cycle.
    useEffect(() => {
        if (currentInteraction == 'THINKING') {
            setOpacity(1);
        } else {
            setOpacity(0);
        }
    }, [currentInteraction]);

    useEffect(() => {
        console.log('Interaction mode changed to: ' + interactionMode);
        if (interactionMode == 'SPEAK') {
            dispatch(setMicOn(true));
        } else {
            dispatch(setMicOn(false));
        }
    }, [interactionMode]);

    const playSound = () => {
        fetch(testAudio)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = (reader.result as string).split(',')[1]; // Remove the data URL part
                    playAudio(base64data);
                };
            })
            .catch((error) => console.error('Error fetching audio file:', error));
    };

    const sendTextResponse = async () => {
        if (inputValue.length > 0) {
            setResponseValue('');
            const input = inputValue;
            setInputValue('');

            setCurrentInteraction('THINKING');

            const response = await getNextConversationElement(input);

            setCurrentInteraction('IDLE');

            setInputValue(response.userInput);
            setResponseValue(response.contents);
            setPercentComplete(response.percent);

            if (response.percent == 100) {
                dispatch(setUserStatus('SETUP_PROFILE'));
                setCurrentInteraction('COMPLETE');
            }
        }
    };

    const onSpeechEnd = async (audio) => {
        console.log('stopping listening during playback..');

        console.log('User stopped talking');
        const wavBuffer = utils.encodeWAV(audio);
        const base64 = utils.arrayBufferToBase64(wavBuffer);

        const response = await onboardingSpeechTranscribe(base64);
        setInputValue(response.data.userInput);
        setResponseValue(response.data.contents);
        setPercentComplete(response.data.percent);

        //Add user input (from TTS)
        dispatch(addConversationItem({ contents: response.data.userInput, initiator: 'USER' }));

        dispatch(
            addConversationItem({
                contents: response.data.contents,
                initiator: 'ASSISTANT'
            })
        );

        console.log('Pending UserStatus is: ' + response.data.userStatus);

        //Play audio..
        if (response.data.voice) {
            dispatch(setVoiceMode(VoiceMode.ELLA_SPEAKING));

            // Decode and set the audio data
            playAudio(response.data.voice);
        } else {
            console.log('No voice found in response');
        }
        // }
    };

    const playAudio = (audioBytes: string) => {
        const audioContext = new window.AudioContext();
        setCurrentInteraction('PLAYING');

        // Convert base64 to ArrayBuffer
        function base64ToArrayBuffer(base64) {
            const binaryString = atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        }

        const audioData = base64ToArrayBuffer(audioBytes);

        // Decode and play the audio data
        audioContext
            .decodeAudioData(audioData)
            .then((buffer) => {
                const source = audioContext.createBufferSource();
                source.buffer = buffer;
                source.connect(audioContext.destination);

                const analyser = audioContext.createAnalyser();
                analyserRef.current = analyser;
                source.connect(analyser);
                analyser.connect(audioContext.destination);
                analyser.fftSize = 2048; // You can adjust this value

                // Set up dataArray
                const bufferLength = analyser.frequencyBinCount;
                setDataArray(new Uint8Array(bufferLength));

                source.onended = () => {
                    console.log('Audio playback has ended.');
                    // Replace with your logic to handle the end of playback
                    handleAudioEnded();
                };

                source.start(0);
            })
            .catch((error) => {
                console.error('Audio decoding failed', error);
            });
    };

    function handleAudioEnded() {
        setCurrentInteraction('IDLE');
        setInputValue('');
        analyserRef.current = null;

        if (percentCompleteRef.current < 100) {
            if (interactionModeRef.current == 'SPEAK') {
                vadContext.triggerToggleVAD();
            }
        } else {
            dispatch(setUserStatus('SETUP_PROFILE'));
            setCurrentInteraction('COMPLETE');
        }

        console.log('Audio playback finished..');
    }

    function ellaVideoContainer() {
        return (
            <Box
                display="flex"
                flexDirection="row"
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
                sx={{
                    width: '100%',
                    height: '100%' // Keeping it square
                    // position: 'relative'
                }}
            >
                {currentInteraction == 'THINKING' ? (
                    <video
                        autoPlay
                        muted
                        playsInline
                        loop
                        style={{
                            // position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '40%',
                            transition: 'opacity 2s ease-in-out', // 1s fade-in transition
                            opacity: opacity // Set the opacity to state value
                        }}
                    >
                        <source src={sparkleVideo} type="video/mp4" />
                    </video>
                ) : (
                    <></>
                )}

                {currentInteraction == 'USER_SPEAKING' ? <svg id="waveform" width="500" height="100"></svg> : <></>}

                {currentInteraction == 'PLAYING' ? (
                    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                        <Box style={{ width: isMobile ? '80%' : '40%' }}>
                            <img src={ellaSpeaking} width="100%" height="100%" />
                        </Box>
                        <svg id="waveform" width="500" height="100%"></svg>
                    </Box>
                ) : (
                    <></>
                )}

                {currentInteraction == 'COMPLETE' ? (
                    <Button
                        onClick={() => {
                            navigate('/onboarding/profile');
                        }}
                    >
                        Complete Profile..
                    </Button>
                ) : (
                    <></>
                )}

                {currentInteraction == 'IDLE' ? <Typography variant="h1">(Idle Animation Here)</Typography> : <></>}
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    width: '100vw',
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundColor: '#12141A',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: isMobile ? 'start' : 'center',
                    color: '#fff',
                    overflow: 'hidden'
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width={isMobile ? '100%' : '95%'}
                    height={isMobile ? '85%' : '100%'}
                >
                    <Box display="flex" flexDirection="row">
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between" // This will align children to the sides
                            alignItems="center" // Vertically center the items
                            width="100%" // Take the full width to allow space-between to work
                            paddingLeft={2}
                            paddingTop={2}
                        >
                            <Box display="flex" flexDirection="row" gap={1}>
                                <Logo />
                                <Typography
                                    sx={{ whiteSpace: 'nowrap' }}
                                    className={styles.greymattersLogo}
                                    paddingTop={1}
                                    gutterBottom
                                >
                                    <span>Grey Matters</span>
                                </Typography>
                            </Box>

                            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                <Button
                                    sx={{
                                        borderTopLeftRadius: '50px',
                                        borderBottomLeftRadius: '50px',
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        backgroundColor: interactionMode == 'TYPE' ? '#3f51b5' : '#6573c3', // Left button color
                                        '&:hover': {
                                            backgroundColor: '#334296' // Darken color on hover
                                        }
                                    }}
                                    startIcon={<Create />}
                                    onClick={() => {
                                        setInteractionMode('TYPE');
                                    }}
                                >
                                    {/* Button text or icon */}
                                </Button>
                                <Button
                                    sx={{
                                        borderTopRightRadius: '50px',
                                        borderBottomRightRadius: '50px',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        backgroundColor: interactionMode == 'SPEAK' ? '#3f51b5' : '#6573c3', // Right button color
                                        '&:hover': {
                                            backgroundColor: '#49599a' // Darken color on hover
                                        }
                                    }}
                                    startIcon={<MicIcon />}
                                    onClick={() => {
                                        setInteractionMode('SPEAK');
                                    }}
                                >
                                    {/* Button text or icon */}
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" alignItems="center">
                        <Typography>Onboarding Completion</Typography>
                        <Slider
                            className={styles.animatedSlider}
                            value={percentComplete}
                            min={0}
                            max={100}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            sx={{
                                // Step 3: Style the slider
                                width: '100%', // Adjust width as needed
                                margin: 'auto' // Center the slider
                            }}
                        />
                    </Box>

                    <EllaResponseComponent responseValue={responseValue} />

                    <Box
                        display="flex"
                        flexDirection="column"
                        width={'100%'}
                        height={'100%'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={2}
                    >
                        {ellaVideoContainer()}
                    </Box>
                    <Box display="flex" justifyContent={'center'}>
                        <TextField
                            variant="outlined"
                            sx={{
                                width: '80%',
                                marginTop: '1rem',
                                color: 'white',
                                borderColor: 'white !important',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)'
                                }
                            }}
                            label={inputValue == null ? 'Your response..' : ''}
                            value={inputValue}
                            InputLabelProps={{ style: { color: 'white' } }}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    sendTextResponse();
                                }
                            }}
                            InputProps={{
                                style: { color: 'white' },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            disabled={inputValue == null || inputValue.length == 0}
                                            onClick={sendTextResponse}
                                        >
                                            Send
                                        </Button>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default OnboardingMainComponent;
