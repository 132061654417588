import React, { useState, useEffect } from 'react';
import './index.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GMAmplifyInit } from './graphql/GMAmplifyInit';
import { getCurrentUser, isAuthenticated } from './graphql/GMAPI';
import { Auth, Hub } from 'aws-amplify';
import LoadingSpinner from './components/LoadingSpinner';
import styled from 'styled-components';
import { setUser } from './redux/features/user/userSlice';
import { selectUser } from './redux/features/user/userSlice';
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './redux/store';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { App } from 'src/App';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MessageSubscriptionProvider } from './providers/MessageSubscriptionProvider';

import { createRoot } from 'react-dom/client';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
const container = document.getElementById('root');

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // 100% of the viewport height
    width: 100vw; // 100% of the viewport width
`;

const MainComponent = () => {
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [isCheckedUser, setCheckedUser] = useState(false);
    const dispatch = useAppDispatch(); // Use the hook here
    const user = useAppSelector(selectUser);
    const audioRef = React.useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = async () => {
            let authStatus = await isAuthenticated();

            //If we have a user, set it now.
            if (authStatus) {
                try {
                    const user = await getCurrentUser();
                    dispatch(setUser(user));
                } catch (e) {
                    console.error('Error validating user, assuming unauthed..', e);
                    authStatus = false;
                }
            }
            setIsUserAuthenticated(authStatus);
            setCheckedUser(true);
        };

        // Check initial authentication state
        checkAuthentication();
    }, []);

    useEffect(() => {
        // Handle the redirect back from the federated sign-in
        const urlParams = new URLSearchParams(window.location.search);
        console.log('Redirect URL params: ' + JSON.stringify(urlParams));

        Hub.listen('auth', (data) => {
            // this.onAuthEvent(payload);
            console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
        });
    }, []);

    useEffect(() => {
        // Redirect logic
        const isOnboardingPath = /^\/onboarding\//.test(location.pathname);
        if (isCheckedUser && user && user.value && user?.value.status !== 'ONBOARDED' && !isOnboardingPath) {
            navigate('/onboarding/1');
        }
    }, [isCheckedUser, user, navigate, location.pathname]);

    const renderContent = () => {
        console.log('Called render content in profileComponent.  User.status is: ' + user?.value?.status);
        console.log('UserAuthenticated: ' + isUserAuthenticated);
        if (!isUserAuthenticated) {
            return (
                <CenteredDiv>
                    {isCheckedUser ? (
                        <button
                            onClick={() =>
                                Auth.federatedSignIn({
                                    customProvider: 'KeyCloak-dev'
                                })
                            }
                            color="inherit"
                        >
                            Login / Sign up
                        </button>
                    ) : (
                        <LoadingSpinner />
                    )}
                </CenteredDiv>
            );
        } else {
            return <App />;
        }
    };

    return (
        <div className="centered-container">
            {renderContent()}

            <audio ref={audioRef}>
                <source src="/message-tone.mp3" type="audio/mpeg" />
            </audio>
        </div>
    );
};

export default MainComponent;

const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <GMAmplifyInit />
                <MessageSubscriptionProvider>
                    <I18nextProvider i18n={i18n}>
                        <MainComponent />
                    </I18nextProvider>
                </MessageSubscriptionProvider>
            </LocalizationProvider>
        </BrowserRouter>
    </Provider>
);

reportWebVitals(null);
