import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../..';

import { styled } from '@mui/material';

import { addConversationItem, selectConversations } from '../../redux/features/onboarding/onboardingConversationSlice';
import { useEffect, useRef, useState } from 'react';
import { ellaConversationNext, getConversationHistory } from '../../graphql/GMAPI';
import { EllaChatContainer } from './EllaChatContainer';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        position: 'absolute',
        top: '5%',
        margin: '0px',
        right: '5%',
        width: '40vw',
        height: '85vh',
        [theme.breakpoints.down('sm')]: {
            margin: '0px',
            top: '0%',
            left: '0%',
            width: '100vw',
            height: '100vh'
        }
    }
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
}));

const EllaChat = (props) => {
    const conversations = useSelector(selectConversations);
    const dispatch = useAppDispatch(); // Use the hook here
    const [message, setMessage] = useState<string>('');
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [hasInitialised, setInitialised] = useState<boolean>(false);

    const textareaRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const fetchData = async () => {
            //First check if we have conversation history, to know whose 'turn' it is to speak..
            if (conversations == null || conversations.length == 0) {
                const conversationElements = (await getConversationHistory()).data;
                if (conversationElements && conversationElements.length > 0) {
                    for (const conversationElement of conversationElements) {
                        dispatch(
                            addConversationItem({
                                id: conversationElement.id,
                                contents: conversationElement.contents,
                                initiator: conversationElement.initiator,
                                reasoning: conversationElement.reasoning
                            })
                        );
                    }
                } else {
                    const response = await ellaConversationNext('');
                    console.log('Response is: ' + JSON.stringify(response));
                    dispatch(
                        addConversationItem({
                            id: response.id,
                            contents: response.contents,
                            initiator: 'ASSISTANT',
                            reasoning: response.reasoning
                        })
                    );
                }
            }

            setInitialised(true);
        };

        if (!hasInitialised) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [message]);

    const handleSend = async () => {
        if (message && message.length > 0) {
            setIsTyping(true);
            const timer = setTimeout(() => {
                setIsTyping(false);
            }, 30000);
            const input = message;
            setMessage('');

            dispatch(addConversationItem({ contents: input, initiator: 'USER' }));

            const response = await ellaConversationNext(message);
            setIsTyping(false);
            clearTimeout(timer);
            console.log('Response is: ' + JSON.stringify(response));

            dispatch(
                addConversationItem({
                    contents: response.contents,
                    initiator: 'ASSISTANT',
                    reasoning: response.reasoning
                })
            );
        }
    };

    return (
        <StyledDialog open={props.open} onClose={props.onClose}>
            <CloseButton aria-label="close" onClick={props.onClose}>
                <CloseIcon />
            </CloseButton>

            <EllaChatContainer
                conversations={conversations}
                message={message}
                handleSend={handleSend}
                isTyping={isTyping}
                setMessage={setMessage}
            />
        </StyledDialog>
    );
};

export default EllaChat;
