import React, { useEffect, useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    debounce
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { formatDistanceToNow } from 'date-fns';
import { groupPost, groupPostImageCreate, groupPostReact, userFindByNameFragment } from '../../graphql/GMAPI';
import { Typography, Card, CardContent, Box, Divider } from '@mui/material';
import { GroupPost, ReactionType } from '../../graphql/types/Group';
import {
    faThumbsUp as farThumbsUp, // for regular thumbs up (unfilled)
    faGrinHearts,
    faLaugh,
    faSurprise,
    faAngry
} from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as fasThumbsUp } from '@fortawesome/free-solid-svg-icons'; // for solid thumbs up (filled)

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './index.css';
import QuillWithCustomLink from '../QuillWithRouterLink';

const ReactionIcon = ({ icon, count, label }) => {
    return (
        <div
            style={{
                display: 'inline-block',
                position: 'relative',
                marginLeft: '-4px', // Negative margin for overlapping effect
                cursor: 'pointer'
            }}
            title={`${label}: ${count}`} // Simple browser tooltip
        >
            <FontAwesomeIcon icon={icon} />
        </div>
    );
};

const reactionTypes = [
    { type: 'LIKE', icon: fasThumbsUp, label: 'Like' },
    { type: 'LOVE', icon: faGrinHearts, label: 'Love' },
    { type: 'LAUGH', icon: faLaugh, label: 'Laugh' },
    { type: 'SURPRISE', icon: faSurprise, label: 'Surprise  ' },
    { type: 'ANGRY', icon: faAngry, label: 'Angry' }
];

// ReactionBar component
const ReactionBar = ({ showDivider, isUserMember, inPost, isComment }) => {
    const [showReactions, setShowReactions] = useState(false);
    const reactionBarRef = useRef(null);
    const hideTimeoutIdRef = useRef(null);
    const [post, setPost] = useState<GroupPost>(inPost);
    const activeReactions = reactionTypes.filter((reaction) => post.reactionCounts[reaction.type] > 0);

    // Reaction options array
    const reactions = [
        { icon: fasThumbsUp, label: 'Like' },
        { icon: faGrinHearts, label: 'Love' },
        { icon: faLaugh, label: 'Laugh' },
        { icon: faSurprise, label: 'Surprise' },
        { icon: faAngry, label: 'Angry' }
    ];

    // Function to handle reaction selection
    const handleReaction = async (reaction: string) => {
        console.log(`Reacted with ${reaction} to post ${post.id}`);

        try {
            // Call the API to record the reaction
            const response = await groupPostReact(post.id, reaction.toUpperCase() as ReactionType);
            if (response.errors) {
                // Handle any errors that occurred during the API call
                console.error('Error reacting to post:', response.errors);
            } else {
                // Handle the successful reaction, e.g., update state or UI
                console.log('Successfully reacted to post:', response.data);

                // Destructure the relevant data from the response
                const { actionPerformed, type } = response.data;

                // Update the post state with the new reaction count
                setPost((currentPost) => {
                    // Clone the current reactionCounts
                    const newReactionCounts = { ...currentPost.reactionCounts };

                    // Check the actionPerformed and update the count accordingly
                    if (actionPerformed === 'CREATED') {
                        newReactionCounts[type] = (newReactionCounts[type] || 0) + 1;
                    } else if (actionPerformed === 'DELETED') {
                        newReactionCounts[type] = (newReactionCounts[type] || 0) - 1;
                    }

                    // Return the updated post with the new reaction counts
                    return { ...currentPost, reactionCounts: newReactionCounts };
                }); // Here you might want to update state or trigger a re-render to show the new reaction
            }
        } catch (error) {
            // Handle the error if the API call fails
            console.error('Error calling groupPostReact:', error);
        }

        setShowReactions(false);
    };

    const showReactionOptions = () => {
        // Clear any existing timeout to prevent the bar from hiding
        if (hideTimeoutIdRef.current) {
            clearTimeout(hideTimeoutIdRef.current);
        }
        setShowReactions(true);
    };

    const hideReactionOptions = () => {
        // Hide the reaction options after a short delay to allow for clicking
        hideTimeoutIdRef.current = setTimeout(() => {
            setShowReactions(false);
        }, 500); // 500ms delay before hiding the reactions
    };

    // Cleanup timeout on component unmount
    useEffect(() => {
        return () => {
            if (hideTimeoutIdRef.current) {
                clearTimeout(hideTimeoutIdRef.current);
            }
        };
    }, []);

    return (
        <>
            <div style={{ position: 'relative' }}>
                {activeReactions.map((reaction) => (
                    <ReactionIcon
                        key={reaction.type}
                        icon={reaction.icon}
                        count={post.reactionCounts[reaction.type]}
                        label={reaction.label}
                    />
                ))}
            </div>
            <div style={{ position: 'relative', padding: 3 }}>
                <div
                    style={{ display: 'inline-flex', width: '100%', alignItems: 'center', position: 'relative' }}
                    onMouseOver={showReactionOptions}
                    onMouseLeave={hideReactionOptions}
                    ref={reactionBarRef}
                >
                    {isUserMember && (
                        <>
                            <Box display="flex" flexDirection="column" width="100%" alignItems="start">
                                {showDivider && <Divider sx={{ width: '100%' }} />}
                                <IconButton aria-label="like" size="small">
                                    {!isComment && <FontAwesomeIcon icon={farThumbsUp} />}
                                    {!isComment ? (
                                        <span style={{ paddingLeft: '0.25rem', fontSize: '1rem' }}>Like</span>
                                    ) : (
                                        <span style={{ fontSize: '0.7rem' }}>Like</span>
                                    )}
                                </IconButton>
                            </Box>
                        </>
                    )}
                    {showReactions && (
                        <div
                            style={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                backgroundColor: '#fff',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                zIndex: 1000
                            }}
                            onMouseOver={() => setShowReactions(true)}
                            onMouseLeave={() => setShowReactions(false)}
                        >
                            {reactions.map((reaction) => (
                                <IconButton key={reaction.label} onClick={() => handleReaction(reaction.label)}>
                                    <FontAwesomeIcon icon={reaction.icon} />
                                </IconButton>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export function PostCard({ myUser, inPost, groupId, isUserMember, navigatePostId }) {
    const [newComment, setNewComment] = useState('');
    const [post, setPost] = useState<GroupPost>(inPost);
    const [quillContent, setQuillContent] = useState('');
    const lastMentionInsertionIndexRef = useRef(null);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    //Autocomplete for user tags

    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [autocompletePosition, setAutocompletePosition] = useState({ top: 0, left: 0 });
    const [, setInputValue] = useState('');
    const quillRef = useRef(null);

    //Render all comments if we're navigating to a comment in this post..
    useEffect(() => {
        if (navigatePostId && inPost.child_posts.some((childPost) => childPost.id === navigatePostId)) {
            setShowAllComments(true);
        }
    }, [inPost, navigatePostId]);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.on('text-change', handleTextChange);
        }
    }, [quillRef]);

    const handleTextChange = debounce(async (delta, oldDelta, source) => {
        if (source === 'user') {
            const cursorPosition = quillRef.current.getEditor().getSelection()?.index;
            if (cursorPosition === null || cursorPosition === undefined) return;

            const text = quillRef.current.getEditor().getText(0, cursorPosition);
            const atSignIndex = text.lastIndexOf('@');

            const lastMentionInsertionIndex = lastMentionInsertionIndexRef.current;

            console.log('At index is: ' + atSignIndex);
            console.log('Last mention insert index: ' + lastMentionInsertionIndex);

            if (
                atSignIndex > -1 &&
                (lastMentionInsertionIndex === null || cursorPosition > lastMentionInsertionIndex) &&
                (atSignIndex > lastMentionInsertionIndex || lastMentionInsertionIndex == null)
            ) {
                const bounds = quillRef.current.getEditor().getBounds(cursorPosition);
                setAutocompletePosition({ top: bounds.top, left: bounds.left });
                setInputValue(text.substring(atSignIndex + 1, cursorPosition));
                setShowAutocomplete(true);
                await fetchData(text.substring(atSignIndex + 1, cursorPosition));
            } else {
                setShowAutocomplete(false);
            }
        }
    }, 300);

    const handleAutocompleteSelect = (username, userId) => {
        console.log('Inside handle autocomplete select');

        const quill = quillRef.current.getEditor();
        quill.focus();

        const cursorPosition = quill.getSelection()?.index;

        if (cursorPosition !== null && cursorPosition !== undefined) {
            const atSignIndex = quill.getText(0, cursorPosition).lastIndexOf('@');

            if (atSignIndex !== -1) {
                // Delete the '@' and any following text up to the cursor position
                quill.deleteText(atSignIndex, cursorPosition - atSignIndex);

                // Insert the username as a link
                const mentionLink = `/profile/user/${userId}`;
                const mentionText = `@${username}`;

                quill.insertText(atSignIndex, mentionText, 'link', mentionLink);

                // Position the cursor after the inserted link
                quill.setSelection(atSignIndex + mentionText.length);

                const cursorPositionAfterInsert = atSignIndex + username.length + 2; // Length of the inserted mention text
                console.log('Setting last mention insert index to: ' + cursorPositionAfterInsert);
                lastMentionInsertionIndexRef.current = cursorPositionAfterInsert;
            } else {
                console.log("Couldn't find at sign index...");
            }
        }

        setShowAutocomplete(false);
    };

    const fetchData = async (input) => {
        if (input.length > 1) {
            console.log('Searching for options for: ' + input);
            const response = await userFindByNameFragment(input);
            const users = response.data;
            console.log('Users is: ', users);
            setAutocompleteOptions(users);
        } else {
            setAutocompleteOptions([]);
        }
    };

    const handleNewCommentChange = (content, delta, source, editor) => {
        setNewComment(editor.getContents());
        setQuillContent(editor.getText());
    };

    const handleSendComment = async () => {
        if (!newComment || newComment.length == 0) {
            console.log('Comment cannot be empty');
            return; // Add appropriate error handling if needed
        }

        // Convert newComment to a string if it's a Delta object
        const commentAsString = JSON.stringify(newComment);

        //Reset mentions..
        lastMentionInsertionIndexRef.current = null;

        // Assuming groupPost function takes groupId, title, body, and parentId as arguments
        const postResponse = await groupPost(groupId, 'Comment', commentAsString, post.id);

        if (postResponse.data) {
            // Handle the successful comment post, such as clearing the input or showing a success message
            console.log('Comment sent:', postResponse.data);
            setNewComment('');

            setPost((prevPost) => {
                return {
                    ...prevPost,
                    child_posts: [postResponse.data, ...prevPost.child_posts]
                };
            });
        } else if (postResponse.errors) {
            // Handle errors, for instance logging to the console or displaying an error message to the user
            console.log('Error sending comment:', postResponse.errors);
        }
    };

    const navigate = useNavigate();

    const getUserInitials = (name) => {
        return name
            .split(' ')
            .map((n) => n[0])
            .join('');
    };

    const handleAvatarClick = (id) => {
        navigate(`/profile/user/${id}`);
    };

    const handleGroupClick = (id) => {
        navigate(`/group/instance/${id}`);
    };

    const [showAllComments, setShowAllComments] = useState(false);
    const [isQuillFocused, setQuillFocused] = useState(false);

    // Handler to toggle Quill editor's focus state
    const handleQuillFocus = () => {
        setQuillFocused(true);
    };

    const handleQuillBlur = () => {
        setQuillFocused(false);
    };

    const commentAvatarStyle = {
        width: 24, // Smaller width for comment avatar
        height: 24, // Smaller height for comment avatar
        marginRight: 1, // Spacing between avatar and text
        fontSize: '0.875rem' // Smaller font size for initials
    };

    const formatDate = (dateString) => {
        return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    };

    const createImage = async () => {
        setIsGenerating(true);
        const imageUrl = await groupPostImageCreate(post.id);
        setIsGenerating(false);

        if (imageUrl.errors) {
            setErrorMessage(imageUrl.errors[0].message);
            setOpenErrorDialog(true);
        } else {
            setPost({
                ...post,
                image_url: imageUrl.data
            });
        }
    };

    return (
        <>
            <Card variant="outlined" sx={{ marginBottom: 2 }} id={`post-${post.id}`}>
                <CardContent>
                    <Box display="flex" alignItems="center" marginBottom={2}>
                        <Avatar
                            onClick={() => handleAvatarClick(post.poster.id)}
                            sx={{ cursor: 'pointer', marginRight: 2 }}
                            src={post.poster?.avatar_url}
                        >
                            {getUserInitials(post.poster.name)}
                        </Avatar>
                        <Box display="flex" flexDirection="column" alignItems="left">
                            <Box sx={{ flexGrow: 1 }}>
                                {post.group?.name ? (
                                    <Typography variant="h6" fontWeight="bold">
                                        <Link
                                            component="button"
                                            onClick={() => handleGroupClick(groupId)}
                                            sx={{ textAlign: 'left', cursor: 'pointer', marginRight: 1 }}
                                        >
                                            {post.group.name}
                                        </Link>
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                                <Typography variant="h6" fontStyle="italic">
                                    {post.title}
                                </Typography>
                                {/* Poster's name and age of post */}
                                <Typography variant="body1">
                                    <Link
                                        component="button"
                                        onClick={() => handleAvatarClick(post.poster.id)}
                                        sx={{ cursor: 'pointer', marginRight: 1 }}
                                    >
                                        {post.poster.name}
                                    </Link>
                                    &bull; {formatDate(post.date_created)}
                                    &bull;
                                    {!isGenerating && !post.image_url && (
                                        <Button onClick={createImage}>Create image from post</Button>
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" paddingBottom={0}>
                        <ReactQuill value={post.body} readOnly={true} theme={null} />
                        {post.image_url && <img src={post.image_url} width="100%" />}
                        {isGenerating && <CircularProgress />}
                    </Box>
                    <ReactionBar showDivider={true} isUserMember={isUserMember} inPost={post} isComment={false} />
                    <Divider />
                    {isUserMember ? (
                        <Box display="flex" flexDirection="row" alignItems="center" paddingTop={1}>
                            <Avatar
                                onClick={() => handleAvatarClick(myUser.id)}
                                sx={{ ...commentAvatarStyle, marginRight: 2 }}
                                src={myUser.avatar_url}
                            >
                                {getUserInitials(myUser.name)}
                            </Avatar>
                            <Box
                                sx={{
                                    position: 'relative',
                                    borderRadius: '8px',
                                    border: `1px solid ${isQuillFocused ? 'primary.main' : 'grey.300'}`,
                                    transition: 'border-color 0.2s',
                                    flexGrow: 1
                                    // Adjust padding or other styles as necessary
                                }}
                            >
                                <QuillWithCustomLink
                                    value={newComment}
                                    style={{ height: isQuillFocused ? '5em' : '3em' }}
                                    onFocus={handleQuillFocus}
                                    ref={quillRef}
                                    onBlur={handleQuillBlur}
                                    onChange={handleNewCommentChange}
                                    modules={{ toolbar: false }}
                                />

                                {showAutocomplete && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: autocompletePosition.top, // Adjust as necessary
                                            left: autocompletePosition.left, // Adjust as necessary
                                            border: '1px solid #ccc', // Grey border
                                            backgroundColor: '#fff', // White background
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Optional: Adds a subtle shadow
                                            zIndex: 1000, // Ensure it's above other elements
                                            maxHeight: '200px', // Limit the height of the dropdown
                                            overflowY: 'auto', // Add scroll for overflow
                                            width: '300px' // Set a fixed width or make it dynamic
                                        }}
                                    >
                                        {autocompleteOptions.map((user) => (
                                            <div
                                                key={user.id}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleAutocompleteSelect(user.name, user.id);
                                                }}
                                                style={{
                                                    padding: '10px', // Space around each item
                                                    borderBottom: '1px solid #eee', // Divider between items
                                                    cursor: 'pointer' // Change cursor on hover
                                                }}
                                            >
                                                {user.name}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <IconButton
                                    onClick={handleSendComment}
                                    aria-label="send"
                                    sx={{
                                        position: 'absolute',
                                        right: '8px',
                                        bottom: '4px',
                                        color: quillContent.trim() ? 'primary.main' : 'grey.400' // Change to blue if there's text, grey otherwise
                                    }}
                                    disabled={!quillContent.trim()} // Optional: Disable if there's no text
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </IconButton>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {post.child_posts.slice(0, showAllComments ? post.child_posts.length : 2).map((comment, index) => (
                        <div key={index} id={`post-${comment.id}`}>
                            <Box display="flex" flexDirection="column">
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    key={index}
                                    sx={{ marginBottom: 2 }}
                                >
                                    <Avatar
                                        onClick={() => handleAvatarClick(comment.poster.id)}
                                        sx={{ ...commentAvatarStyle, marginRight: 2 }}
                                        src={comment.poster?.avatar_url}
                                    >
                                        {getUserInitials(comment.poster.name)}
                                    </Avatar>
                                    <Box display="flex" flexDirection="column">
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                backgroundColor: 'grey.200',
                                                borderRadius: 2,
                                                paddingTop: 4,
                                                paddingBottom: 3,
                                                paddingRight: 11,
                                                marginTop: 1
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    position: 'absolute',
                                                    top: '0.5rem',
                                                    // left: '1rem',
                                                    backgroundColor: 'grey.200',
                                                    display: 'inline-block',
                                                    whiteSpace: 'nowrap',
                                                    padding: '0.2rem 0.5rem',
                                                    borderRadius: '0.5rem',
                                                    fontSize: '0.875rem',
                                                    fontWeight: 'bold',
                                                    maxWidth: 'calc(100% - 2rem)' // prevent it from overflowing the box
                                                    // overflow: 'hidden'
                                                    // textOverflow: 'ellipsis' // adds '...' to texts that are too long
                                                }}
                                            >
                                                <Link
                                                    component="button"
                                                    onClick={() => handleAvatarClick(comment.poster.id)}
                                                    sx={{ cursor: 'pointer', marginRight: 1 }}
                                                >
                                                    {comment.poster.name}
                                                </Link>
                                            </Typography>
                                            <Box paddingLeft={1}>
                                                <ReactQuill
                                                    value={comment.body}
                                                    readOnly={true}
                                                    theme={null}
                                                    className="comment-quill"
                                                />
                                            </Box>
                                            <Typography
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: '0.5rem',
                                                    right: '1rem',
                                                    backgroundColor: 'grey.200',
                                                    display: 'inline-block',
                                                    whiteSpace: 'nowrap',
                                                    padding: '0.2rem 0.5rem',
                                                    borderRadius: '0.5rem',
                                                    fontSize: '0.6rem',
                                                    maxWidth: 'calc(100% - 2rem)' // prevent it from overflowing the box
                                                    // overflow: 'hidden'
                                                    // textOverflow: 'ellipsis' // adds '...' to texts that are too long
                                                }}
                                            >
                                                {formatDistanceToNow(new Date(comment.date_created), {
                                                    addSuffix: true
                                                })}
                                            </Typography>
                                        </Box>
                                        <ReactionBar
                                            showDivider={false}
                                            isUserMember={isUserMember}
                                            inPost={comment}
                                            isComment={true}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    ))}
                    {!showAllComments && post.child_posts.length > 1 && (
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => setShowAllComments(true)}
                            sx={{ color: 'grey' }}
                        >
                            Show more comments...
                        </Link>
                    )}
                </CardContent>
            </Card>
            <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenErrorDialog(false)} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
